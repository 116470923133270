import React from 'react';
import {Route, Routes} from "react-router-dom";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import useToggle from "../hooks/useToogle";
import {useNavigate} from "react-router-dom";
import {getUser, getUsers, removeUser} from "../reduxToolkit/asyncThunkFunctions/Api/UserRequests";
import {PATHS} from "../utils/RouteSettings/Paths";
import Users from "../components/Administration/Users";
import Tools from "./Tools";
import EditUser from "../components/Administration/EditUser";
import AddUser from "../components/Administration/AddUser";
import {selectUsers, selectUser} from "../reduxToolkit/selectors/Api/user";
import Logs from "../components/Administration/Logs";


const Administration = () => {
    const {open, close, previewCheckClasses} = useToggle();
    const {user} = useSelector(selectUser);
    const {users, usersLoading} = useSelector(selectUsers)


    const dispatch = useDispatch();
    const navigation = useNavigate();

    const previewClasses = classNames('sectionPreviewUser', previewCheckClasses);
    const userClasses = classNames('userCard', previewCheckClasses);

    const handleEditUser = (_id) => navigation(`/${PATHS.ADMINISTRATION}/edit/${_id}`)

    const handleAddNew = () => navigation(`/${PATHS.ADMINISTRATION}/new`)

    const handleRemoveUser = async (_id) => {
        await dispatch(removeUser(_id))
        await dispatch(getUsers());
    }

    const onChange = (pagination, filters) => {
        const {is_active} = filters
        const filterStatus = is_active?.length === 1 ? (is_active?.join('') ?? '') : null
        dispatch(getUsers(filterStatus ? {is_active: filterStatus === 'active'} : {}))
    };

    const handlePreviewUser = async (_id) => {
        await dispatch(getUser(_id))
        await open()
    }

    return (
        <Routes>
            <Route path={'users'} element={
                <Users
                    onChange={onChange} handlePreviewUser={handlePreviewUser}
                    handleEditUser={handleEditUser} handleRemoveUser={handleRemoveUser}
                    handleAddNew={handleAddNew} users={users} user={user} usersLoading={usersLoading}
                    close={close} previewClasses={previewClasses} userClasses={userClasses}

                />}/>
            <Route path={'audit'} element={<Logs/>}/>
            <Route path={'edit/:login'} element={<EditUser/>}/>
            <Route path={'new'} element={<AddUser/>}/>
            <Route path={'tools'} element={<Tools/>}/>
        </Routes>
    );
};

export default Administration;