import React from 'react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonStepTwoFormAddGate = () => {
    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <div className={'formGate'}>
                <div className={'formGate__wrapperSkeletonInput'}>
                    <div className={'formGate__skeletonLabel'}>
                        <Skeleton height={15} width={`100%`}/>
                    </div>
                    <Skeleton height={35} width={`100%`}/>
                </div>
                <div className={'formGate__wrapperSkeletonInput'}>
                    <div className={'formGate__skeletonLabel'}>
                        <Skeleton height={15} width={`100%`}/>
                    </div>
                    <Skeleton height={35} width={`100%`}/>
                </div>
                <div className={'formGate__wrapperSkeletonInput'}>
                    <div className={'formGate__skeletonLabel'}>
                        <Skeleton height={15} width={`100%`}/>
                    </div>
                    <Skeleton height={35} width={`100%`}/>
                </div>
                <div className={'formGate__wrapperSkeletonInput'}>
                    <div className={'formGate__skeletonLabel'}>
                        <Skeleton height={15} width={`100%`}/>
                    </div>
                    <Skeleton height={35} width={`100%`}/>
                </div>
                <div className={'formGate__wrapperSkeletonInput'}>
                    <div className={'formGate__skeletonLabel'}>
                        <Skeleton height={15} width={`100%`}/>
                    </div>
                    <div className={'formGate__skeletonRadioButtons'}>
                        <Skeleton height={'15px'} width={`15px`} circle style={{marginRight: '20px'}}/>
                        <Skeleton height={'15px'} width={`15px`} circle/>
                    </div>
                </div>
                <div className={'formGate__skeletonSubmit'}>
                    <Skeleton height={40} width={`100%`}/>
                </div>
            </div>
        </SkeletonTheme>
    );
};

export default SkeletonStepTwoFormAddGate;