import React from 'react';

const GeneralInfo = ({id, current_airport, airline, aircraft_type, aircraft_reg_number, parking_gate}) => {
    return (
        <div className={'wrapperTurnInfo'}>
            <h2>General</h2>
            <div className={'turnInformation'}>
                <span><span className={'headTextTurnInfo'}>id: </span>{id}</span>
                <span><span className={'headTextTurnInfo'}>airport: </span>{current_airport}</span>
                <span><span className={'headTextTurnInfo'}>airline: </span>{airline}</span>
                <span><span className={'headTextTurnInfo'}>aircraft: </span>{aircraft_type}</span>
                <span><span className={'headTextTurnInfo'}>reg number: </span>{aircraft_reg_number} </span>
                <span><span className={'headTextTurnInfo'}>parking gate: </span>{parking_gate}</span>
            </div>
        </div>
    );
};

export default GeneralInfo;