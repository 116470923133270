import {Route, Routes} from "react-router-dom";
import Download from "../components/System/Download";
import Events_Tools from "../components/System/EventTools/Events_Tools";
import Validation from "../components/System/Validation";
import {SYSTEM_DOWNLOAD, SYSTEM_EVENTS_TOOLS, SYSTEM_OTHER} from "../utils/RouteSettings/Paths";


const System = () => {
    return (
        <div className={'systemWrapper'}>
            <Routes>
                <Route path={'/'} element={<Download/>}/>
                <Route path={SYSTEM_DOWNLOAD} element={<Download/>}/>
                <Route path={SYSTEM_EVENTS_TOOLS} element={<Events_Tools/>}/>
                <Route path={SYSTEM_OTHER} element={<Validation/>}/>
            </Routes>
        </div>
    );
};

export default System;