import {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {PENCIL} from "../components/ANTD/Icons";
import {Card} from "antd";
import '../styles/Account.sass'
import { Button, Form, Input } from 'antd';
import {editUser} from "../reduxToolkit/asyncThunkFunctions/Api/UserRequests";
import {useParams} from "react-router-dom";
import {selectProfile} from "../reduxToolkit/selectors/Api/auth";

const DetailsText = ({detail, label}) => {
    return (
        <div className={'detailsText'}>
            <span>{label} </span>
            <span>{detail}</span>
        </div>
    )
}

const ChangePassword = ({form, onFinishChangePassword, onFinishFailedChangePassword}) => {
    return (
        <div className={'accountWrapper__changeUserPassword'} >
            <Card size={'small'} title={'Change password'} className={'changePassword'}>
                <Form
                    form={form}
                    autoComplete={'off'}
                    name={'change password'}
                    className={'changePassword__form'}
                    onFinish={onFinishChangePassword}
                    onFinishFailed={onFinishFailedChangePassword}
                >
                    <Form.Item label={'Current password'} name={'currentPassword'} rules={[{ required: true, message: 'Please input your current password!'}]}>
                        <Input.Password className={'changePassInput'}/>
                    </Form.Item>
                    <Form.Item
                        label={'New password'}
                        name={'newPassword'}
                        rules={[{required: true, message: 'Please input your new password!'}]}
                    >
                        <Input.Password className={'changePassInput'}/>
                    </Form.Item>
                    <Form.Item shouldUpdate>
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={
                                    !form.isFieldsTouched(true) ||
                                    !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                            >
                                Edit
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

const Details = ({handleEditDetails, editProfile, onFinishDetails, onFinishFailedDetails, _id}) => {
    return (
        <div className={'accountWrapper__userDetails'}>
            <Card title="Details" size="small" className={'details'}>
                <button className={'details__editButton'} onClick={handleEditDetails}>{PENCIL}</button>
                <Form
                    name="details"
                    initialValues={{ remember: true }}
                    onFinish={onFinishDetails}
                    onFinishFailed={onFinishFailedDetails}
                    autoComplete="off"
                >
                    {editProfile ? <Form.Item
                        className={'detailsFormItem'}
                        label="Login"
                        name="_id"
                        initialValue={_id}
                        rules={[{ required: true, message: 'Please input your login!' }]}
                    >
                        <Input className={'detailsInput'}/>
                    </Form.Item> : <DetailsText _id={_id} label={'Login:'}/>
                    }

                    {/*{editProfile ? <Form.Item*/}
                    {/*        className={'detailsFormItem'}*/}
                    {/*        label="Name"*/}
                    {/*        name="name"*/}
                    {/*        initialValue={_id}*/}
                    {/*        rules={[{ required: true, message: 'Please input your name!' }]}*/}
                    {/*    >*/}
                    {/*        <Input className={'detailsInput'}/>*/}
                    {/*    </Form.Item> :*/}
                    {/*    <DetailsText _id={_id}/>*/}
                    {/*}*/}
                    {/*{editProfile ? <Form.Item*/}
                    {/*        className={'detailsFormItem'}*/}
                    {/*        label="E-mail"*/}
                    {/*        name="email"*/}
                    {/*        initialValue={_id}*/}
                    {/*        rules={[{ required: true, message: 'Please input your email!', type: 'email'}]}*/}
                    {/*    >*/}
                    {/*        <Input className={'detailsInput'}/>*/}
                    {/*    </Form.Item> :*/}
                    {/*    <div className={'detailsText'}>*/}
                    {/*        <span>E-mail: </span>*/}
                    {/*        <span>{_id}</span>*/}
                    {/*    </div>*/}
                    {/*}*/}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={!editProfile}>
                            Edit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    )
}

const Profile = () => {
    const {login} = useParams()
    const dispatch = useDispatch();
    const profile = useSelector(selectProfile);
    const [editProfile, setEditProfile] = useState(false);
    const [form] = Form.useForm();


    const handleEditDetails = () => setEditProfile(!editProfile);

    const onFinishDetails = (values) => {
        dispatch(editUser({user: profile['_id'], body: values}))
        console.log('Success:', values);
    };

    const onFinishFailedDetails = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishChangePassword = (values) => {
        dispatch(editUser({user: profile['_id'], body: {password: values['newPassword']}})) // check it here
        console.log('Success:', values);
    }

    const onFinishFailedChangePassword = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    return (
        <div className={'accountWrapper'}>
            <div className={'accountWrapper__userDetails'}>
                <Card title={'PROFILE'} className={'details'}>
                    <DetailsText detail={profile['_id']} label={'Login:'}/>
                    <DetailsText detail={profile['is_active'] ? 'active' : 'inactive'} label={'Status:'}/>
                    <DetailsText detail={profile['role']} label={'Role:'}/>
                </Card>
            </div>

            {/*<Details*/}
            {/*    _id={profile['_id']}*/}
            {/*    handleEditDetails={handleEditDetails}*/}
            {/*    editProfile={editProfile}*/}
            {/*    onFinishDetails={onFinishDetails}*/}
            {/*    onFinishFailedDetails={onFinishFailedDetails}*/}
            {/*/>*/}

            {/*<ChangePassword*/}
            {/*    form={form}*/}
            {/*    onFinishChangePassword={onFinishChangePassword}*/}
            {/*    onFinishFailedChangePassword={onFinishFailedChangePassword}*/}
            {/*/>*/}
        </div>
    )
}


export default Profile;