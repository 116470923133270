import {useCallback, useEffect, useRef, useState} from "react";

export default function () {
    const canvas_ref = useRef(null);

    const [ctx, useCtx] = useState(null);

    const setContext = useCallback((context) => useCtx(context), []);

    useEffect(() => {
        if (canvas_ref?.current) {
            setContext(canvas_ref.current?.getContext('2d'));
        }
    }, [canvas_ref.current]);

    return {canvas_ref, current: canvas_ref.current, ctx};
}

