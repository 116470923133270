import axios from "axios";
import {handleLogout, handleUpdateUser} from "../reduxToolkit/slices/Api/Auth";
import {PATHS} from "../utils/RouteSettings/Paths";
import {setAllUserSettings} from "../utils/CacheHelper";
import {baseUrl} from "../utils/helpers/variables/api";

export default async function RefreshToken(dispatch, navigate, location) {
    try {
        const {data} = await axios(`${baseUrl}/auth/refresh`, {withCredentials: true});
        const {token, role, _id, is_active} = data
        setAllUserSettings(token.split(' ')[1], {_id, role, is_active})
        dispatch(handleUpdateUser({user_token: token, role, _id, is_active}));
        return data
    } catch (error) {
        const {data, status} = error?.response
        if (status === 401 ||
            status === 403 ||
            (status === 400 && (data.includes('Invalid token')))) {
            dispatch(handleLogout())
            navigate(`${PATHS.SLASH}${PATHS.LOGIN}`, {replace: true, state: {from: location}})
        }
        console.log(error.response, 'ERROR')
        throw new Error(error)
    }
}