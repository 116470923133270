import {useCallback} from "react";
import {clearCanvasList, drawCross, drawText} from "../utils/helpers/functions/drawCanvasItems";
import {returnXY} from "../utils/helpers/functions/calcPositions";

export default function ({landmarks, dpi_width, dpi_height, isMarked}) {
    return useCallback((context) => {
        console.log('FUNCTION RENDER in usePrintCrossesWithIndexText');
        clearCanvasList(context);
        landmarks.forEach(({x, y, color}, index) => {
            const {x: circle_x, y: circle_y} = returnXY(x, y, dpi_width, dpi_height);
            drawCross({
                context, crossSize: 13,
                x: circle_x, y: circle_y,
                lineWidth: 2, strokeStyle: isMarked(index) ? "#FF0000" : color
            })
            drawText({
                context, fillStyle: "#000000",
                font: "30px Arial",
                fillText: {text: `${index+1}`, x: circle_x + 15, y: circle_y + 15}
            })
        })
    }, [landmarks, dpi_width, dpi_height, isMarked]);
}