import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../utils/axiosBody";
import {toast} from "react-toastify";
import {getEvents} from "./TurnaroundRequests";

export const addValidation = createAsyncThunk('validation/addValidation',
    async (settings = {turnaround_id: '', cctv_id: '', event: {}}, {dispatch}) => {
        try {
            const {turnaround_id, event, cctv_id} = settings
            const response = await air_axios_settings.post(`events/add_validation?turnaround_id=${turnaround_id}`, event);
            return [response, dispatch(getIsValidFields(turnaround_id)), dispatch(getEvents({turnaround_id, cctv_id}))]
        } catch (error) {
            throw new Error(error)
        }
})

export const getIsValidFields = createAsyncThunk('validation/getIsValidFields',
    async (turnaround_id) => {
    try {
        const {data} = await air_axios_settings.get(`turnarounds/is_validated?turnaround_id=${turnaround_id}`)
        return data
    } catch (error) {
        throw new Error(error)
    }
})

export const finishValidation = createAsyncThunk('validation/finishValidation',
    async (turnaround_id) => {
    try {
        const response = await air_axios_settings.get(`turnarounds/finish_validate?turnaround_id=${turnaround_id}&as_file=false`)
        toast.info(`Turnaround validation finished`);
        return response
    } catch (error) {
        throw new Error(error)
    }
})

export const getDetections = createAsyncThunk('validation/getDetections',
    async () => {
    try {
        const {data} = await air_axios_settings.get('events/objects');
        return data
    } catch (error) {
        throw new Error(error)
    }
})