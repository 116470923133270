import React from 'react';

const AutoCompleteSuggestions = ({suggestions, handleSelect}) => {
    return suggestions.map(suggestion => {
        const {place_id, structured_formatting: {secondary_text, main_text}} = suggestion
        return (
            <li key={place_id} onClick={() => handleSelect(suggestion)} className={`autocomplete-suggestion-list-item`}>
                <strong>{main_text}</strong> <small>{secondary_text}</small>
            </li>
        )
    })
};

export default AutoCompleteSuggestions;