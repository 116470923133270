import {useDispatch} from "react-redux";
import {imgFilePattern} from "../utils/reg";
import {toast} from "react-toastify";
import {onUploadNewFrame} from "../reduxToolkit/slices/Api/Frames";
import {setFile} from "../reduxToolkit/slices/Ui/Files";

const useUploadFrameHandler = (cctv_id) => {
    const dispatch = useDispatch();

    return (e) => {
        const file = e.target.files[0];
        const isImageType = imgFilePattern.test(file?.name);
        if (file && !isImageType) {
            toast.error("Invalid file type");
            return
        }

        if (file && isImageType) {
            dispatch(onUploadNewFrame({cctv_id, uploadedFrame: URL.createObjectURL(file)}))
            dispatch(setFile(file));
            toast.success("Uploaded successfully");
        }
    }
}

export default useUploadFrameHandler;