import React from 'react';
import EmptyComponent from "../Gate/EmptyComponent";
import GeneralInfo from "./InfoBlocks/GeneralInfo";
import ArrivalInfo from "./InfoBlocks/ArrivalInfo";
import DepartureInfo from "./InfoBlocks/DepartureInfo";

const BuildTurnaroundInfo = ({turnaround}) => {
    if (!turnaround || Object.keys(turnaround).length === 0) {
        return <EmptyComponent text={"There is no information about this turnaround"} emptyClassComponent={'gatesEmptyBlock'}/>
    }

    const {id, current_airport, airline, aircraft_type, aircraft_reg_number, parking_gate,
        arrival_airport, arrival_flight_number, outgoing_gate, sta_utc, ata_utc, eta_utc,
        departure_airport, departure_flight_number, incoming_gate, std_utc, atd_utc, etd_utc} = turnaround


    const turnInfoState = () => {

        const general = {id, aircraft_reg_number, aircraft_type, current_airport, parking_gate, airline}
        const arrival = {arrival_airport,arrival_flight_number,eta_utc,ata_utc,sta_utc,outgoing_gate}
        const departure = {departure_flight_number,departure_airport,etd_utc,atd_utc,std_utc,incoming_gate}

        return {general, arrival, departure}
    }


    return (
        <section className={'turnInfoSection'}>
            <GeneralInfo {...turnInfoState().general}/>

            <ArrivalInfo {...turnInfoState().arrival}/>

            <DepartureInfo {...turnInfoState().departure}/>
        </section>
    );
};

export default BuildTurnaroundInfo;