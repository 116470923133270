import {combineReducers} from "redux";
import DownloadWsFile from "../slices/Websocket/DownloadWsFile";
import ArchiveTurn from "../slices/Websocket/ArchiveTurn";
import General from "../slices/Websocket/General";
import Alert from '../slices/Websocket/Alert'

const Websocket = combineReducers({
    download_ws_slice: DownloadWsFile,
    archive_turn: ArchiveTurn,
    alert: Alert,
    general: General
})

export default Websocket