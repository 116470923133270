import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    time_zone: null
}

const Geotime = createSlice({
    name: 'geotime',
    initialState,
    reducers: {
        changeTimeZone(state, action) {
            state.time_zone = action.payload
            localStorage.setItem('Time_Zone', action.payload)
        },
        getLocalStorageTimeZone(state, {payload}) {
            state.time_zone = payload
        }
    }
})


export default Geotime.reducer
export const {changeTimeZone, getLocalStorageTimeZone} = Geotime.actions