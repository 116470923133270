import React from 'react';
import {SIMPLE_LOADING} from "../ANTD/Icons";
import EmptyImg from "../../images/no_image.jpg"

const SimpleRequestImgFallbackWrapper = ({error, loading, children, errorClasses, loadingClasses, colorLoading}) => {
    if (loading) {
        return (
            <div className={loadingClasses}>
                <span>{SIMPLE_LOADING(100, colorLoading)}</span>
            </div>
        )
    }

    if (error) {
        return (
            <div className={errorClasses}>
                <img src={EmptyImg} alt="Error img"/>
            </div>
        )
    }

    return children
};

export default SimpleRequestImgFallbackWrapper;