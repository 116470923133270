import React from 'react';
import Camera from "./Camera/Camera";
import {useSelector} from "react-redux";
import {selectCctvFrames} from "../../reduxToolkit/selectors/Api/frames";
import {setErrorImage} from "../../utils/ErrorImages";

const PreviewGate = ({cameras, camera}) => {
    const listOfImages = useSelector(selectCctvFrames);

    if (Object.keys(camera).length > 0) {
        return (
            <Camera
                {...camera}
                imgPath={setErrorImage(+listOfImages[camera['cctv_id']]?.imgError, listOfImages[camera['cctv_id']]?.imgLink)}
                isReadyToSend={!listOfImages[camera['cctv_id']]?.imgLink && !listOfImages[camera['cctv_id']]?.imgError}
                imgLoading={listOfImages[camera['cctv_id']]?.isImgLoading}
            />
        )
    }

    if (cameras.length > 0) {
        return cameras?.map((camera) => {
            return (
                <Camera
                    {...camera}
                    key={camera['cctv_id']}
                    isReadyToSend={!listOfImages[camera['cctv_id']]?.imgLink && !listOfImages[camera['cctv_id']]?.imgError}

                    imgPath={setErrorImage(+listOfImages[camera['cctv_id']]?.imgError, listOfImages[camera['cctv_id']]?.imgLink)}
                    imgLoading={listOfImages[camera['cctv_id']]?.isImgLoading}
                />
            )
        })
    }

    return (
        <div className={'emptyBlock'}>
            <h1>Empty camera list</h1>
            <h2>You can't finish without camera</h2>
        </div>
    )
};

export default PreviewGate;