import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toggleStorage} from "../../../reduxToolkit/slices/Api/newGate";
import {useNavigate, useParams} from "react-router-dom";
import PreviewImg from "../PreviewImg";
import {
    getUploadedFrame,
    readStream
} from "../../../reduxToolkit/asyncThunkFunctions/Api/ImageRequests";
import {FORCE_FORM_GATE_STEP} from "../../../utils/RouteSettings/Links";
import {selectCctvFrames} from "../../../reduxToolkit/selectors/Api/frames";
import {selectIdCamera} from "../../../reduxToolkit/selectors/Api/formAddGate";
import PreviewBIgImg from "../StepThreeNew/PreviewBIgImg";
import ModalWrapper from "../../Customs/ModalWrapper";
import classNames from "classnames";
import {getStorageValue, setSessionStorageValue} from "../../../utils/CacheHelper";

const Third = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {id, name} = useParams();
    const listOfImages = useSelector(selectCctvFrames);
    const id_camera = useSelector(selectIdCamera)

    const [showHideImg, setShowHideImg] = useState(false);

    const [isWhatDraw, setIsWhatDraw] = useState('');

    const modalClasses = classNames('modalWrapper', {
        'openImgModal': showHideImg, 'closeImgModal': !showHideImg
    });

    const handleForceNavStep = (form_step) => {
        return navigate(FORCE_FORM_GATE_STEP({_id: name, cctv_id: id, form_step}))
    }
    const backToSetUpCameraInfo = () => handleForceNavStep('step_two');

    const handleNextStep = () => {
        if (!listOfImages[id_camera]?.imgError) {
            dispatch(toggleStorage(true));
        }
        handleForceNavStep('preview')
    }

    const handleClickHideImg = () => setShowHideImg(false);

    const handleClickShowImg = (value) => {
        setIsWhatDraw(value);
        setShowHideImg(true);
    }

    const frameRequests = ({url, cctv_id}) => {
        dispatch(readStream({url, cctv_id, isReSendLastFrameHere: true}));
        dispatch(getUploadedFrame(cctv_id))
    }

    const initializeStorageCameraProperties = (storageCamera) => {
        if (storageCamera.position === "ramp") {
            if (!storageCamera.calibration) storageCamera.calibration = {lines: [], landmarks: []}
            else {
                if (!storageCamera.calibration.lines) storageCamera.calibration.lines = [];
                if (!storageCamera.calibration.landmarks) storageCamera.calibration.landmarks = [];
            }

            if (!storageCamera.clearance_points) storageCamera.clearance_points = [];
        }
        if (storageCamera.position === "bridge" && !storageCamera.doors_point) storageCamera.doors_point = {};
    }

    const initializeFrameAndDrawFields = () => {
        const storageCamera = getStorageValue("camera", {}, "session");
        if (storageCamera) {
            const {url, cctv_id} = storageCamera

            initializeStorageCameraProperties(storageCamera);
            setSessionStorageValue("camera", JSON.stringify(storageCamera));

            if (!listOfImages[id_camera]?.imgLink) frameRequests({url, cctv_id})
        }
    }

    useEffect(() => {
        initializeFrameAndDrawFields()
    }, []);

    return (
        <div style={{width: '600px'}}>
            <div className={'formGate imageSetting'}>
                <PreviewImg
                    handleClickShowImg={handleClickShowImg}
                    handleNextStep={handleNextStep}
                    backToSetUpCameraInfo={backToSetUpCameraInfo}
                />

                <ModalWrapper modalClasses={modalClasses}>
                    <PreviewBIgImg hide={handleClickHideImg} showHideImg={showHideImg} isWhatDraw={isWhatDraw}/>
                </ModalWrapper>
            </div>
        </div>
    );
};

export default Third;