import React from 'react';

const ColorEventTableInstruction = ({colors, isShowColorSettings}) => {
    if (!isShowColorSettings) return null
    return colors.map(({color, text}) => {
        return (
            <div key={text} className={'colorInstructions'}>
                <div style={{backgroundColor: color}} className={'colorInstructions__color'}/>
                <span className={'colorInstructions__text'}>{text}</span>
            </div>
        )
    })
};

export default ColorEventTableInstruction;