import {useEffect} from 'react';
import usePlacesAutocomplete, {getGeocode, getLatLng} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import AutoCompleteSuggestions from "./AutoCompleteSuggestions";
import {Button, Input} from "antd";
import {SEARCH} from "../ANTD/Icons";

const AutoComplete = ({isLoaded, handleAddCenter}) => {
    const {
        ready,
        value,
        suggestions: { status, data: suggestions },
        setValue,
        clearSuggestions,
        init
    } = usePlacesAutocomplete({callbackName: "Google Maps Field", initOnMount: false, debounce: 300});
    const ref = useOnclickOutside(() => {clearSuggestions()});
    const handleInput = (e) => setValue(e.target.value);

    const getCurrentPosition = (description) => {
        setValue(description, false);
        clearSuggestions();

        getGeocode({ address: description }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            handleAddCenter({lat, lng});
        });
    }

    const onPressSearchButton = () => getCurrentPosition(value);

    const handleSelect = ({ description}) => getCurrentPosition(description);

    const onPressEnter = ({key, target: {value}}) => {
        if (key === "Enter") getCurrentPosition(value)
    }

    useEffect(() => {
        if (isLoaded) init()
    }, [isLoaded, init]);

    return (
        <div ref={ref} className={'autocomplete-wrapper'}>
            <div className={'autocomplete-input-container'}>
                <Input
                    value={value}
                    onChange={handleInput}
                    disabled={!ready} placeholder="Where are you going?"
                    onKeyPress={onPressEnter}
                />
                <Button onClick={onPressSearchButton} icon={SEARCH}/>
            </div>
            {status === "OK" && (
                <ul className="autocomplete-suggestions-list">
                    <AutoCompleteSuggestions suggestions={suggestions} handleSelect={handleSelect}/>
                </ul>
            )}
</div>
)
    ;
};

export default AutoComplete;