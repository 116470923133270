import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Header from "../components/Header/Header";
import {Route, Routes, useLocation} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Not_Found from "../components/Errors/Not_Found";
import {errorMessages} from "../utils/ErrorMessages";
import {ROUTES} from "../utils/RouteSettings/Routes";
import {PATHS} from "../utils/RouteSettings/Paths";
import {getUiSettings} from "../reduxToolkit/asyncThunkFunctions/Api/SystemRequests";
import {handleLogout} from "../reduxToolkit/slices/Api/Auth";
import WebsocketProcess from "../components/WebsocketProcess";
import {selectAuthUser} from "../reduxToolkit/selectors/Api/auth";
import {connectionToAlerts, getAlertMessage} from "../reduxToolkit/asyncThunkFunctions/Weboscket/AlertRequests";
import {toast} from 'react-toastify';
const PrimaryRoutes = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {profile, isAuth} = useSelector(selectAuthUser);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            toast.error('Unauthorized');
            dispatch(handleLogout());
        }
    }, [location.pathname]);

    const firstRequests = async () => {
        await dispatch(getUiSettings());
        await dispatch(connectionToAlerts());
        await dispatch(getAlertMessage());
    }

    useEffect(() => {
        firstRequests()
    }, [])

    return (
        <>
            <Header/>
            <WebsocketProcess/>
            <Routes>
                <Route element={<ProtectedRoute isAuth={isAuth}/>}>
                    {ROUTES?.map(({roles, path, component}) => {
                        if (roles?.includes(profile?.role)) return <Route key={path} path={path} element={component}/>
                    })}
                </Route>
                <Route path={'/404'} element={<Not_Found errorMessage={errorMessages.broken_page}/>}/>
                <Route path={PATHS.STAR} element={<Not_Found errorMessage={errorMessages.not_found}/>}/>
            </Routes>
        </>
    );
}


export default PrimaryRoutes