import React from 'react';
import {Tooltip} from "antd";
import {Link} from "react-router-dom"
import {useSelector} from "react-redux";
import {returnCorrectTimeZoneTime} from "../../utils/TimeHelper";
import {RegCAMERA} from "../ANTD/Icons";
import {selectTimeZone} from "../../reduxToolkit/selectors/Ui/TimeZone";

const Cameras = ({frames, parking_gate, id}) => {
    const timeZone = useSelector(selectTimeZone);
    const sortFrames = () => [...frames].sort((a, b) => a.cctv_id - b.cctv_id)
    const replacedChangeTimeZone = (time) => returnCorrectTimeZoneTime(time, timeZone)

    return (
        <div className={'blockFrames'}>
            {sortFrames()?.map(({cctv_id, count, first, last}) => {
                const path = `/turnaround/${id}/${parking_gate}/${cctv_id}`
                const tooltip = `First frame: ${first && replacedChangeTimeZone(first)} : Last frame: ${last && replacedChangeTimeZone(last)} :`

                return (
                    <div className={'blockFrames__wrapperFidsCamera'} key={cctv_id}>
                        <Tooltip title={tooltip}>
                            <Link to={path} disabled={count <= 0} className={"fidsCamera"}>{RegCAMERA}</Link>
                        </Tooltip>
                        <h4 className={'idFidCamera'}>{cctv_id} / {count}</h4>
                    </div>
                )
            })}
        </div>
    );
};

export default Cameras;