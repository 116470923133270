import {useEffect, useMemo} from 'react';
import {Table} from "antd";
import {getLogs, getUniqueLogsValues} from "../../reduxToolkit/asyncThunkFunctions/Api/AuditRequests";
import {selectLogs, selectUniqueValues} from "../../reduxToolkit/selectors/Api/logs";
import {useDispatch, useSelector} from "react-redux";
import {logsColumns} from "../../utils/Table/Columns/log";
import usePagination from "../../hooks/usePagination";
import {paginationSettings} from "../../utils/Table/tableSettings";
import {setFilterOptionSettings} from "../../utils/helperFunctions";
import {clearUniqueLogsValuesList} from "../../reduxToolkit/slices/Api/Logs";
import useReadyState from "../../hooks/useReadyState";
import {returnCorrectTimeZoneTime} from "../../utils/TimeHelper";
import {selectTimeZone} from "../../reduxToolkit/selectors/Ui/TimeZone";
import {debounce} from "lodash";

const Logs = () => {
    const dispatch = useDispatch();
    const {logs, count, logsLoading, logsError} = useSelector(selectLogs);
    const {uniqueLogValues} = useSelector(selectUniqueValues);
    const timeZone = useSelector(selectTimeZone);

    const {page, limit, tools, setSettings} = usePagination(1, 10);
    const {state: isOpenDatePicker, onChangeReadyState: setIsOpenDatePicker} = useReadyState(false);

    const transformAuditLogDate = (time) => returnCorrectTimeZoneTime(time, timeZone)

    const transformatedList = useMemo(() => setFilterOptionSettings(uniqueLogValues), [uniqueLogValues]);

    const updateTable = () => dispatch(getLogs({...tools, page, limit}));

    const onChange = ({current: page, pageSize: limit}, filter, sort) => {
        const {datetime ,...rest} = filter

        if (datetime && Array.isArray(datetime)) {
            const [from, to] = datetime
            rest.datetime_from = new Date(from).getTime();
            rest.datetime_to = new Date(to).getTime();
        } else {
            rest.datetime_from = null
            rest.datetime_to = null
        }

        setSettings(page, limit, rest)
    }

    const onFilterVisibleChange = (isVisible, key, cb) => {
        if (isVisible) {
            return dispatch(cb(key))
        } else {
            dispatch(clearUniqueLogsValuesList())
        }
    }
    const onGateUsers = (visible) => onFilterVisibleChange(visible, "user", getUniqueLogsValues);
    const onGetFunctions = (visible) => onFilterVisibleChange(visible, "function", getUniqueLogsValues);
    const onGetIps = (visible) => onFilterVisibleChange(visible, "ip", getUniqueLogsValues);

    const auditLogColumn = logsColumns({
        transformatedList,
        onGateUsers,
        onGetFunctions,
        onGetIps,
        isOpenDatePicker,
        setIsOpenDatePicker,
        transformAuditLogDate
    })

    useEffect(() => {
        updateTable();
    }, [page, limit, tools]);


    useEffect(() => {
        const debouncedClosePopup = debounce(() => {
            console.log('RENDER RESIZE')
            setIsOpenDatePicker(false);
        }, 1000);

        const handleResize = () => debouncedClosePopup();

        if (isOpenDatePicker) {
            window.addEventListener('resize', handleResize);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpenDatePicker]);


    return (
        <div className={'wrapperAdministration'}>
            <Table
                sticky
                dataSource={logs}
                pagination={{...paginationSettings(page, limit, count)}}
                columns={auditLogColumn}
                loading={logsLoading}
                rowKey={"_id"}
                onChange={onChange}
            />
        </div>
    );
};

export default Logs;