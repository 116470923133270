import React from 'react';
import {Card, Empty} from "antd";
import BigLoading from "../Loadings/BigLoading";
import MarkTimeTeg from "../ANTD/MarkTimeTeg";

const S3Information = ({Arrival, Departure, General, Cameras, isEmpty, isS3FilesLoading}) => {
    const checkField = (filed) => !filed ? 'empty' : filed

    if (isS3FilesLoading) return <BigLoading/>
    if (isEmpty) return <Empty/>

    return (
        <main className={'mock_wrapper__flight_mock_information'}>
            <section className={'flight_information'}>
                <Card title={'Flight Information'} className={'flight_information__flight_file_card_description'}>
                    <div>
                        <h2>Arrival</h2>
                        <div className={'arrival_description'}>
                            <p>DepartureAirport: <span>{Arrival?.['DepartureAirport']}</span></p>
                            <p>Date: <span>{checkField(Arrival?.['Date'])}</span></p>
                            <p>Gate: <span>{checkField(Arrival?.['Gate'])}</span></p>
                            <p>STA: <MarkTimeTeg mark={true} time={Arrival['STA']}/></p>
                        </div>
                    </div>
                    <div>
                        <h2>Departure</h2>
                        <div className={'departure_description'}>
                            <p>ArrivalAirport: <span>{Departure?.['ArrivalAirport']}</span></p>
                            <p>Gate: <span>{checkField(Departure?.['Gate'])}</span></p>
                            <p>STD: <span>{Departure?.['STD']}</span></p>
                        </div>

                    </div>
                    <div>
                        <h2>General</h2>
                        <div className={'general_description'}>
                            <p>Features: <span>{General?.Features}</span></p>
                            <p>Weather: <span>{General?.Weather}</span></p>
                            <p>Parking: <span>{General?.Parking}</span></p>
                            <p>CarrierCode: <span>{General?.CarrierCode}</span></p>
                            <p>AcRegistration: <span>{General?.AcRegistration}</span></p>
                            <p>AcType: <span>{General?.AcType}</span></p>
                        </div>

                    </div>
                </Card>
            </section>
            <section className={'mock_wrapper__flightInfoCameras'}>
                <div className={"flightInfoCamerasScrollContainer"}>
                    {Object.entries(Cameras)?.map(([cctv_id, camera], index) => {
                        return (
                            <Card
                                title={`Camera: ${cctv_id}`}
                                key={cctv_id} size={"small"}
                                className={'flightCamera'}
                                style={{width: 280}}
                            >
                                <div className={'cameraDescription'}>
                                    <p>EmptyFrame: <span>{camera?.EmptyFrame}</span></p>
                                    <p>File: <span>{camera?.File}</span></p>
                                    <p>IdleInterval: <span>Start: {checkField(camera?.IdleInterval['Start'])}</span>
                                        /
                                        <span>End: {checkField(camera?.IdleInterval['End'])}</span>
                                    </p>
                                    <p>Speed: <span>{camera?.Speed}</span></p>
                                    <p>VideoStartOffset: <span>{camera?.VideoStartOffset}</span></p>
                                    <p>VideoEndOffset: <span>{camera?.VideoEndOffset}</span></p>
                                </div>
                            </Card>
                        )
                    })}
                </div>

            </section>
        </main>

    )
};

export default S3Information;
