import React, {useMemo} from 'react';
import {MARK} from "../ANTD/Icons";
import {Tag} from "antd";

const CameraEvents = ({frames, events, is_validated}) => {

    const sortFrames = useMemo(() => [...frames].sort((a, b) => a.cctv_id - b.cctv_id), [frames])

    const filterEvents = useMemo(() => {
        return (cctv_id) => {
            if (!events) return '0';

            return events.filter(e => e.Camera === cctv_id)?.length;
        }
    }, [events])

    const filterValidated = useMemo(() => {
        return events.filter(ev => ev['validation'] !== {} && ev['validation']['finished']).length;
    }, [events])

    return (
        <div className={'blockFrames blockEvents'}>
            <div className={'blockEvents__totalBlock'}>
                <span className={'totalText'}>Total: {events?.length}</span>
                <span className={'totalText'}>Validated: {filterValidated}
                    {is_validated && <Tag color={'#52c41a'}>{MARK}</Tag>}
                </span>
            </div>
            <div className={'blockEvents__blockHorizon'}>
                {sortFrames.map(({cctv_id}) => {
                    return (
                        <div className={'blockFrames__wrapperFidsCamera blockEvents__wrapperEventCamera'} key={cctv_id}>
                            <h4 className={'idFidCamera'}>{cctv_id} / {filterEvents(cctv_id)}</h4>
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default CameraEvents;


