import {createSlice} from "@reduxjs/toolkit";
import {connectionToDownloadFileSession} from "../../asyncThunkFunctions/Weboscket/DownloadFileMessages";

const initialState = {
    download_file: {
        path: '',
        result_message: ''
    },
    connection_download_obj: {
        connection_message: '',
        isConnected: false
    },
    download_file_name: null,
    isDisabled: false,

    array_file_parts: []
}

const DownloadWsFileSlice = createSlice({
    name: 'ws_download_file',
    initialState,
    reducers: {
        saveMessage(state, {payload}) {
            if (payload) {
                state.download_file.path = payload.data
                state.download_file.result_message = payload.message
            }
        },
        handleGetDownloadFileName(state, {payload}) {
            state.download_file_name = payload
        },

        handleOnChangeIsDisabled(state, {payload}) {
            state.isDisabled = payload
        },

        handlePushFilePart(state, {payload}) {
            state.array_file_parts.push(payload)
        },

        returnToDefDownloadFileState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(connectionToDownloadFileSession.pending, (state) => {
            state.connection_download_obj.connection_message = 'connection'
            state.connection_download_obj.isConnected = false
        })
            .addCase(connectionToDownloadFileSession.fulfilled, (state) => {
                state.connection_download_obj.connection_message = 'connected'
                state.connection_download_obj.isConnected = true
            })
            .addCase(connectionToDownloadFileSession.rejected, (state) => {
                state.connection_download_obj.connection_message = 'connection failed'
                state.connection_download_obj.isConnected = false
            })
    }
})


const {reducer, actions} = DownloadWsFileSlice
export default reducer
export const {
    saveMessage,
    handleGetDownloadFileName,
    handleOnChangeIsDisabled,
    returnToDefDownloadFileState,
    handlePushFilePart,
} = actions
