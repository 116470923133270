import React from "react";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {CleanCanvas, DrawCircle, DrawRectangle, DrawText} from "../../utils/DrawingHelper";
import {
    canvas_layersTest,
    object_color,
    projection_colors
} from "../../utils/settingCanvas/settingCanvas";
import {connect} from "react-redux";
import {DOWNLOAD} from "../ANTD/Icons";
import {All, WithoutViewer} from "../../utils/RouteSettings/Roles";

class Canvas extends React.Component {
    constructor(props) {
        super(props);
            this.state = {}
            this.arrayCanvas = [];
            for (let key in this.props?.CanvasLayers) {
                this.arrayCanvas.push({
                    className: `${key.toLowerCase()}_layer`,
                    id: `${key.toLowerCase()}_canvas`,
                    hidden: !this.props?.CanvasLayers[key],
                    ref: React.createRef(),
                    canvas_key: key,
                    roles: ['DETECTIONS', 'CLEARANCE_ZONE', 'IMAGE', 'EVENT_BBOX'].includes(key) ? All : WithoutViewer
                })
            }

            this.contexts = []
            this.orig_image_size = [0, 0];
            this.window_size = [window.screen.width, window.screen.height];
    }

    Init() {
        let contexts = []
        const arrayCanvas = this.filterArrayCanvas()
        for (let i = 0; i < arrayCanvas.length; i++) {
            contexts.push({
                roles: arrayCanvas[i]['roles'],
                context: arrayCanvas[i]['ref'].current.getContext("2d"),
                canvas_key: arrayCanvas[i]['canvas_key']
            },);
            arrayCanvas[i]['ref'].current.width = this.window_size[0];
            arrayCanvas[i]['ref'].current.height = this.window_size[1];
        }
        contexts['0']['context'].imageSmoothingEnabled = false;

        this.contexts = contexts.filter(({roles}) => roles.includes(this.props['role']));
    }

    filterArrayCanvas() {
        return this.arrayCanvas.filter(({roles}) => roles.includes(this.props['role']))
    }

    findCorrectDrawContext(value) {
        if (value) return this.contexts?.find(({canvas_key}) => canvas_key === value)?.context
    }

    componentDidMount() {

        this.Init();
        this.SetImageMyVersion()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.SetImageMyVersion();
    }

    async SetImageMyVersion() {
        const {image, inference, image_path, checkedBoxes, bbox} = this.props

        if (inference && inference.image_size) this.orig_image_size = [inference.image_size[0], inference.image_size[1]];

        const img = new Image();
        img.src = image !== "" ? image : window.location.origin + image_path;
        img.onload = () => {
            this.contexts.forEach(({context}) => {if (context.canvas['className'] !== 'image_layer') CleanCanvas(context)});

            this.contexts['0']?.context.drawImage(img, 0, 0, this.window_size[0], this.window_size[1]);

            if (inference) {
                this.DrawDetectionsTrackers(checkedBoxes['DETECTIONS'], checkedBoxes['TRACKERS']);
                this.DrawVests(checkedBoxes['VESTS']);

                this.DrawKeypoints(checkedBoxes['KEYPOINTS']);
                this.DrawObservedArea(checkedBoxes['PROJECTION']);
                this.DrawProjection(checkedBoxes['PROJECTION']);

            }
            if (bbox && Object.keys(bbox).length) {
                this.DrawBbox(canvas_layersTest['EVENT_BBOX'], bbox['positions'], bbox['label']);
            }

            this.DrawClearanceZone(checkedBoxes['CLEARANCE_ZONE']);
        }
    }

    AlignX(x) {
        return (x / this.orig_image_size[0]) * this.window_size[0];
    }

    AlignY(y) {
        return (y / this.orig_image_size[1]) * this.window_size[1];
    }

    CanvasPointAllignment(bbox) {
        return {x: this.AlignX(bbox[0]), y: this.AlignY(bbox[1])};
    }

    DrawBbox(layer, bbox, label) {
        DrawRectangle(
            this.findCorrectDrawContext(layer),
            this.AlignX(bbox[0]),
            this.AlignY(bbox[1]), this.AlignX(bbox[2] - bbox[0]),
            this.AlignY(bbox[3] - bbox[1]), 2, object_color['default'], label, false
        );
    }

    DrawKeypoints(status) {
        if (status) {
            const {inference} = this.props

            CleanCanvas(this.findCorrectDrawContext(canvas_layersTest['KEYPOINTS']));

            let all_aircraft_data = inference?.detected_objects;
            for (let i=0; i<all_aircraft_data?.length; i++) {
                let obj = all_aircraft_data[i];

                if (!obj.keypoints) {
                    continue;
                }

                let superclass = obj['superclass_name']
                let moved = obj['moved']
                let obj_type_color = superclass in object_color ? object_color[superclass] : object_color['default'];

                for (let key in obj.keypoints) {
                    let newBbox = this.CanvasPointAllignment(obj.keypoints[key]);
                    DrawCircle(this.findCorrectDrawContext(canvas_layersTest['KEYPOINTS']), newBbox.x, newBbox.y, 5, 2, obj_type_color, moved);
                }
            }
        } else {
            CleanCanvas(this.findCorrectDrawContext(canvas_layersTest['KEYPOINTS']));
        }
    }

    DrawObservedArea(status) {
        const {inference: {observed_area}, isCorrectOption} = this.props
        let ctx = this.findCorrectDrawContext(canvas_layersTest['PROJECTION']);

        if (status && ctx && isCorrectOption) {
            ctx.beginPath();
            ctx.setLineDash([])
            ctx.strokeStyle = 'turquoise';
            ctx.lineWidth = 2.5;
            for (let i = 0; i < observed_area?.length; i++) {
                const {x, y} = this.CanvasPointAllignment(observed_area[i]);
                ctx.lineTo(x, y);
            }
            ctx.closePath();
            ctx.stroke();

        } else {
            CleanCanvas(ctx);
        }
    }

    DrawProjection(status) {
        const {selected} = this.props
        let ctx = this.findCorrectDrawContext(canvas_layersTest['PROJECTION']);
        if (status && ctx) {
            return selected?.forEach(({projected_bbox, superclass_name}) => {
                ctx.beginPath();
                ctx.lineWidth = 6;
                ctx.setLineDash([4, 6])
                ctx.strokeStyle = superclass_name in projection_colors ? projection_colors[superclass_name] : projection_colors['default'];
                Array.isArray(projected_bbox) && projected_bbox?.forEach((projected) => {
                    const {x, y} = this.CanvasPointAllignment(projected);
                    ctx.lineTo(x, y);
                })
                ctx.closePath();
                ctx.stroke();

            })
        } else {
            CleanCanvas(this.findCorrectDrawContext(canvas_layersTest['PROJECTION']));
        }
    }

    DrawDetectionsTrackers(detections, trackers) {
        const {selected} = this.props

        const detections_context = this.findCorrectDrawContext(canvas_layersTest['DETECTIONS']);
        const trackers_context = this.findCorrectDrawContext(canvas_layersTest['TRACKERS']);

        if (!detections) CleanCanvas(detections_context);
        if (!trackers) CleanCanvas(trackers_context);

        for (let i = 0; i < selected?.length; i++) {
            const {bbox, superclass_name, subclass_name, superclass_confidence, track_id, subclass_prob, filtered_out} = selected[i]
            const color = superclass_name in object_color ? object_color[superclass_name] : object_color['default'];
            if (detections) {
                let broken_line = filtered_out !== undefined ? filtered_out : false
                DrawRectangle(detections_context,
                    this.AlignX(bbox[0]),
                    this.AlignY(bbox[1]),
                    this.AlignX(bbox[2] - bbox[0]),
                    this.AlignY(bbox[3] - bbox[1]),
                    3, color, superclass_name === 'aircraft' ? superclass_name : subclass_name, false, broken_line);
            }
            if (trackers) {
                let confidence = parseInt(superclass_confidence * 100)
                let sub_confidence = parseInt(subclass_prob * 100)
                DrawText(
                    trackers_context,
                    this.AlignX(bbox[0]), this.AlignY(bbox[1]) + this.AlignX(bbox[3] - bbox[1]),
                    color, `(${track_id},${confidence}%,${sub_confidence}%)`
                );
            }
        }
    }

    DrawVests(status) {
        const ctx = this.findCorrectDrawContext(canvas_layersTest['VESTS'])
        if (status && ctx) {
            const {inference} = this.props
            const detected_objects = inference?.detected_objects;

            detected_objects?.forEach(element => {
                if (element.superclass_name === 'person') {
                        let bbox = element['bbox'];
                        let confidence = parseInt(element['superclass_confidence'] * 100);
                        let label = "";

                        let color = element['vest_color'];
                        if (color === "no_vest" || color === "not_sure") {
                            label = `${color}`;
                            color = "black";
                        } else {
                            color = color.split('_')[1];
                            label = `${element['vest_color']}`;
                        }

                        label = `${label}(${element['track_id']}),${confidence}%`;
                        DrawRectangle(this.findCorrectDrawContext(canvas_layersTest['VESTS']),
                            this.AlignX(bbox[0]),
                            this.AlignY(bbox[1]),
                            this.AlignX(bbox[2] - bbox[0]),
                            this.AlignY(bbox[3] - bbox[1]),
                            2, color, label, false);
                    }
            })
        } else {
            CleanCanvas(ctx);
        }
    }

    DrawClearanceZone(status) {
        let ctx = this.findCorrectDrawContext(canvas_layersTest['CLEARANCE_ZONE']);
        if (!status) CleanCanvas(ctx);

        if (status && ctx) {
            ctx.beginPath();
            ctx.lineWidth = 1.5;
            ctx.strokeStyle = 'blue';
            const clearance_points = this.props.camera['clearance_points'];
            const [width, height] = this.orig_image_size
            for (let i = 0; i < clearance_points.length; i++) {
                let point = clearance_points[i];
                let after = [point[0] * this.window_size[0], point[1] * this.window_size[1]];
                ctx.lineTo(after[0], after[1]);
            }
            ctx.stroke();
            ctx.closePath();
        }
    }


    componentWillUnmount() {
        console.log('componentWillUnmount')

    }


    TransformWrapperState() {
        return {
            initialScale: 1, initialPositionX: 0,
            initialPositionY: 0, doubleClick: {disabled: true},
        }
    }

    render() {
        return (
            <div className="canvas_div">
                <div className={'canvas_div__downloadImage'}>
                    <button onClick={this.props.downloadImage} className={'buttonDownloadImage'}>{DOWNLOAD}</button>
                </div>
                <TransformWrapper {...this.TransformWrapperState()}>
                    <TransformComponent>
                        {this.filterArrayCanvas()?.map((item, i) => {
                            return <canvas
                                key={item.className}
                                className={item.className}
                                id={item.id}
                                ref={item?.ref}
                                hidden={item.hidden}
                            />
                        })}
                    </TransformComponent>
                </TransformWrapper>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    camera: state.api.formGate.editCamera.camera,
    CanvasLayers: state.api.systemSlice.settingsUI?.CANVAS_LAYERS,
    role: state.api.Auth.profile['role'],
})

export default connect(mapStateToProps, null, null, ({forwardRef: true}))(Canvas)