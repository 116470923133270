import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const InfoContent = ({headerData, sectionData, footerData, sectionTitleText, footerTitleText}) => {
    return (
        <>
            <header style={{marginBottom: '10px'}}><p>{headerData}</p></header>
            <section style={{marginBottom: '10px'}}>
                <p>{sectionTitleText}</p>
                {sectionData?.map(({id, text, additions, color}) => {
                    return (
                        <div key={id} style={{margin: '2px 0'}}>
                            <p style={{fontWeight: 600, }}>{id}: <span style={{fontWeight: 500, marginLeft: '2px'}}>{text}</span> {color && <span style={{ display: 'block', width: '15px', height: '15px', border: '1px solid #000000', backgroundColor: color, marginLeft: '10px'}}/>}</p>
                            {additions?.map(additional => <p key={uuidv4()} style={{marginLeft: '5px', fontWeight: 600}}>
                                *
                                <span style={{fontWeight: 500}}>{additional}</span>
                            </p>)}
                        </div>
                    )
                })}
            </section>
            <footer>
                <p style={{margin: '10px 0'}}>{footerTitleText}</p>
                {footerData?.map(({name, description}) => {
                    return <p key={name} style={{fontWeight: 600}}>{name}: <span
                        style={{fontWeight: 500}}>{description}</span></p>
                })}
            </footer>
        </>
    );
};

export default InfoContent;