import React from 'react';
import {Button} from "antd";

const PopoverAvatarContent = ({logout, profile, _id, isAccountPage}) => {
    return (
        <div className={'wrapperPopoverAvatarContent'}>
            <h3>{_id}</h3>
            <div className={'wrapperPopoverAvatarContent__popoverAvatarContent'}>
                {isAccountPage && <Button onClick={profile} size={'middle'} type={'primary'}>Profile</Button>}
                <Button onClick={logout} size={'middle'} type={'primary'}>Logout</Button>
            </div>
        </div>
    );
};

export default PopoverAvatarContent;