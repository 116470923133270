import React from 'react';
import {Button, Tooltip} from "antd";
import {CLOSE, SAVE} from "./Icons";

const EditableBtnToggle = ({isEdited, save, cancel, children}) => {
    if (isEdited) {
        return (
            <>
                <Tooltip placement="bottomRight" title={"save"}>
                    <Button icon={SAVE} onClick={save}/>
                </Tooltip>

                <Tooltip placement="bottomLeft" title={"close"}>
                    <Button icon={CLOSE} className={'cameraButton'} onClick={cancel}/>
                </Tooltip>
            </>
        )
    }
    return children
};

export default EditableBtnToggle;