export function getStorageValue(key, defaultValue = {}, isWhichStorage = 'session') {
    const saved = isWhichStorage === 'session' ? sessionStorage.getItem(key) : localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
}

export function setLocalStorageValue (key, value) {
   return localStorage.setItem(key, value)
}

export function setSessionStorageValue (key, value) {
    return sessionStorage.setItem(key, value)
}

export const setAllUserSettings = (token, profile) => {
    setLocalStorageValue('token', token)
    setLocalStorageValue('profile', JSON.stringify(profile))
}

export const removeCashFile = (elem) => {
    if (localStorage.getItem(elem)) {
        return localStorage.removeItem(elem)
    }
}


export const clearLocalStorage = () => localStorage.clear()
export const clearSessionStorage = () => sessionStorage.clear()

export const setCacheTableSettingsGroup = (key, value) => {
    const cacheTableSettings = getStorageValue('tableGroupsSetting', {}, 'local');
    return setLocalStorageValue('tableGroupsSetting', JSON.stringify({...cacheTableSettings, [key]: value}))
}