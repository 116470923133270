import React from "react";
import classNames from "classnames";

const InputFile = ({onChange, icon, accept, label_text, disabled}) => {
    const inputFileClasses = classNames("input_file_container", {
        "disabled_input_file_container": disabled
    })
    return (
        <div className={inputFileClasses}>
            <label htmlFor={"my_file"} className={"input_file_trigger"}>
                <input type="file" className={"file_input"} id={"my_file"} onChange={onChange} accept={accept}
                       disabled={disabled}/>
                <span>{icon} {label_text}</span>
            </label>
            {/*<input type="file" className={"file_input"} id={"my_file"} onChange={onChange} accept={accept}*/}
            {/*       disabled={disabled}/>*/}
            {/*<label htmlFor={"my_file"} className={"input_file_trigger"}>{icon} {label_text}</label>*/}
        </div>
    )
}

export default InputFile