import React from 'react';
import {Table} from "antd";

const SummaryTable = ({colSpan, index, children}) => {
    return (
        <Table.Summary.Row>
            <Table.Summary.Cell index={index} colSpan={colSpan}>
                {children}
            </Table.Summary.Cell>
        </Table.Summary.Row>
    );
};

export default SummaryTable;