import {useMemo} from 'react';
import {Tag, Tooltip} from "antd";
import {ADMIN, DEVELOPER, VIEWER, VALIDATOR} from "../../utils/RouteSettings/Roles";

const Role = ({role, onDoubleClick, onClick, tooltip}) => {
    const colorRole = useMemo(() => {
        if (role === ADMIN) return 'magenta'
        if (role === DEVELOPER) return 'gold'
        if (role === VALIDATOR) return 'cyan'
        if (role === VIEWER) return 'purple'
    }, [role])

    return (
        <Tooltip {...tooltip}>
            <Tag
                color={colorRole}
                className={'usersRole role'}
                onDoubleClick={onDoubleClick}
                onClick={onClick}
            >
                {role}
            </Tag>
        </Tooltip>

    )
}

export default Role;