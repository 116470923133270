import {createSlice} from "@reduxjs/toolkit";
import {getLogs, getUniqueLogsValues} from "../../asyncThunkFunctions/Api/AuditRequests";

const initialState = {
    logs_obj: {
        logs: [],
        count: 0,
        logsLoading: false,
        logsError: null
    },

    uniqueLogsObj: {
        uniqueLogValues: [],
        isUniqueLoading: false,
        uniqueLogsError: null
    }
}

export const logsSlice = createSlice({
    name: 'Logs',
    initialState,
    reducers: {
        clearUniqueLogsValuesList(state) {
            if (state.uniqueLogsObj.uniqueLogValues.length) {
                state.uniqueLogsObj.uniqueLogValues = []
            }
        },
        clearLogsState: () => initialState
    },
    extraReducers: (builder) => {

        builder.addCase(getLogs.pending, (state) => {
            state.logs_obj.logsLoading = true
            state.logs_obj.logsError = null
        })
            .addCase(getLogs.fulfilled, (state, {payload}) => {
                state.logs_obj.logs = payload.logs
                state.logs_obj.count = payload.count
                state.logs_obj.logsLoading = false
        })
            .addCase(getLogs.rejected, (state, {error}) => {
                state.logs_obj.logsLoading = false
                state.logs_obj.logsError = error.message
        })

        builder.addCase(getUniqueLogsValues.pending, (state) => {
                state.uniqueLogsObj.isUniqueLoading = true
                state.uniqueLogsObj.uniqueLogsError = null
        })
            .addCase(getUniqueLogsValues.fulfilled, (state, {payload}) => {
                state.uniqueLogsObj.uniqueLogValues = payload
                state.uniqueLogsObj.isUniqueLoading = false
            })
            .addCase(getUniqueLogsValues.rejected, (state, {error}) => {
                state.uniqueLogsObj.uniqueLogsError = error.message
                state.uniqueLogsObj.isUniqueLoading = false
            })
    }
})


export default logsSlice.reducer

export const {clearLogsState, clearUniqueLogsValuesList} = logsSlice.actions