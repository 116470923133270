import React from 'react';
import {Button, Tag, Tooltip} from "antd";
import {DOWN_ARROW_CIRCLE} from "../../ANTD/Icons";
import EditableBtnToggle from "../../ANTD/EditableBtnToggle";
import {useDispatch, useSelector} from "react-redux";
import {editPotentialEvent, getPotentialEvents} from "../../../reduxToolkit/asyncThunkFunctions/Api/EventsRequest";
import {selectPotentialEvents} from "../../../reduxToolkit/selectors/Api/events";

const EventPosition = ({save, cancel, isEdited, editingKey, edit, event: {uuid, positions, required, visibility, ...rest}, camera, gate}) => {
    const dispatch = useDispatch();
    const {potentialPage: page, potentialLimit: limit, potentialFilterObj} = useSelector(selectPotentialEvents);

    const tag_value = visibility.length > 0 ? visibility.join(', ') : "not visible"

    const handleSavePositionChanges = () => {
        save(async ({visibility: edit_positions}) => {
            const event = {...rest, gate, camera, visibility: edit_positions}
            await dispatch(editPotentialEvent(event))
            await dispatch(getPotentialEvents({page, limit, gate, camera, ...potentialFilterObj}))
        })
    }

    return (
        <EditableBtnToggle isEdited={isEdited} save={handleSavePositionChanges} cancel={cancel}>
            <div>
                <Tag color={visibility.length ? 'blue' : "red"} style={{color: '#000'}}>{tag_value}</Tag>
                <Tooltip title={!editingKey && "drop_down"}>
                    <Button onClick={() => edit({visibility}, uuid)} disabled={editingKey !== ''}>{DOWN_ARROW_CIRCLE}</Button>
                </Tooltip>
            </div>
        </EditableBtnToggle>
    );
};

export default EventPosition;