import {useState, useEffect, useCallback} from "react";

export default function useImg (url) {
    const [imgSizes, setImgSizes] = useState({img_width: 100, img_height: 100});
    const [errorImg, setErrorImg] = useState(null);
    const [loadingImg, setLoadingImg] = useState(true);

    const onChangeImgSizes = useCallback((img_sizes) => setImgSizes(img_sizes), []);

    useEffect(() => {
        const img = new Image();
        if (url) {
            img.src = url
            img.onload =  () => {
                onChangeImgSizes({img_width: img.naturalWidth, img_height: img.naturalHeight})
                setLoadingImg(false)
            }
            img.onerror = (e) => setErrorImg(e)
        }

        return () => {
            img.onload = null
        }
    }, [url, onChangeImgSizes]);

    return {...imgSizes, loadingImg, errorImg}
}