import {useEffect} from 'react';
import {Slider} from "antd";
import useReadyState from "../../hooks/useReadyState";

const TimeLine = ({marks, maxSize, formatter, minSize, onChangeComplete, currentFrame}) => {
    const {state: slideNumber, onChangeReadyState: onChangeSlideNumber} = useReadyState(0);

    useEffect(() => {
        if (currentFrame !== slideNumber) {
            onChangeSlideNumber(currentFrame);
        }
    }, [currentFrame])

    return (
        <div className={'turnSlideBlock'}>
            <Slider tooltip={{formatter}} marks={marks}
                    value={slideNumber} max={maxSize}
                    min={minSize} onChangeComplete={onChangeComplete}
                    onChange={onChangeSlideNumber}
            />
        </div>
    );
};

export default TimeLine;