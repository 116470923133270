import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from "react-toastify";
import {setAllUserSettings} from "../../../utils/CacheHelper";
import {baseUrl} from "../../../utils/helpers/variables/api";

export const login = createAsyncThunk('Auth/login',
    async (body) => {
        try {
            const {data} = await axios.post(`${baseUrl}/auth/login`, body, {withCredentials: true});
            const {token, role, _id, is_active} = data
            setAllUserSettings(token.split(' ')[1], {_id, role, is_active})
            return data
        } catch (error) {
            const status = error.response['status']
            if (status === 401 || status === 403) {
                toast.error("Incorrect login details")
            }
            console.log(error.response, 'ERROR')
            throw new Error(error)
        }
})