import React from 'react';
import {Form, Select} from "antd";
import {setOptionsSettings} from "../../utils/helperFunctions";

const EditableSelectCell = (
    {
        editing, dataIndex, title,
        inputType, record, index, onChange,
        children, ...restProps
    }) => {

    return (
        <td {...restProps}>
            {editing ? (
                <div>
                    <Form.Item name={dataIndex} style={{margin: 0}}>
                        <Select
                            onChange={onChange}
                            mode="multiple"
                            maxTagCount={"responsive"}
                            placeholder={"Select options"}
                            options={setOptionsSettings(record['positions'])}
                            style={{width: "100%"}}
                            size={"small"}
                            variant={'filled'}
                            defaultOpen
                            showSearch={false}
                            removeIcon={false}
                        />
                    </Form.Item>
                    {(children)}
                </div>

            ) : (children)
            }
        </td>
    )
}

export default EditableSelectCell;