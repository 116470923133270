export const required = true

export const messChoseGen = (text) => `Please chose the ${text}`
export const messWriteNumbGen = (text) => `Please write the number of ${text}`
export const messAddSomeGen = (text) => `Please add the ${text}`



export const rules = {
    process: [{required, message: messChoseGen('process')}],
    event: [{required, message: messChoseGen('event')}],
    correct_time: [{required, message: messWriteNumbGen('correct_time')}],
    cctv_id: [{required, message: messWriteNumbGen('cctv_id')}],
    timestamp: [{required, message: messAddSomeGen('timestamp')}],
    reject_reason: [{required, message: messChoseGen('reason!')}]
}
