import React from 'react';
import {drawSheetBtnsSettings} from "../../../utils/helpers/objects/PreviewImgButton";
import {Popconfirm, Button} from "antd";

const PreviewImgBtnActions = ({cameraPosition, isDisabled, isLoading, handleClickShowImg}) => {
    return (
        <section className={'wrapperSettingButtons'}>
            { drawSheetBtnsSettings[cameraPosition]?.map(({id, name, value, isPopConfirm, confirmProps, openValues}) => {
                if (isPopConfirm) {
                    return (
                        <Popconfirm
                            key={id}
                            {...confirmProps}
                            onCancel={() => handleClickShowImg(openValues[0])}
                            onConfirm={() => handleClickShowImg(openValues[1])}
                            disabled={isDisabled}
                            okType={"default"}
                        >
                            <Button size={'large'} type={'primary'} disabled={isDisabled} loading={isLoading}>
                                {name}
                            </Button>
                        </Popconfirm>
                    )
                }
                return (
                    <Button size={'large'} type={'primary'} key={id} onClick={() => handleClickShowImg(value)} disabled={isDisabled} loading={isLoading}>
                        {name}
                    </Button>
                )
            })}
        </section>
    )
};

export default PreviewImgBtnActions;