import React from 'react';
import ColorEventTableInstruction from "./ColorEventTableInstruction";
import {colorHelperMainEventTable} from "../../utils/helperArrays";

const FooterMainEventTable = ({isFinish, isShowColorSettings, finishValidInformation}) => {
    return (
        <div className={'footerMainEventTable'}>
            <div className={'footerMainEventTable__wrapperColorInstructions'}>
                <ColorEventTableInstruction colors={colorHelperMainEventTable} isShowColorSettings={isShowColorSettings}/>
            </div>
            <div>
                {isFinish && <span>{finishValidInformation}</span>}
            </div>
        </div>
    );
};

export default FooterMainEventTable;