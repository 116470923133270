import CameraButtons from "../../../components/Configuration/CameraButtons";
import SettingRow from "../../../components/Configuration/SettingRow";
import EmptyField from "../../../components/Customs/EmptyField";

export const configurationColumns = ({isEditing, edit, editingKey, cancel, save}) => ([
    {
        key: '_id',
        title: 'Gate_name',
        dataIndex: '_id',
        editable: true,
        width: '40%',
        render: (_id) => {
            return (
                <EmptyField isEmpty={!_id} message={'N/A'} tagColor={"#006d75"}>
                    <span>{_id}</span>
                </EmptyField>
            )
        }
    },
    {
        key: 'cameras',
        title: 'Cameras',
        dataIndex: 'cameras',
        width: '40%',
        render: (frames, record) => {
            return (
                <EmptyField isEmpty={!frames.length} message={'N/A'} tagColor={"#006d75"}>
                    {
                        frames?.map(camera => {
                            return (
                                <CameraButtons
                                    key={camera['cctv_id']}
                                    camera={camera}
                                    _id={record['_id']}
                                />
                            )
                        })
                    }
                </EmptyField>
            )
        }
    },
    {
        key: 'actions',
        title: 'Actions',
        dataIndex: 'actions',
        width: '20%',
        render: (_, record) => {
            return <SettingRow record={record} edit={edit} editingKey={editingKey}
                               cancel={cancel} isEditing={isEditing} save={save}/>
        }
    }
])