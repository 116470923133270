import {createSlice} from "@reduxjs/toolkit";
import {
    getCalibrationResult,
    getLastFrame, getUploadedFrame,
    getVideoFrame,
    readStream,
} from "../../asyncThunkFunctions/Api/ImageRequests";
import {updateFrameState} from "../../../utils/helpers/functions/redux";

const initialState = {
    cctv_frames : {},
    uploadedFrameObj: {
        uploadedFrame: null,
        isUploadedLoading: false,
        uploadedError: null,
    },
    calibrationResultObj: {
        calibrationResult: null,
        isCalibrationResLoading: false,
        calibrationResultError: null
    }
}

const imagesSlice = createSlice({
    name: 'imagesSlice',
    initialState,
    reducers: {
        onRemoveCamera(state, {payload}) {
            delete state.cctv_frames[payload]
        },
        onUploadNewFrame(state, {payload: {cctv_id, uploadedFrame}}) {
            state.uploadedFrameObj.uploadedFrame = uploadedFrame
            if (state.uploadedFrameObj.uploadedError) state.uploadedFrameObj.uploadedError = null

            if (state.cctv_frames[cctv_id].imgError) state.cctv_frames[cctv_id].imgError = null
        },
        clearImgStates: () => initialState
    },
    extraReducers: (builder) => {

        const actions = [
            {action: getLastFrame, errorPrefix: 'lastFrame'},
            {action: readStream, errorPrefix: 'readStream'},
            {action: getVideoFrame, errorPrefix: 'videoFrame'},
        ];

        actions.forEach(({ action, errorPrefix }) => {
            const {pending, fulfilled, rejected} = action

            builder
                .addCase(pending, (state, { meta: { arg: { cctv_id } } }) => {
                    updateFrameState(state, cctv_id, null, true, null, 'pending');
                })
                .addCase(fulfilled, (state, { payload, meta: { arg: { cctv_id } } }) => {
                    updateFrameState(state, cctv_id, payload, false, null, 'fulfilled');
                })
                .addCase(rejected, (state, { error, meta }) => {
                    const { cctv_id, isReSendLastFrameHere, isReSendReadStreamHere } = meta.arg
                    const correct_prefix = {
                        readStream: isReSendLastFrameHere,
                        lastFrame: isReSendReadStreamHere
                    };
                    const isNotFound = error.message === "404"


                    if (correct_prefix[errorPrefix] && isNotFound) {
                        updateFrameState(state, cctv_id, null, true, null, 'rejected');
                        console.log(errorPrefix, `found ${errorPrefix}`, isNotFound, 'status');
                    } else {
                        updateFrameState(state, cctv_id, null, false, error.message, 'rejected');
                        console.log(`${errorPrefix} error in ${rejected}`);
                    }
                });
        });

        builder.addCase(getUploadedFrame.pending, (state) => {
            state.uploadedFrameObj.isUploadedLoading = true
            state.uploadedFrameObj.uploadedError = null
        })
            .addCase(getUploadedFrame.fulfilled, (state, {payload}) => {
                state.uploadedFrameObj.uploadedFrame = payload
                state.uploadedFrameObj.isUploadedLoading = false
            })
            .addCase(getUploadedFrame.rejected, (state, {error}) => {
                state.uploadedFrameObj.uploadedError = error.message
                state.uploadedFrameObj.isUploadedLoading = false
            })

        builder.addCase(getCalibrationResult.pending, (state) => {
            state.calibrationResultObj.isCalibrationResLoading = true
            state.calibrationResultObj.calibrationResultError = false

        })
            .addCase(getCalibrationResult.fulfilled, (state, {payload}) => {
                state.calibrationResultObj.calibrationResult = payload
                state.calibrationResultObj.isCalibrationResLoading = false
            })
            .addCase(getCalibrationResult.rejected, (state, {error}) => {
                state.calibrationResultObj.calibrationResultError = error.message
                state.calibrationResultObj.isCalibrationResLoading = false
            })
    }
})

const {reducer, actions} = imagesSlice

export const {clearImgStates, onRemoveCamera, onUploadNewFrame} = actions
export default reducer

