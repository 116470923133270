import React from "react";
import {Button, DatePicker, Form, Input} from "antd";

const PerformanceForm = ({onFinish, initialState, form}) => {
    const { RangePicker } = DatePicker;

    return (
        <Form onFinish={onFinish} initialValues={initialState} form={form} className={'performanceWrapper__performanceForm'}>
            <Form.Item name={'interval'} label={'Interval:'}  rules={[{ type: 'array', required: true, message: 'Please select range time!' }]}>
                <RangePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
            </Form.Item>
            <Form.Item name={'turnaround'} label={'Active:'} rules={[{required: true, message: 'Please write something'}]}>
                <Input placeholder={'active turnarounds'}/>
            </Form.Item>

            <Form.Item name={'runtime'} label={'Min: '} rules={[{required: true, message: 'Please write something'}]}>
                <Input placeholder={'min runtime'}/>
            </Form.Item>
            <Form.Item>
                <Button htmlType="submit">Submit</Button>
            </Form.Item>
        </Form>
    )
}


export default PerformanceForm