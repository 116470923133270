import {sortByDate} from "../../helperFunctions";

export const alertColumns = (getUniqueValues, getColumnSearchProps, renderSwitchTimeZoneTime) => ([
    {
        key: 'date',
        title: 'First recieved',
        dataIndex: 'date',
        width: '15%',
        sorter: (a, b) => sortByDate(a['date'], b['date']),
        render: (date) => renderSwitchTimeZoneTime(date)
    },
    {
        key: 'last_update',
        title: 'Last recieved',
        dataIndex: 'last_update',
        width: '15%',
        sorter: (a, b) => sortByDate(a['last_update'], b['last_update']),
        render: (last_update) => renderSwitchTimeZoneTime(last_update)
    },
    {
        key: 'event',
        title: 'Event',
        dataIndex: 'event',
        width: '15%',
        filters: getUniqueValues('event'),
        onFilter: (value, record) => record?.event?.indexOf(value) === 0,
        filterSearch: true
    },
    {
        key: 'context',
        title: 'Context',
        dataIndex: 'context',
        width: '40%',
        render: (context) => {
            return (
                <>
                    <p>gate: {context['gate']}</p>
                    <p>last_frame_time: {renderSwitchTimeZoneTime(context['last frame time'])}</p>
                </>
            )
        }
    },
    {
        key: 'level',
        title: 'Level',
        dataIndex: 'level',
        width: '15%',
        filters: getUniqueValues('level'),
        onFilter: (value, record) => record?.level?.indexOf(value) === 0,
        filterSearch: true
    }
])