import utc  from 'dayjs/plugin/utc';
import timezone  from 'dayjs/plugin/timezone';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const FORMAT_TIME = "DD/MM/YYYY HH:mm:ss";

export const getTimestampInCorrectMilliSeconds = (timestamp) => {
    return parseInt(`${timestamp}`.replace(".", "").slice(0, 13).padEnd(13, 0), 10);
}
export const addSeconds = (currentTimestamp, seconds) => {
    const time = new Date(currentTimestamp);
    time.setSeconds(time.getSeconds() + seconds);
    return time
}


export const isValidTime = (time) => dayjs(time, 'HH:mm:ss', true).isValid();
export const getTime = (time) => dayjs(time);
export const getMillisecondsUnixTimestamp = (time) => getTime(time).valueOf();
export const returnUtcTime = (time) => dayjs.utc(time);
export const returnCorrectFormatDate = (date, format_date = FORMAT_TIME) => dayjs(date).format(format_date);

const validTime = (time) => {
    if (!time) return null

    if (!isNaN(time)) {
        let unixTime = Number(time);
        if (Number.isInteger(unixTime)) {
            unixTime = unixTime / 1000
        }

        return dayjs.unix(unixTime).format();
    }

    if (dayjs.utc(time).isUTC()) return time;

    // if(!dayjs.utc(time).isUTC()) return dayjs.utc(time);
    return time
}

export const returnCorrectTimeZoneTime = (time, time_zone, format_time = FORMAT_TIME) => {
    if (!dayjs(validTime(time)).isValid()) return null

    const returnCorrectTime = validTime(time);
    if (time_zone === 'GMT') return dayjs.utc(returnCorrectTime).format(format_time);
    return dayjs.utc(returnCorrectTime).local().format(format_time);
}

