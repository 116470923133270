import React from "react";
import {useSelector} from "react-redux";
import {Button, Carousel} from "antd";
import {ARROW_LEFT, ARROW_RIGHT, UPLOAD} from "../ANTD/Icons";
import {selectIdCamera} from "../../reduxToolkit/selectors/Api/formAddGate";
import {selectCctvFrames, selectUploadedFrame} from "../../reduxToolkit/selectors/Api/frames";
import {getStorageValue} from "../../utils/CacheHelper";
import InputFile from "../MyCustomComponents/InputFile";
import useUploadFrameHandler from "../../hooks/useUploadFrameHandler";
import PreviewImgBtnActions from "./StepThreeNew/PreviewImgBtnActions";
import {imageFileAccepts} from "../../utils/helpers/variables/accepts";
import SimpleRequestImgFallbackWrapper from "../Customs/SimpleRequestImgFallbackWrapper";

const PreviewImg = ({handleClickShowImg, handleNextStep, backToSetUpCameraInfo}) => {
    const frames = useSelector(selectCctvFrames);
    const uploadedFrameObj = useSelector(selectUploadedFrame)
    const cctv_id = useSelector(selectIdCamera);
    const cameraStorage = getStorageValue('camera', {}, 'session');
    const onUploadImgFile = useUploadFrameHandler(cctv_id);


    const isReadyToLoading = !frames[cctv_id]?.isImgLoading && !uploadedFrameObj?.isUploadedLoading
    const isReadyToShowError = frames[cctv_id]?.imgError && uploadedFrameObj.uploadedError


    const frameList = [frames[cctv_id]?.imgLink, uploadedFrameObj?.uploadedFrame];

    const handleBack = () => {
        backToSetUpCameraInfo()
        sessionStorage.setItem('edit_setting', JSON.stringify({id_camera: cctv_id, isEdit: true}))
    }

    return (
        <>
            <header>
                <h1>Frame Settings </h1>
            </header>
                <section className={"wrapperImage"}>
                    <SimpleRequestImgFallbackWrapper
                            loading={!isReadyToLoading}
                            error={isReadyToShowError}
                            loadingClasses={"preview_simple_img_loading_wrapper"}
                            errorClasses={"preview_simple_img_error_wrapper"}
                            colorLoading={"blue"}
                        >
                        <Carousel adaptiveHeight dots={frameList.every(frame => frame)}>
                            {frameList.map(frame => {
                                if (frame) {
                                    return <img src={frame} alt="IMAGE" className={'smallImgCamera'} key={frame}/>
                                }
                            })}
                        </Carousel>

                    </SimpleRequestImgFallbackWrapper>
                    {isReadyToLoading && <InputFile icon={UPLOAD} onChange={onUploadImgFile} accept={imageFileAccepts}/>}
                </section>
            <PreviewImgBtnActions
                cameraPosition={cameraStorage?.position}
                handleClickShowImg={handleClickShowImg}
                isDisabled={!isReadyToLoading}
                isLoading={!isReadyToLoading}
            />
            <footer className={'wrapperFinishButtons'}>
                <Button
                    icon={ARROW_LEFT}
                    type={'primary'}
                    onClick={handleBack}
                    size={'large'}
                >Back</Button>
                <Button
                    icon={ARROW_RIGHT}
                    type={'primary'}
                    size={'large'}
                    onClick={handleNextStep}
                >Next</Button>
            </footer>
        </>
    )
};

export default PreviewImg;