import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../utils/axiosBody";
import {returnUtcTime} from "../../../utils/TimeHelper";

export const getS3Files = createAsyncThunk('flightsFiles/getS3Files',
    async (file_name = {yml: '', gateName: ''}) => {
    try {
        const {data} = await air_axios_settings.get(`s3/content?file_name=${file_name['yml']}`);
        return {
            ...data,
            Arrival: {...data['Arrival'],
                STA: returnUtcTime(new Date()).format('HH:mm:ss')},
            General: {...data['General'], Parking: file_name['gateName']}
        }
    } catch (error) {
        throw new Error(error)
    }
})

export const getYmlFiles = createAsyncThunk('flightsFiles/getYmlFiles',
    async (gate_id) => {
    try {
        const {data} = await air_axios_settings.get(`s3/gate_flights_yml?gate_id=${gate_id}`);
        return data
    } catch (error) {
        throw new Error(error)

    }
})