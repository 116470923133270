import React from 'react';
import {Card} from "antd";
import Skeleton from "react-loading-skeleton";

const AdminSkeletonForm = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <section style={{display: 'flex', justifyContent: 'space-around'}}>
                <Card title={<Skeleton height={10} width={`150px`}/>} style={{width: '450px'}}>
                    <section>
                        <Skeleton height={20} width={`80%`}/>
                        <Skeleton height={20} width={`80%`} style={{marginTop: '20px'}}/>
                    </section>
                </Card>
                <Card title={<Skeleton height={10} width={`150px`}/>} style={{width: '450px'}}>
                    <section>
                        <Skeleton height={20} width={`80%`}/>
                    </section>
                </Card>
            </section>
           <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
               <Skeleton height={35} width={`150px`} style={{marginRight: '10px'}}/>
               <Skeleton height={35} width={`150px`}/>
           </div>
        </div>

    );
};

export default AdminSkeletonForm;