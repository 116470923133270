import {createSlice} from "@reduxjs/toolkit";
import {
    connectionToArchiveTurnSession
} from "../../asyncThunkFunctions/Weboscket/ArchiveTurnRequests";

const initialState = {
    connection_archive_session_obj: {
        connection_message: '',
        isConnected: false
    },
    isDisabled: false,
}

const saveTurnaroundSlice = createSlice({
    name: 'ws_archive_turn',
    initialState,
    reducers: {
        onChangeIsDisabled(state, {payload}) {
            state.isDisabled = payload
        },

        returnToInitSaveTurnState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(connectionToArchiveTurnSession.pending, (state) => {
            state.connection_archive_session_obj.connection_message = 'connection'
            state.connection_archive_session_obj.isConnected = false
        })
            .addCase(connectionToArchiveTurnSession.fulfilled, (state) => {
                state.connection_archive_session_obj.connection_message = 'connected'
                state.connection_archive_session_obj.isConnected = true
            })
            .addCase(connectionToArchiveTurnSession.rejected, (state) => {
                state.connection_archive_session_obj.connection_message = 'connection failed'
                state.connection_archive_session_obj.isConnected = false
            })
    }
})


const {reducer, actions} = saveTurnaroundSlice
export const {
    returnToInitSaveTurnState,
    onChangeIsDisabled
} = actions

export default reducer