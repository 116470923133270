import React from 'react';
import Calibration from "../../Canvas/DrawingWindows/Calibration";
import ClearanceZone from "../../Canvas/DrawingWindows/ClearanceZone";
import MarkDoor from "../../Canvas/DrawingWindows/MarkDoor";
import Landmarks from "../../DrawOnMaps/Landmarks";

const PreviewBIgImg = ({isWhatDraw, ...restProps}) => {
    switch (isWhatDraw) {
        case 'clearance zone':
            return <ClearanceZone {...restProps}/>
        case 'intrinsic':
            return <Calibration {...restProps}/>
        case 'extrinsic':
            return <Landmarks {...restProps}/>
        case 'mark door':
            return <MarkDoor {...restProps}/>
        default:
            return null
    }
};

export default PreviewBIgImg;