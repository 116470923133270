import {createSlice} from "@reduxjs/toolkit";
import {
    getAllGates,
    editGateNameRequest,
    deleteGateRequest, getListOfGateNames, getListOfCctvID, getKnownGateNames,
} from "../../asyncThunkFunctions/Api/GateRequest";
import {getCameraPosition} from "../../asyncThunkFunctions/Api/CamerasRequests";

const initialState = {
    gateNamesObj: {
        gatesName: [],
        isGateNamesLoading: false,
        gateNamesError: null
    },

    cctvIdsObj: {
        cctvIds: [],
        isCctvIDsLoading: false,
        cctvIdsError: null
    },
    gates: [],
    camerasNumber: [],


    total: 0,
    position: '',
    uniqueItemsLoading: false,
    loading: false,
    error: null,
    pending: false,
    isUpdateGateData: false,


    knownGateNameObj: {
        knownLists_id: [],
        isLoadingKnown_id: false,
        errorKnown_id: null
    }



}

export const sliceGetGate = createSlice({
    name: 'gates',
    initialState,
    reducers: {
        removeGate(state, action) {
            state.gates = state.gates.filter(({_id}) => _id !== action.payload)
        },

        editGateName(state, action) {
            const {_id, row} = action.payload, array = [...state.gates];
            const index = array.findIndex(elem => elem['_id'] === _id);

            if (index > -1) array.splice(index, 1, {...array[index], ...row})
            state.gates = array
        },

        removeCamera(state, action) {
            const array = [...state.gates];
            const indexArray = array.findIndex(({_id}) => _id === action.payload?._id);
            const indexCamera = array[indexArray]?.cameras?.findIndex(({cctv_id}) => cctv_id === action.payload?.cctv_id);

            if (indexCamera > -1) state.gates[indexArray]?.cameras.splice(indexCamera, 1)
        },

        editModeCamera(state, {payload}) {
            const array = [...state.gates]

            const indexGate = array.findIndex(({_id}) => _id === payload['_id']);
            const indexCamera = array[indexGate].cameras.findIndex(({cctv_id}) => cctv_id === payload['cctv_id']);

            array[indexGate].cameras[indexCamera] = {
                ...array[indexGate]?.cameras[indexCamera],
                mode: payload.mode
            }
        },

        handleOnChangeIsUpdateDataStatus(state, {payload}) {
            state['isUpdateGateData'] = payload
        },

        resetPartOfGateState(state, {payload}) {
            state[payload] = initialState[payload]
        },
        resetGateState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getAllGates.pending, (state) => {
            state.loading = true
            state.error = null
        })
            .addCase(getAllGates.fulfilled, (state, {payload}) => {
                state.gates = payload['data']
                state.total = payload['total']
                state.loading = false
            })
            .addCase(getAllGates.rejected, (state, {error}) => {
                state.loading = false
                state.error = error.message
            })
        builder.addCase(editGateNameRequest.pending, (state) => {
            state.pending = true
            state.error = null
        })
            .addCase(editGateNameRequest.fulfilled, (state, {payload}) => {
                state.pending = false
            })
            .addCase(editGateNameRequest.rejected, (state, {error}) => {
                state.pending = false
                state.error = error.message
            })

        builder.addCase(deleteGateRequest.pending, (state) => {
            state.pending = true
            state.error = null
        })
            .addCase(deleteGateRequest.fulfilled, (state, {payload}) => {
                state.pending = false
            })
            .addCase(deleteGateRequest.rejected, (state, {error}) => {
                state.pending = false
                state.error = error.message
            })
        builder.addCase(getCameraPosition.pending, (state) => {
            state.pending = true
            state.error = null
        })
            .addCase(getCameraPosition.fulfilled, (state, {payload}) => {
                state.position = payload
                state.pending = false
            })
            .addCase(getCameraPosition.rejected, (state, {error}) => {
                state.pending = false
                state.error = error.message
            })

        builder.addCase(getListOfGateNames.pending, (state) => {
            state.gateNamesObj.isGateNamesLoading = true
            state.gateNamesObj.gateNamesError = null
        })
            .addCase(getListOfGateNames.fulfilled, (state, {payload}) => {
                state.gateNamesObj.gatesName = payload
                state.gateNamesObj.isGateNamesLoading = false
            })
            .addCase(getListOfGateNames.rejected, (state, {error}) => {
                state.gateNamesObj.gateNamesError = error.message
                state.gateNamesObj.isGateNamesLoading = false
            })

        builder.addCase(getListOfCctvID.pending, (state) => {
            state.cctvIdsObj.isCctvIDsLoading = true
            state.cctvIdsObj.cctvIdsError = null
        })
            .addCase(getListOfCctvID.fulfilled, (state, {payload}) => {
                state.cctvIdsObj.cctvIds = payload
                state.cctvIdsObj.isCctvIDsLoading = false
            })
            .addCase(getListOfCctvID.rejected, (state, {error}) => {
                state.cctvIdsObj.isCctvIDsLoading = false
                state.cctvIdsObj.cctvIdsError = error.message
            })


        builder.addCase(getKnownGateNames.pending, state => {
            state.knownGateNameObj.isLoadingKnown_id = true
            state.knownGateNameObj.errorKnown_id = null
        })
            .addCase(getKnownGateNames.fulfilled, (state, {payload}) => {
                state.knownGateNameObj.knownLists_id = payload
                state.knownGateNameObj.isLoadingKnown_id = false
            })
            .addCase(getKnownGateNames.rejected, (state, {error}) => {
                state.knownGateNameObj.errorKnown_id = error.message
                state.knownGateNameObj.isLoadingKnown_id = false

            })
    }
})

export default sliceGetGate.reducer

export const {
    removeGate,
    editGateName,
    removeCamera,
    editModeCamera,
    resetGateState,
    onVisibleFrame,
    handleOnChangeIsUpdateDataStatus,


    resetPartOfGateState
} = sliceGetGate.actions