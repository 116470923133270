import {useEffect, useMemo} from "react";
import usePagination from "../../hooks/usePagination";
import {Table} from "antd";
import {paginationSettings} from "../../utils/Table/tableSettings";
import {scrollByY} from "../../utils/Table/Scroll";
import {PERF_INFO_BUSY_SPACE} from "../../utils/Sizes";
import {performanceInfoExpandableColumns} from "../../utils/Table/Columns/performance";

const PerformanceInfo = ({dataSource, columns, loading, total, handleGetPerfInfo}) => {
    const {page, limit, setSettings} = usePagination(1, 10);

    const refreshPerformanceData = (pageNumber, limitSize) => setSettings(pageNumber, limitSize);
    const onChange = async ({ current, pageSize }, filters, {order, field}) => {
        await refreshPerformanceData(current, pageSize);
    }

    const setPeckedges = useMemo(() => {
        return (obj) => {
            let list_of_packages = []
            for (let key in obj) {
                if (key.includes('ia-')) {
                    list_of_packages.push({
                        'package': key,
                        'version': obj[key]
                    })
                }
            }
            return list_of_packages
        }
    }, [dataSource])

    useEffect(() => {
        if (dataSource.length) {
            handleGetPerfInfo(page, limit)
        }
    }, [page, limit])

    return (
        <Table dataSource={dataSource} columns={columns}
               className={'performanceWrapper__performanceInfoTable'}
               loading={loading} onChange={onChange}
               pagination={{...paginationSettings(page, limit, total)}}
               scroll={{y: scrollByY(PERF_INFO_BUSY_SPACE)}}
               rowKey={({first_entry: {_id}}) => _id}
               expandable={{expandedRowRender: (record) => {
                       return <Table dataSource={setPeckedges(record)} columns={performanceInfoExpandableColumns()} className={'nestedTable'}/>
                   },
                   defaultExpandedRowKeys: ['0']
               }}
        />
    )
}

export default PerformanceInfo