import SimpleModal from "../../../components/ANTD/SimpleModal";
import EmptyField from "../../../components/Customs/EmptyField";
import FilterRangeDatePicker from "../../../components/Customs/FilterRangeDatePicker";
import TagItem from "../../../components/ANTD/TagItem";

export const logsColumns =
    ({
         transformatedList,
         onGateUsers,
         onGetFunctions,
         onGetIps,
         isOpenDatePicker,
         setIsOpenDatePicker,
         transformAuditLogDate
    }) => ([
    {
        key: 'datetime',
        title: 'Time',
        dataIndex: 'datetime',
        filters: transformatedList,
        onFilterDropdownOpenChange: (visible) => setIsOpenDatePicker(visible),
        // onFilter: (value, {datetime}) => value.isSame(dayjs(datetime)),
        filterDropdownOpen: isOpenDatePicker,
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
            return (
                <FilterRangeDatePicker
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    isOpenDatePicker={isOpenDatePicker}
                />
            )
        },
        render: (date) => {
            return (
                <EmptyField isEmpty={!date}>
                    <TagItem value={transformAuditLogDate(date)}/>
                </EmptyField>
            )
        }
    },{
        key: 'user',
        title: 'User',
        dataIndex: 'user',
        filters: transformatedList,
        onFilterDropdownOpenChange: (visible) => onGateUsers(visible),
        onFilter: (value, {user}) => user.indexOf(value) === 0,

    },{
        key: 'func',
        title: 'Function',
        dataIndex: 'function',
        filters: transformatedList,
        onFilterDropdownOpenChange: (visible) => onGetFunctions(visible),
        onFilter: (value, {function: func}) => func.indexOf(value) === 0,

    },{
        key: 'ip',
        title: 'Ip',
        dataIndex: 'ip',
        filters: transformatedList,
        onFilterDropdownOpenChange: (visible) => onGetIps(visible),
        onFilter: (value, {ip}) => ip.indexOf(value) === 0,

    },{
        key: 'context',
        title: 'Context',
        dataIndex: 'context',
        render: context => {
            return (
                <EmptyField isEmpty={!context} message={"N/A"}>
                    <SimpleModal primaryText={context?.target} modalText={context?.description}/>
                </EmptyField>
            )
        }
    },

])