import {useEffect, useMemo, useState} from 'react';
import {Table} from "antd";
import useTable from "../hooks/useTable";
import {returnCorrectTimeZoneTime} from "../utils/TimeHelper";
import {useSelector} from "react-redux";
import air_axios_settings from '../utils/axiosBody';
import {getUniqueValues, setFilterOptionSettings} from "../utils/helperFunctions";
import {alertColumns} from "../utils/Table/Columns/alert";
import {selectTimeZone} from "../reduxToolkit/selectors/Ui/TimeZone";

const Alerts = () => {
    const time = 360
    const [data, setData] = useState([]);
    const {getColumnSearchProps} = useTable();
    const timeZone = useSelector(selectTimeZone);

    const renderSwitchTimeZoneTime = (time) => returnCorrectTimeZoneTime(time, timeZone);


    const uniqueAlertValues = useMemo(() => {
        return (key) => setFilterOptionSettings(getUniqueValues(data, key))
    }, [data])


    const getAlertsData = async () => {
        try {
            const result = await air_axios_settings.get('system/alerts')
            setData(result.data)
        } catch (error) {
        }
    };

    useEffect(() => {
        getAlertsData();
        const timer = setInterval(getAlertsData, time * 1000);
        return () => clearInterval(timer);
    }, [time]);

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', filters, pagination);
    };

    return (
        <section className={'sectionNewTable'}>
            <div className={'containerNewTable'}>
                <Table
                    dataSource={data}
                    columns={alertColumns(uniqueAlertValues, getColumnSearchProps, renderSwitchTimeZoneTime)}
                    onChange={onChange} rowKey={({id}) => id}
                />
            </div>
        </section>
    );
};

export default Alerts;