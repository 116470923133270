import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    abortController: null,
    isLoadAbortController: false,
    errorAbortController: null,
}

const abortControllerSlice = createSlice({
    name: 'AbortController',
    initialState,
    reducers: {
        handleAddAbortController(state) {
            state.abortController = new AbortController()
            state.isLoadAbortController = true
        },

        handleAbortRequest(state) {
            state.abortController.abort("Loading too long");
            state.isLoadAbortController = false
        },
        returnDefAbortControllerState: () => initialState,
    }
})


export const {
    returnDefAbortControllerState,
    handleAddAbortController,
    handleAbortRequest,
} = abortControllerSlice.actions
export default abortControllerSlice.reducer