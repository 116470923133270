import {useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectSettingsUi} from "../../reduxToolkit/selectors/Api/system";
import {Checkbox, Tooltip} from "antd";
import {filterElements} from "../../utils/helperFunctions";
import {position_layers} from "../../utils/settingCanvas/settingCanvas";
import {extraCanvasLayerKeys, extraViewerCanvasLayerKeys} from "../../utils/helperArrays";
import {selectProfile} from "../../reduxToolkit/selectors/Api/auth";
import {VIEWER} from "../../utils/RouteSettings/Roles";
const DetectionCheckboxes = ({handleOnChangeCheckBox, camera_position}) => {
    const {CANVAS_LAYERS} = useSelector(selectSettingsUi);
    const {role} = useSelector(selectProfile);

    const filteredCheckBoxesByRole = useMemo(() => {
        const condition = (canvas_layer) => {
            return role === VIEWER ? extraViewerCanvasLayerKeys.includes(canvas_layer) : canvas_layer
        }
        return filterElements(Object.keys(CANVAS_LAYERS), condition)
    }, [role, CANVAS_LAYERS]);


    const checkBoxes = useMemo(() => {
        const condition = (canvas_layer) => {

            return !extraCanvasLayerKeys.includes(canvas_layer) &&
                camera_position && position_layers[camera_position].includes(canvas_layer);
        }
        return filterElements(filteredCheckBoxesByRole, condition);
    }, [camera_position, filteredCheckBoxesByRole]);


    return (
        <div className={'canvasFrameCheckBoxes'}>
            {
                checkBoxes.map((checkbox, index) => {
                    return (
                        <Tooltip
                            key={`${checkbox} -> ${index}`}
                            title={!CANVAS_LAYERS[checkbox] && `${checkbox} was disabled in configs`}
                            placement={'bottomRight'}
                        >
                            <Checkbox
                                disabled={!CANVAS_LAYERS[checkbox]}
                                key={checkbox}
                                onChange={({target: {checked}}) => handleOnChangeCheckBox({[checkbox]: checked})}>
                                {`${checkbox[0]}${checkbox.slice(1, checkbox.length).toLowerCase()}`}
                            </Checkbox>
                        </Tooltip>
                    )
                })
            }
        </div>
    )
};

export default DetectionCheckboxes;