import {useEffect, useRef} from 'react';
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";

const ZoomImg = ({zoom_settings, trackingZoomScale, isReadyZoomToDef, children}) => {
    const transformWrapperRef = useRef(null);

    useEffect(() => {
        if (transformWrapperRef.current && transformWrapperRef?.current?.instance?.transformState?.scale > 1 && isReadyZoomToDef) {
            console.log('resetTransform');
            transformWrapperRef.current.resetTransform();
            trackingZoomScale && trackingZoomScale({state: {scale: 1}})
        }
    }, [transformWrapperRef, isReadyZoomToDef]);

    return (
        <TransformWrapper {...zoom_settings} onWheel={trackingZoomScale} ref={transformWrapperRef}>
            <TransformComponent>
                {children}
            </TransformComponent>
        </TransformWrapper>
    );
};

export default ZoomImg;