import React from "react";
import {Button, Tooltip} from "antd";
import {PLUS_CIRCLE} from "../../../components/ANTD/Icons";
import SystemEventActions from "../../../components/System/EventTools/SystemEventActions";
import EmptyField from "../../../components/Customs/EmptyField";
import {expectedEventFilterToolsOption} from "../../helperArrays";
import EventPosition from "../../../components/System/EventTools/EventPosition";

const defaultPotentialEventColumns = ({processes, getColumnSearchProps, isShowFilter}) => ([
    {
        title: "№",
        dataIndex: '№',
        width: '50px',
        render: (array, value, index) => <span>{index + 1}</span>
    },
    {
        title: 'Processes',
        dataIndex: 'process',
        key: 'process',
        filters: processes,
        filterSearch: false,
        onFilter: (value, record) => record['process'].indexOf(value) === 0,
    },
    {
        title: 'Events',
        dataIndex: 'event_name',
        key: 'event_name',
        ...getColumnSearchProps('event_name', isShowFilter),
    },
])

export const potentialEventColumns = ({processes, getColumnSearchProps, isEditing, edit, editingKey, cancel, save, camera, gate}) => ([
    ...defaultPotentialEventColumns({processes, getColumnSearchProps}),
    {
        title: 'Positions visibility',
        dataIndex: 'visibility',
        key: 'positions',
        editable: true,
        className: "potential_editable_btn_container",
        render: (visibility, event) => {
            return (
                <EmptyField isEmpty={!event?.positions.length} message={'N/A'} tagColor={"#006d75"}>
                    <EventPosition
                        isEdited={isEditing(event?.uuid)}
                        save={save}
                        cancel={cancel}
                        event={event}
                        editingKey={editingKey}
                        edit={edit}
                        camera={camera}
                        gate={gate}
                    />
                </EmptyField>
            )
        },
    },
    {
        title: 'Expected',
        dataIndex: 'Expected',
        key: 'Expected',
        filters: expectedEventFilterToolsOption,
        filterSearch: false,
        onFilter: (value, {required}) => required === value,
        render: (_, {visibility, uuid, camera, required, ...event}) => {
            return <SystemEventActions isActive={required} event={event} gate={gate} camera={camera}/>
        }
    },
])

export const potentialEventTurnColumns = ({handleGetPairedEvent, processes, getColumnSearchProps, isShowFilter, isStillTurnInRedis}) => ([
    ...defaultPotentialEventColumns({processes, getColumnSearchProps, isShowFilter}),
    {
        title: 'Paired Event',
        dataIndex: 'paired_event',
        key: 'paired_event',
        render: (paired_events) => {
            return (
                <EmptyField isEmpty={!paired_events || !paired_events.length} message={"N/A"} tagColor={"#006d75"}>
                    {paired_events?.map(event => <p key={event}>{event}</p>)}
                </EmptyField>
            )
        }
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: (_, {event_name: event, process}) => {
            return (
                <Tooltip placement={'bottom'} title={isStillTurnInRedis && "disabled while turn is active"}>
                    <Button disabled={isStillTurnInRedis} onClick={() => handleGetPairedEvent({process, event})}
                            style={{padding: '5px', fontSize: '1rem', lineHeight: '1rem'}}>{PLUS_CIRCLE}</Button>
                </Tooltip>
            )
        }
    }
])