import React from 'react';
import {Popconfirm, Tooltip, Button, ColorPicker} from "antd";
import InputFile from "../../MyCustomComponents/InputFile";

const DrawButtons = ({add, remove, reset, close, save, info, upload, colorPicker, isChanged}) => {
    return (
        <>
            {add &&
                <Tooltip placement={'top'} title={add.title}>
                    <Button className={add.classes} disabled={add.disabled} icon={add.icon} onClick={add.onClick}>{add.name}</Button>
                </Tooltip>
            }
            {remove &&
                <Tooltip placement={'top'} title={remove.title}>
                    <Button
                        disabled={remove.disabled}
                        icon={remove.icon}
                        onClick={remove.onClick}
                        loading={remove.loading}
                    >{remove.name}
                    </Button>
                </Tooltip>
            }
            {reset &&
               <Popconfirm
                   title={reset.title}
                   onConfirm={reset.onConfirm}
                   onCancel={reset.onCancel}
                   okText={reset.okText}
                   cancelText={reset.cancelText}
                   disabled={reset.disabled}
               >
                   <Button icon={reset.icon} disabled={reset.disabled}>{reset.name}</Button>
               </Popconfirm>
            }

            {isChanged && save &&
                <Popconfirm
                    title={save.title}
                    onConfirm={save.onConfirm}
                    onCancel={save.onCancel}
                    okText={save.okText}
                    cancelText={save.cancelText}
                    disabled={save.disabled}
                >
                    <Button icon={save.icon}>{save.name}</Button>
                </Popconfirm>
            }
            {!isChanged && close &&
                <Button onClick={close.onClick} icon={close.icon} disabled={close.disabled}>{close.name}</Button>
            }
            {upload && <InputFile {...upload}/>}
            {colorPicker && <ColorPicker value={colorPicker.value} onChangeComplete={colorPicker.onChangeComplete}/>}
            {info && <Button icon={info.icon} onClick={info.onClick} className={'informButton'}>{info.name}</Button>}
        </>
    )
}

export default DrawButtons;
