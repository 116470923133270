import {Button, Popconfirm} from "antd";
import {TRASH} from "../../../components/ANTD/Icons";
import LandmarksEditTableButtons from "../../../components/DrawOnMaps/LandmarksEditTableButtons";

export const landmarks =
    ({
         prevElem,
         handleMarkedElem,
         handleRefuseElem,
         handleApproveChanges,
         handleFilteredUnequalItems,
         isDisableBtn,
         isDisableActionTrashBtn
     })  => [
    {
        title: '№',
        dataIndex: '№',
        render: (text, record, index) => index + 1
    },
    {
        title: 'Pixel',
        dataIndex: 'Pixel',
        className: "ant-table-cell-pixel",
        render: (_, {x, y, mouseX, mouseY, lat, lng}, index) => {
            const isDisableEditBtn = isDisableBtn || (!lat && !lng);
            const isReadyToRemove = prevElem.prev?.x && prevElem.prev?.y && prevElem.index === index;

            return x && y &&
                <LandmarksEditTableButtons
                    coordinateOne={mouseX}
                    coordinateTwo={mouseY}
                    onMarkedElem={() => handleMarkedElem({index, prev: {x, y}}, "editLeft")}
                    onRefuseElem={handleRefuseElem}
                    isReadyToRemove={isReadyToRemove}
                    isDisabledEditBtn={isDisableEditBtn}
                    onApproveChanges={handleApproveChanges}

                />
        }
    },
    {
        title: 'Coordinate',
        dataIndex: 'Coordinate',
        className: "ant-table-cell-coordinate",
        render: (_, {lat, lng, x, y}, index) => {
            const isDisableEditBtn = isDisableBtn || (!x && !y);
            const isReadyToRemove = prevElem.prev?.lat && prevElem.prev.lng && prevElem.index === index;

            return lat && lng &&
                <LandmarksEditTableButtons
                    coordinateOne={lat}
                    coordinateTwo={lng}
                    onMarkedElem={() => handleMarkedElem({index, prev: {lng, lat}}, "editRight")}
                    onRefuseElem={handleRefuseElem}
                    isReadyToRemove={isReadyToRemove}
                    isDisabledEditBtn={isDisableEditBtn}
                    onApproveChanges={handleApproveChanges}
                />
        }
    },
    {
        title: 'Actions',
        dataIndex: 'Actions',
        className: "ant-table-cell-actions",
        render: (_, calibration, index) => {
            const isDisableTrashBtn = !isDisableActionTrashBtn(index);
            return (
                <Popconfirm
                    title={"Are you sure ?"}
                    onConfirm={() => handleFilteredUnequalItems(calibration)}
                    disabled={isDisableTrashBtn}
                    cancelText={'No'}
                    okText={'Yes'}
                >
                    <Button icon={TRASH} disabled={isDisableTrashBtn} className={'remove-landmark-btn-row'}/>
                </Popconfirm>
            )
        }
    }
]