import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectCctvIds, selectGateNames} from "../../../reduxToolkit/selectors/Api/gates";
import {setOptionsSettings} from "../../../utils/helperFunctions";
import {Select} from "antd";

const EventToolsHeader = ({gate, camera, onSelectGate, onSelectCctvId}) => {
    const {gatesName, isGateNamesLoading} = useSelector(selectGateNames);
    const {cctvIds, isCctvIDsLoading} = useSelector(selectCctvIds);

    const modifiedGateNameList = useMemo(() => setOptionsSettings(["default", ...gatesName]),[gatesName]);
    const modifiedCctvIdList = useMemo(() => setOptionsSettings(["default", ...cctvIds]),[cctvIds]);

    return (
        <header className={"potential_event_container__header"}>
            <Select
                placeholder={'Select gate name'}
                value={gate}
                onChange={onSelectGate}
                loading={isGateNamesLoading}
                options={modifiedGateNameList}
            />
            <Select
                placeholder={'Select cctv_id'}
                value={camera}
                onChange={onSelectCctvId}
                loading={isCctvIDsLoading}
                options={modifiedCctvIdList}
                disabled={!cctvIds.length}
            />
        </header>
    )
}

export default EventToolsHeader;