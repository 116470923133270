import React from 'react';
import {Button} from "antd";
import {CLOSE} from "../ANTD/Icons";
import SimpleRequestImgFallbackWrapper from "../Customs/SimpleRequestImgFallbackWrapper";
import ZoomImg from "../Customs/ZoomImg";
import {ZOOM_SETTINGS} from "../../utils/settingCanvas/settingCanvas";
import {listPreviewCameraInfKeys} from "../../utils/helpers/arrays/configuration";
import useReadyState from "../../hooks/useReadyState";
import CustomCarousel from "../Customs/CustomCarousel";


const Camera = ({camera, frames, onClose, loading, error, isReadyToRender}) => {
    const {state: slideNumber, onChangeReadyState: setSlideNumber} = useReadyState(0);

    const carouselProps = {
        adaptiveHeight: true,
        arrows: true,
        effect: "fade",
        initialSlide: slideNumber,
        infinite: false,
        dots: frames.every(item => item),
        data: frames,
        beforeChange: (_, oldSlide) => setSlideNumber(oldSlide),
        nextArrow: slideNumber === 0 && <span>Calibration Result</span>,
        prevArrow: slideNumber === 1 && <span>Main Frame</span>,
        renderItem: (product, index) => (
            <ZoomImg zoom_settings={ZOOM_SETTINGS} isReadyZoomToDef={slideNumber !== null && slideNumber !== index}>
                <img src={product} alt={product}/>
            </ZoomImg>
        )
    }
    if (!isReadyToRender) return null

    return (
        <div className={"configuration-camera-info-container"}>
            <header className={"configuration-camera-info-container__camera-title"}>
                <p>Cctv_Id: <span>{camera?.cctv_id}</span></p>
                <Button icon={CLOSE} onClick={onClose}/>
            </header>
            <section className={"configuration-camera-info-container__zoomable-carousel-frame-container"}>
                <SimpleRequestImgFallbackWrapper
                    loading={loading}
                    error={error}
                    colorLoading={"black"}
                    loadingClasses={"zoomable-loading-container"}
                >
                    <CustomCarousel {...carouselProps} />
                </SimpleRequestImgFallbackWrapper>
            </section>
            <footer className={"configuration-camera-info-container__camera-description"}>
                {Object.entries(camera).map(([cameraKey, cameraValue]) => {
                    return listPreviewCameraInfKeys.includes(cameraKey) && <p>{cameraKey}: <span>{cameraValue}</span></p>
                })}
            </footer>
        </div>

    )
};

export default Camera;