export const correctEventPositionsFields = ['Process', 'State', 'Position', 'Camera', 'Timestamp', 'Offset', 'Frame_Id', 'Track_Id', 'Bbox']
export const extraCanvasLayerKeys = ['EVENT_BBOX', 'IMAGE'];
export const extraViewerCanvasLayerKeys = ['DETECTIONS', 'CLEARANCE_ZONE'];
export const colorHelperMainEventTable = [
    {color: '#ffccc7', text: 'Manual'},
    {color: '#cde9ff', text: 'Auto'}
]
export const colorHelperPotentialEventTable = [
    {color: '#ffccc7', text: 'Miss paired event'},
    {color: '#ffffb8', text: 'Required'}
]

export const typesOcclusionsOption = [
    {value: "Fully visible", label: "Fully visible"},
    {value: "Partially occluded", label: "Partially occluded"},
    {value: "Fully occluded", label: "Fully occluded"}
]

export const defaultEventEditFormState = [
    {value: 'time', label: 'time', isShowSelect: false, isShowDatePicker: true, isShowOcclusion: true},
    {value: 'wrong detections', label: 'wrong detections', isShowSelect: true, isShowDatePicker: true, hideLabelSelect: 'Detections', isShowOcclusion: true},
    {value: 'no detection', label: 'no detection', isShowSelect: true, isShowDatePicker: true, hideLabelSelect: 'Detections', isShowOcclusion: true},
    {value: 'location', label: 'location', isShowSelect: true, hideLabelSelect: 'Positions', isShowOcclusion: true},
    {value: "event didn't happen", label: "event didn't happen", isShowSelect: false, isShowOcclusion: true},
    {value: 'other', label: 'other', isShowSelect: false, isShowLabel: false, isShowOcclusion: false},
]

export const visibleEventFilterToolsOption = [
    {text: 'visible', value: 'viewer'},
    {text: 'not visible', value: 'validator'}
]
export const expectedEventFilterToolsOption= [
    {text: 'expected', value: true},
    {text: 'not expected', value: false}
]

export const userStatuses = [
    {text: 'active', value: 'active'},
    {text: 'inactive', value: 'inactive'}
]
export const userRoles = [
    {text: 'admin', value: 'admin'},
    {text: 'viewer', value: 'viewer'},
    {text: 'developer', value: 'developer'},
    {text: 'validator', value: 'validator'}
]