import {useState, useMemo} from "react";
import useResizeObserver from "use-resize-observer/polyfilled";
import {throttle} from "lodash";

export default wait => {
    const [size, setSize] = useState({width: 0, height: 0, dpi_width: 0, dpi_height: 0});
    const onResize = useMemo(() => throttle(({width, height}) => {
        if (!width || !height) return
        setSize({width, height, dpi_width: width * 2, dpi_height: height * 2})
    }, wait), [wait]);
    const { ref } = useResizeObserver({ onResize });
    return { ref, ...size };
};
