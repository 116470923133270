import {useEffect, useMemo} from 'react';
import {Form, Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    getPotentialEvents,
    getProcessesName
} from "../../../reduxToolkit/asyncThunkFunctions/Api/EventsRequest";
import {potentialEventColumns} from "../../../utils/Table/Columns/potentialEvent";
import {scrollByY} from "../../../utils/Table/Scroll";
import {BUSY_SPACE_IN_PAGE_JUST_WITH_TABLE} from "../../../utils/Sizes";
import {addUuidFiled, setFilterOptionSettings} from "../../../utils/helperFunctions";
import useTable from "../../../hooks/useTable";
import {returnSomeInitialState, setPotentialEventPaginationTools} from "../../../reduxToolkit/slices/Api/Events";
import {selectPotentialEvents, selectProcessNames} from "../../../reduxToolkit/selectors/Api/events";
import {getListOfCctvID, getListOfGateNames} from "../../../reduxToolkit/asyncThunkFunctions/Api/GateRequest";
import {selectCctvIds} from "../../../reduxToolkit/selectors/Api/gates";
import EditableSelectCell from "../../ANTD/EditableSelectCell";
import {paginationSettings} from "../../../utils/Table/tableSettings";
import useReadyState from "../../../hooks/useReadyState";
import {resetGateState, resetPartOfGateState} from "../../../reduxToolkit/slices/Api/sliceGateRequest";
import EventToolsHeader from "./EventToolsHeader";

const EventsTools = () => {
    const dispatch = useDispatch();
    const {mergedColumns, form, defaultEditPropsConfig, getColumnSearchProps, cancel, editingKey} = useTable();

    const {cctvIds} = useSelector(selectCctvIds);
    const {
        potentialEvents, isPotentialEvents, potentialTotal,
        potentialPage: page, potentialLimit: limit, potentialFilterObj: filter
    } = useSelector(selectPotentialEvents);

    const {processes_name} = useSelector(selectProcessNames);

    const {state: camera, onChangeReadyState: setCamera} = useReadyState("default");
    const {state: gate, onChangeReadyState: setGate} = useReadyState("default");

    const processes = useMemo(() => setFilterOptionSettings(processes_name), [processes_name])

    const setColumns = () => {
        const props = {...defaultEditPropsConfig, processes, camera, gate, getColumnSearchProps}

        return mergedColumns(props, potentialEventColumns, "uuid")
            .map(({key, ...rest}) => ({...rest, key, isFiltered: key === "Expected" && camera !== "default"}))
            .filter(row => !row.isFiltered)
    }

    const events = useMemo(() => addUuidFiled(potentialEvents), [potentialEvents]);

    const onSelectGate = (gate) => {
        if (editingKey !== "") cancel()
        setGate(gate)
        if (camera !== "default") setCamera("default");
        if (gate !== "default") dispatch(getListOfCctvID({_id: gate}));
        if (gate === "default" && cctvIds.length > 0) dispatch(resetPartOfGateState('cctvIdsObj'));
    }

    const onSelectCctvId = (cctv_id) => {
        if (editingKey !== "") cancel()
        setCamera(cctv_id)
    }

    const onChange = ({current: page, pageSize: limit}, {process, event_name}) => {
        dispatch(setPotentialEventPaginationTools({page, limit, filter: {process, event_name}}))
    }

    useEffect(() => {
        dispatch(getPotentialEvents({page, limit, gate, camera, ...filter}))
    },[limit, page, filter?.process, filter?.event_name, gate, camera, dispatch])

    useEffect(() => {
        dispatch(getListOfGateNames());
        dispatch(getProcessesName());
        return () => {
            dispatch(returnSomeInitialState('potentialEventsObj'))
            dispatch(resetGateState())
        }
    }, []);

    //fix unmount warning that getting after close edit visibility switch component

    return (
        <div className={"potential_event_container"}>
            <EventToolsHeader gate={gate} camera={camera} onSelectGate={onSelectGate} onSelectCctvId={onSelectCctvId}/>
            <Form form={form} component={false} className={"potential_event_container__table_form"}>
                <Table
                    dataSource={events}
                    loading={isPotentialEvents}
                    columns={setColumns()}
                    rowClassName="editable-row"
                    components={{body: {cell: EditableSelectCell}}}
                    scroll={{y: scrollByY(BUSY_SPACE_IN_PAGE_JUST_WITH_TABLE)}}
                    rowKey={({uuid}) => uuid}
                    onChange={onChange}
                    pagination={{...paginationSettings(page, limit, potentialTotal), onChange: cancel}}
                    className={"potential_event_table"}
                />
            </Form>
        </div>
    );
};

export default EventsTools;