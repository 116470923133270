import {createSlice} from "@reduxjs/toolkit";
import {
    getEvents,
    getFidsFrames,
    getCameraFramesTimes,
    getPageFids,
    getInference,
    getTurnaround, updatedFids, getStatuses
} from "../../asyncThunkFunctions/Api/TurnaroundRequests";
import {addUuidFiled} from "../../../utils/helperFunctions";
import {getLive} from "../../asyncThunkFunctions/Api/ImageRequests";

const initialState = {
    isFetching: false,
    turnaround: {},
    fids: [],
    total: 0,
    framesState: {
        frames: [],
        frameLoading: false,
        frameError: null
    },
    timestamps: [],
    events: [],
    inference: {},
    statuses: [],

    error: null,

    isStatuses: false,

    isTurnInfo: false,
    isEvents: false,


    loading: false,
    updateFidsLoading: false,

    isStartRefresh: false
}

const turnaroundSlice = createSlice({
    name: 'turnaround',
    initialState,
    reducers: {

        changeRefreshToggle(state, {payload}) {
            state['isStartRefresh'] = payload
        },

        toggleIsFetching(state, {payload}) {
            state['isFetching'] = payload
        },

        addNewEvent(state, {payload}) {
            state['events'].unshift(payload)
        },
        initialTurnInfoState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getTurnaround.pending, (state) => {
            state.loading = true
            state.isTurnInfo = true
            state.error = null
        })
            .addCase(getTurnaround.fulfilled, (state, {payload}) => {
                state.turnaround = payload
                state.isTurnInfo = false
                state.loading = false
            })
            .addCase(getTurnaround.rejected, (state, {error}) => {
                state.loading = false
                state.isTurnInfo = false
                state.error = error.message
            })

        builder.addCase(getPageFids.pending, (state) => {
            state.loading = true
            state.error = null
        })
            .addCase(getPageFids.fulfilled, (state, {payload}) => {
                state.loading = false
                state.fids = payload['fids']
                state.total = payload['total']
            })
            .addCase(getPageFids.rejected, (state, {error}) => {
                state.loading = false
                state.error = error.message
            })
        // TEST UPDATE
        builder.addCase(updatedFids.pending, (state) => {
            state['updateFidsLoading'] = true
            state['error'] = null
        })
            .addCase(updatedFids.fulfilled, (state, {payload, meta}) => {
                state['fids'] = payload.fids
                state['updateFidsLoading'] = false
            })
            .addCase(updatedFids.rejected, (state, {error}) => {
                state['error'] = error['message']
                state['updateFidsLoading'] = false
            })
        //TEST UPDATE

        builder.addCase(getFidsFrames.pending, (state) => {
            state.framesState.frameLoading = true
            state.framesState.frameError = null
        })
            .addCase(getFidsFrames.fulfilled, (state, {payload}) => {
                state.framesState.frames = payload
                state.framesState.frameLoading = false
            })
            .addCase(getFidsFrames.rejected, (state, {error}) => {
                state.framesState.frameLoading = false
                state.framesState.frameError = error.message
            })

        builder.addCase(getCameraFramesTimes.pending, (state) => {
            state.loading = true
            state.error = null
        })
            .addCase(getCameraFramesTimes.fulfilled, (state, {payload}) => {
                state.timestamps = payload
                state.loading = false
            })
            .addCase(getCameraFramesTimes.rejected, (state, {error}) => {
                state.loading = false
                state.error = error.message
            })

        builder.addCase(getEvents.pending, (state) => {
            state.loading = true
            state.isEvents = true
            state.error = null
        })
            .addCase(getEvents.fulfilled, (state, {payload}) => {
                state.events = addUuidFiled(payload)
                state.isEvents = false
                state.loading = false
            })
            .addCase(getEvents.rejected, (state, {error}) => {
                state.loading = false
                state.isEvents = false
                state.error = error.message
            })

        builder.addCase(getInference.pending, (state) => {
            state.loading = true
            state.error = null
        })
            .addCase(getInference.fulfilled, (state, {payload}) => {
                state.inference = payload
                state.loading = false
            })
            .addCase(getInference.rejected, (state, {error}) => {
                state.loading = false
                state.error = error.message
            })
        builder.addCase(getStatuses.pending, (state) => {
            state.isStatuses = true
            state.error = null
        })
            .addCase(getStatuses.fulfilled, (state, {payload}) => {
                state.statuses = payload
                state.isStatuses = false
            })
            .addCase(getStatuses.rejected, (state, {error}) => {
                state.isStatuses = false
                state.error = error.message
            })


        // test
        builder.addCase(getLive.pending, (state) => {
            state.loading = true
            state.error = null
        })
            .addCase(getLive.fulfilled, (state, {payload}) => {
                state.inference = payload
                state.loading = false
            })
            .addCase(getLive.rejected, (state, {error}) => {
                state.loading = false
                state.error = error.message
            })

    }
})

export default turnaroundSlice.reducer
export const {initialTurnInfoState, toggleIsFetching, addNewEvent, changeRefreshToggle} = turnaroundSlice.actions