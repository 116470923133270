import React from 'react';
import {Button, Popconfirm, Tooltip} from "antd";

const SwitchArchiveBtnComponent = ({handler, tooltip_description, isArchived, isDisabled, archived_btn_text, removed_btn_text, pop_confirm_title}) => {
    if (isArchived) {
        return (
            <Popconfirm title={pop_confirm_title} okText={"Yes"} cancelText={"No"} onConfirm={handler}>
                <Tooltip title={tooltip_description} placement={'bottom'}>
                    <Button disabled={isDisabled} style={{marginLeft: '10px'}}>{removed_btn_text}</Button>
                </Tooltip>
            </Popconfirm>
        )
    }

    return (
        <Tooltip title={tooltip_description} placement={'bottom'}>
            <Button disabled={isDisabled} style={{marginLeft: '10px'}} onClick={handler}>{archived_btn_text}</Button>
        </Tooltip>
    )
}

export default SwitchArchiveBtnComponent;