import React from 'react';
import {BIG_LOADING} from "../ANTD/Icons";
import {Spin} from "antd";

const BigLoading = () => {
    return (
        <div className={'big_loading'}>
            <Spin indicator={BIG_LOADING} />
        </div>
    );
};

export default BigLoading;