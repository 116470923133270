
export const defaultSignInState = {_id: '', password: ''}

export const defaultUserState = {_id: '', role: '',}

export const defaultCameraState = {cctv_id: '', url: '', view: 'left-view', position: 'ramp', mode: '', top_left: '', bottom_right: ''}

export const defDownloadFileState = {gate_id: null, cctv_id: null, start: null, end: null}

export const initialEventFormState = {
    reject_reason: '',
    correct_detection: '',
    correct_location: '',
    comment: '',
    occlusion: '',

    process: '',
    event: '',
    positions: '',
    offset: '00:00',
}