import React from 'react';
import { useSelector } from 'react-redux';
import MarkTimeTeg from "../../ANTD/MarkTimeTeg";
import {returnCorrectTimeZoneTime} from "../../../utils/TimeHelper";
import {selectTimeZone} from "../../../reduxToolkit/selectors/Ui/TimeZone";

const ArrivalInfo = ({arrival_airport, arrival_flight_number, outgoing_gate, sta_utc, ata_utc, eta_utc}) => {
    const timeZone = useSelector(selectTimeZone);

    return (
        <div className={'wrapperTurnInfo'}>
            <h2 white-space="pre-wrap">Arrival</h2>
            <div className={'turnInformation'}>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>airport: </span>
                    <span>{arrival_airport}</span>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>flight number: </span>
                    <span>{arrival_flight_number}</span>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>outgoing gate: </span>
                    <span>{outgoing_gate}</span>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>sta: </span>
                    <MarkTimeTeg time={returnCorrectTimeZoneTime(sta_utc, timeZone)} empty_text={'NA'}/>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>eta: </span>
                    <MarkTimeTeg time={returnCorrectTimeZoneTime(eta_utc, timeZone)} mark={true} empty_text={'NA'}/>
                </span>
                <span className="tab">
                    <span className={'headTextTurnInfo'}>ata: </span>
                    <MarkTimeTeg time={returnCorrectTimeZoneTime(ata_utc, timeZone)} mark={true} empty_text={'NA'}/>
                </span>
            </div>

        </div>
    );
};

export default ArrivalInfo;