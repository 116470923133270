import React from 'react';
import {Carousel} from "antd";

const CustomCarousel = ({data, renderItem, ...carouselProps}) => {
    if (Array.isArray(data)) {
        return (
            <Carousel {...carouselProps}>
                {data.map((item, index) => item && renderItem(item, index))}
            </Carousel>
        )
    }
    return renderItem(data)
};

export default CustomCarousel;