export const updateFrameState = (state, cctv_id, payload = null, loading = false, error = null, async_status = 'pending') => {
    const cctvId_frame = state.cctv_frames[cctv_id]
    if (async_status === 'pending') {
        if (!cctvId_frame) {
            state.cctv_frames[cctv_id] = {isImgLoading: loading , imgError: error}
        } else {
            state.cctv_frames[cctv_id].isImgLoading = loading
            state.cctv_frames[cctv_id].imgError = error
        }
    }

    if (async_status === 'fulfilled') { // fix after add abort cantroller
        if (!cctvId_frame) {
            state.cctv_frames[cctv_id] = {isImgLoading: loading , imgLink: payload}
        } else {
            state.cctv_frames[cctv_id].isImgLoading = loading
            state.cctv_frames[cctv_id].imgLink = payload
        }
    }

    if (async_status === 'rejected') {
        if (cctvId_frame) {
            state.cctv_frames[cctv_id].isImgLoading = loading
            state.cctv_frames[cctv_id].imgError = error
            state.cctv_frames[cctv_id].imgLink = payload
        }
    }
}