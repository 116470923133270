import React from 'react';
import {Button} from "antd";

const JumpFrameHelpers = (
    {
        isMoreThenLast, isSmallerThenFirst,
        onNext, onPrev, onMinusSecond, onPlusSecond,
        icons: {ARROW_LEFT, ARROW_RIGHT}, value, currentFrame, totalInferences

    }) => {

    return (
        <div className={'blockFrameButtons'}>
            <Button disabled={isSmallerThenFirst} onClick={onPrev} type={'primary'} icon={ARROW_LEFT}>Prev</Button>
            <div className={'blockFrameButtons__jumpBySecondBlock'}>
                <Button type={'primary'} disabled={isSmallerThenFirst || !value} onClick={onMinusSecond}>-{value} sec</Button>
                    <p className={'textWhichFrame'}>Frame {currentFrame} of {totalInferences}</p>
                <Button type={'primary'} disabled={isMoreThenLast || !value} onClick={onPlusSecond}>+{value} sec</Button>
            </div>
            <Button disabled={isMoreThenLast} onClick={onNext} type={'primary'} icon={ARROW_RIGHT}>Next</Button>
        </div>

    )
}

export default JumpFrameHelpers;