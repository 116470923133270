import {createSlice} from "@reduxjs/toolkit";
import {createUser, editUser, getUser, getUsers, removeUser} from "../../asyncThunkFunctions/Api/UserRequests";

const initialState = {
    user_obj: {
        user: {},
        userLoading: false,
        userError: null
    },
    users_obj: {
        users: [],
        usersLoading: false,
        usersError: null
    }
}

export const userSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        clearUserState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.pending, (state) => {
            state.user_obj.userLoading = true
            state.user_obj.userError = null
        })
                .addCase(getUser.fulfilled, (state, {payload}) => {
                    state.user_obj.user = payload
                    state.user_obj.userLoading = false
        })
                .addCase(getUser.rejected, (state, {error}) => {
                    state.user_obj.userLoading = false
                    state.user_obj.userError = error.message
        })

        builder.addCase(getUsers.pending, (state) => {
            state.users_obj.usersLoading = true
            state.users_obj.usersError = null
        })
            .addCase(getUsers.fulfilled, (state, {payload}) => {
                state.users_obj.users = payload
                state.users_obj.usersLoading = false
        })
            .addCase(getUsers.rejected, (state, {error}) => {
                state.users_obj.usersLoading = false
                state.users_obj.usersError = error.message
        })

        builder.addCase(createUser.pending, (state) => {
            state.user_obj.userLoading = true
            state.user_obj.userError = null
        })
            .addCase(createUser.fulfilled, (state, {payload}) => {
                state.user_obj.userLoading = true
            })
            .addCase(createUser.rejected, (state, {error}) => {
                state.user_obj.userLoading = false
                state.user_obj.userError = error.message
            })

        builder.addCase(editUser.pending, (state) => {
            state.user_obj.userLoading = true
            state.user_obj.userError = null
        })
            .addCase(editUser.fulfilled, (state, {payload}) => {
                state.user_obj.userLoading = false
            })
            .addCase(editUser.rejected, (state, {error}) => {
                state.user_obj.userLoading = false
                state.user_obj.userError = error.message
            })

        builder.addCase(removeUser.pending, (state) => {
            state.user_obj.userLoading = true
            state.user_obj.userError = null
        })
            .addCase(removeUser.fulfilled, (state, {payload}) => {
                state.user_obj.userLoading = false
            })
            .addCase(removeUser.rejected, (state, {error}) => {
                state.user_obj.userLoading = false
                state.user_obj.userError = error.message
            })
    }
})


export default userSlice.reducer

export const {clearUserState} = userSlice.actions