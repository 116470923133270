const percentWidth = window.innerWidth * 0.05
const percentHeight = window.innerHeight * 0.10

export const WIDTH = window.innerWidth - percentWidth;
export const HEIGHT = window.innerHeight - percentHeight;
export const DPI_WIDTH = WIDTH * 2
export const DPI_HEIGHT = HEIGHT * 2

export const ZOOM_SETTINGS = {
    initialScale: 1, initialPositionX: 0,
    initialPositionY: 0, doubleClick: {disabled: true},
    minScale: 1,
    maxScale: 10,
}
export const object_color = {
    "aircraft": "rgb(0, 0, 255)",
    "structure": "rgb(255, 0, 0)",
    "vehicle": "rgb(0, 255, 0)",
    "person": "rgb(255, 0, 255)",
    "object": "rgb(225, 225, 0)",
    "default": "rgb(255, 128, 0)"
}

export const projection_colors = {
    "aircraft": "#4096ff",
    "structure": "#ff4d4f",
    "vehicle": "#52c41a",
    "person": "#eb2f96",
    "object": "#fffb8f",
    "default": "#d48806"
}


export const canvas_layers = {
    IMAGE: 0,
    KEYPOINTS: 1,
    DETECTIONS: 2,
    CLEARANCE_ZONE: 3,
    PERSONS: 4,
    TRACKERS: 5,
    OBSERVED_AREA: 6,
    BBOX: 7,
}

export const canvas_layersTest = {
    IMAGE: 'IMAGE',
    KEYPOINTS: 'KEYPOINTS',
    DETECTIONS: 'DETECTIONS',
    CLEARANCE_ZONE: 'CLEARANCE_ZONE',
    VESTS: 'VESTS',
    TRACKERS: 'TRACKERS',
    PROJECTION: 'PROJECTION',
    EVENT_BBOX: 'EVENT_BBOX',
}

export const position_layers = {
    "ramp": ['KEYPOINTS', 'DETECTIONS', 'TRACKERS', 'CLEARANCE_ZONE', 'VESTS', 'PROJECTION'],
    "bridge": ['DETECTIONS', 'TRACKERS', 'VESTS']
}


export const changeCanvasLayersFieldPositions = (canvas_layers) => {
    const {
        IMAGE, KEYPOINTS, DETECTIONS, TRACKERS, CLEARANCE_ZONE, VESTS, PROJECTION, EVENT_BBOX
    } = canvas_layers

    return {IMAGE, KEYPOINTS, DETECTIONS, TRACKERS, CLEARANCE_ZONE, VESTS, PROJECTION, EVENT_BBOX}
}