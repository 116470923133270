const defMapOptions = {
    panControl: true,
    zoomControl: true,
    keyboardShortcuts: false,
    fullscreenControl: true,
    streetViewControl: false,
    clickableIcons: false,
    disableDoubleClickZoom: true,
    minZoom: 3
}

export default defMapOptions