import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.sass';
import Intell_Act from './Intell_Act';
import {setUpStore} from "./reduxToolkit/store/setUpStore";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import AxiosInterceptor from "./hoc/AxiosInterceptor";

const store = setUpStore();


ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AxiosInterceptor>
                <Intell_Act/>
            </AxiosInterceptor>
            <ToastContainer position={'bottom-right'} autoClose={3000}/>
        </Router>
    </Provider>,
    document.getElementById('root')
);