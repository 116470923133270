import React from 'react';
import {useNavigate} from "react-router-dom";
import {ARROW_UP, HOME} from "../ANTD/Icons";

const NotFound = ({errorMessage}) => {
    const navigate = useNavigate();

    const handleClickHome = () => navigate('/liveView')

    return (
        <div className={'wrapper_notFound'}>
            <h1 className={'notFoundText'}>{errorMessage}</h1>
            <button onClick={handleClickHome} className={'buttonHome'}>{HOME}</button>
            <h2>Click on Home for the back {ARROW_UP}</h2>
        </div>
    );
};

export default NotFound;