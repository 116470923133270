import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../utils/axiosBody";
import {toast} from 'react-toastify';

export const getAllGates = createAsyncThunk('gates/getGate',
    async (params= {page: 1, limit: 5}) => {
        try {
            const {data} = await air_axios_settings.get('/gates/data', {params})
            return data
        } catch (error) {
            throw Error(error)
        }
    })

export const editGateNameRequest = createAsyncThunk('gates/editGateName',
    async (params = {old: '', new: ''}) => {
    try {
        const {data} = await air_axios_settings.put(`/gates/rename`, {}, {params})
        return data
    } catch (error) {
        toast.error(error.message)
        throw new Error(error)
    }
})

export const deleteGateRequest = createAsyncThunk('gates/removeGate',
    async (_id, {dispatch}) => {
    try {
        const {data} = await air_axios_settings.delete(`/gates/remove`, {params: {_id}})
        return data
    } catch (error) {
        throw new Error(error)
    }
})

export const getListOfGateNames = createAsyncThunk('gates/getGateNames',
    async () => {
    try {
        const {data} = await air_axios_settings.get('/gates/names')
        return data
    } catch (error) {
        throw new Error(error)
    }
})

export const getListOfCctvID = createAsyncThunk('gates/getCamerasId',
    async (params) => {
    try {
        const {data} = await air_axios_settings.get(`/gates/cameras`, {params})
        return data
    } catch (error) {
        throw new Error(error)
    }
})


export const getKnownGateNames = createAsyncThunk('gates/getKnownGateNames',
    async () => {
    try {
        const {data} = await air_axios_settings('/gates/known_gates')
        return data
    } catch (error) {
        throw new Error(error)
    }
})

