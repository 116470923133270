import React from 'react';

const EmptyComponent = ({text, emptyClassComponent}) => {
    return (
        <section className={`wrapperItemBodyGate ${emptyClassComponent}`}>
            <h1>{text}</h1>
        </section>
    );
};

export default EmptyComponent;