import {useCallback, useState} from "react";

export default function (defaultScale) {
    const [scale, setScale] = useState(defaultScale || 1);

    const onTrackingScale = useCallback((event) => {
        const scale = event.state.scale;
        if (scale < 1) setScale(1);
        else setScale(scale);
    }, []);

    return [scale, onTrackingScale]
}