import {createSlice} from "@reduxjs/toolkit";
import {connectionToAlerts} from "../../asyncThunkFunctions/Weboscket/AlertRequests";

const initialState = {
    connection_to_alert_ws: {
        connection_message: '',
        isConnected: false
    },
    alert_amount: 0,
    alerts: [],
}


const AlertSlice = createSlice({
    name: 'ws_alert',
    initialState,
    reducers: {
        incrementAlerts(state) {
            state.alert_amount += 1
        }
    },
    extraReducers: (builder) => {
        builder.addCase(connectionToAlerts.pending, (state) => {
            state.connection_to_alert_ws.connection_message = 'connection'
            state.connection_to_alert_ws.isConnected = false
        })
            .addCase(connectionToAlerts.fulfilled, (state) => {
                state.connection_to_alert_ws.connection_message = 'connected'
                state.connection_to_alert_ws.isConnected = true
            })
            .addCase(connectionToAlerts.rejected, (state) => {
                state.connection_to_alert_ws.connection_message = 'connection failed'
                state.connection_to_alert_ws.isConnected = false
            })
    }
})


const {reducer, actions} = AlertSlice

export const {incrementAlerts} = actions
export default reducer
