export const drawCross = ({context, strokeStyle, lineWidth, crossSize, x, y, opacity}) => {
    context.globalAlpha = opacity || 1
    context.beginPath();
    context.moveTo(x - crossSize, y - crossSize);
    context.lineTo(x + crossSize, y + crossSize);
    context.moveTo(x + crossSize, y - crossSize);
    context.lineTo(x - crossSize, y + crossSize);
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth
    context.stroke();
};

export const drawText = ({context, fillStyle, font, fillText: {text, x, y}}) => {
    context.fillStyle = fillStyle;
    context.font = font;
    context.fillText(text, x, y);
}

////


export function DrawRectangle(ctx, x, y, w, h, lineWidth, color, text="", fill, broken_line) {
    ctx.beginPath();
    ctx.rect(x, y, w, h);
    ctx.fillStyle = color;
    if (fill) {
        ctx.fill();
    }
    if (broken_line) {
        ctx.setLineDash([20, 10])
    } else {
        ctx.setLineDash([0, 0])
    }
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.font = "30px serif";

    ctx.stroke();
    ctx.fillText(text, x+2, y+h-2);
    ctx.closePath();
}


export function DrawText(ctx, x, y, color, text ="") {
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.fillText(text, x+2, y-2);
    ctx.font = "30px serif";
    ctx.closePath();
}

export function  circleElem(ctx, centerX, centerY, radius, lineWidth, color, fill= false) {
    if (!ctx) return
    ctx.beginPath();
    ctx.arc(Math.round(centerX), Math.round(centerY), radius, 0, 2 * Math.PI, false);
    if (fill) {
        ctx.fillStyle = color;
        ctx.fill();
    }
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
}


export const drawLines = ({context, lineWidth, strokeStyle, startX, startY, endX, endY, moveTo, closePath, opacity}) => {
    context.globalAlpha = opacity || 1
    context.beginPath();
    context.lineWidth = lineWidth;
    context.strokeStyle = strokeStyle;
    context.moveTo(startX, startY);


    context.lineTo(endX, endY);
    context.stroke();
    if (closePath) {
        context.closePath()
    }
}

export const clearCanvasList = (context) => {
    if (context) return context.clearRect(0, 0, context.canvas.width, context.canvas.height);
}