class SocketClient {
    constructor() {
        this.socket = null
    }

    connection(url) {
        this.socket = new WebSocket(url);
        return new Promise((resolve, reject) => {
            this.socket.addEventListener('open', resolve);
            this.socket.addEventListener('message', resolve);
            this.socket.addEventListener('error', (error) => {
                console.log(error, 'ERROR')
                reject()
            });
            this.socket.addEventListener('close', (event) => {
                console.log(event, 'CLOSE handler')
            })
        })
    }

    sendMessage(message) {
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('Websocket closed')
            this.socket.send(JSON.stringify(message))
            return resolve
        })
    }

    getMessage(cb) {
        return new Promise((resolve, reject) => {
            if (!this.socket) return reject('Websocket closed');
            this.socket.addEventListener('message', cb)
            return resolve
        })
    }

    disconnect() {
        return new Promise((resolve) => {
            this.socket.close(1000);
            this.socket = null;
            resolve();
        });
    }
}

export default SocketClient