export const selectEventsSlice = state => state.api.Events;
export const selectAddEvent = state => selectEventsSlice(state).addEventObj;

export const selectEventScrollTable = state => selectEventsSlice(state).scrollEventTableObj;

export const selectProcessNames = state => selectEventsSlice(state).processesNameObj;

export const selectEventNames = state => selectEventsSlice(state).eventsNameObj;

export const selectPositionNames = state => selectEventsSlice(state).positionNamesObj;

export const selectPotentialEvents = state => selectEventsSlice(state).potentialEventsObj;

export const selectPotentialTurnEvents = state => selectEventsSlice(state).potentialTurnEventsObj;

