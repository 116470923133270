


export const LOGIN = '/login'
export const LIVE_VIEW = 'liveView'
export const FIDS = 'fids'
export const CONFIGURATION = 'configuration'
export const SYSTEM = 'system/*'
export const SYSTEM_DOWNLOAD = 'download'
export const SYSTEM_OTHER = 'other'
export const SYSTEM_EVENTS_TOOLS = 'event_tools'
export const MOCK = 'mock'
export const ALERTS = 'alerts'
export const FORM_GATE = 'formGate/*'
export const FORM_GATE_ADD_CAMERAS = 'gate/:name/add_cameras/*'
export const FORM_GATE_EDIT_CAMERA = 'gate/:name/edit_camera/:id/*'
export const LIVE_STREAM = 'live/:gate_id/:cctv_id/:turnaround_id'
export const TURNAROUND = 'turnaround/:turnaround_id/:gate_id/:cctv_id'
export const  ADMINISTRATION = 'administration/*'
export const USERS = 'users'
export const TOOLS = 'tools'
export const EDIT_USER = 'edit/:login'
export const NEW_USER = 'new'
export const ACCOUNT = 'account'
export const STEP_ONE = 'step_one'
export const STEP_TWO = 'step_two'
export const STEP_THREE = 'step_three'
export const PREVIEW = 'preview'
export const PERFORMANCE = 'performance/*'
export const  STAR = '/*'

export const PATHS = {
    SLASH: '/',
    LOGIN: 'login',
    MAIN: 'liveView',
    FIDS: 'fids',
    CONFIGURATION: 'configuration',
    SYSTEM: 'system',
    MOCK: 'mock',
    ALERTS: 'alerts',
    GATE_FORM: 'formGate/*',
    LIVE: 'live/:gate_id/:cctv_id/:turnaround_id',
    TURNAROUND: 'turnaround/:turnaround_id/:gate_id/:cctv_id',

    ADMINISTRATION: 'administration',

    USERS: 'users',
    TOOLS: 'tools',


    EDIT_USER: 'edit/:login',
    NEW_USER: 'new',
    ACCOUNT: 'account',
    STEP_ONE: 'step_one',
    STEP_TWO: 'step_two',
    STEP_THREE: 'step_three',
    FINISH: 'finish',
    PERFORMANCE: 'performance',
    STAR: '/*',
}