import {useEffect} from "react";
import LandmarksMap from "./LandmarksMap";
import {useLoadScript} from "@react-google-maps/api";
import useReadyState from "../../hooks/useReadyState";
import AutoComplete from "../AutoCompleteGoogleMaps/AutoComplete";
import CompositeLandmarksDrawAndTable from "./CompositeLandmarksDrawAndTable";
import {getStorageValue} from "../../utils/CacheHelper";

const Landmarks = ({hide, showHideImg}) => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyDmbQMh_w8EFkNJ92XuygDxSD5uLdnJB30', language: 'en', libraries: ['places']
    })

    const {state: landmarks, onChangeReadyState: setLandmarks} = useReadyState([]);
    const {state: mode, onChangeReadyState: setMode} = useReadyState(null);
    const {state: center, onChangeReadyState: setCenter} = useReadyState({lat: 39.5696005, lng: 2.6501603});
    const {state: prevElem, onChangeReadyState: setPrevElem} = useReadyState({index: null, prev: null});
    const {state: color, onChangeReadyState: setColor} = useReadyState("#1677ff");

    console.log('LANDMARKS');

    const cameraStorage = getStorageValue('camera', {}, 'session');

    const handleAddCenter = (position) => setCenter(position);

    const handleOnChangeLandmarkColorPicker = (c) => {
        const color = c.toHexString()
        setColor(color);
        setLandmarks(prevLandmarks => prevLandmarks.map(landmark => ({...landmark, color})))
    }

    const defaultProps = {
        landmarks,
        setLandmarks,
        mode,
        setMode,
        prevElem,

        color,
        handleOnChangeLandmarkColorPicker
    }

    useEffect(() => {
        if (showHideImg && cameraStorage && cameraStorage.calibration?.landmarks?.length) {
            const landmarks = cameraStorage.calibration?.landmarks
            setLandmarks(landmarks.map((landmark) => ({...landmark, color})))
        }
    }, [showHideImg]);


    return (
        <div className={'landmarks_wrapper'}>
            <CompositeLandmarksDrawAndTable
                {...defaultProps}
                onCloseModal={hide}
                calibrationStorage={cameraStorage.calibration}
                setPrevElem={setPrevElem}
            />
            <div className={"landmarks_wrapper__right-side"}>
                <AutoComplete isLoaded={isLoaded} handleAddCenter={handleAddCenter}/>
                {isLoaded ?
                    <LandmarksMap {...defaultProps} center={center}/>
                    :
                    <div className={"googleMaps-loading-wrapper"}>...loading</div>
                }
            </div>
        </div>
    );
};

export default Landmarks;