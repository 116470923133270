import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isOpenExpandableRowState: {
        _id: '',
        isOpen: false
    }
}

const resending = createSlice({
    name: 'resending',
    initialState,
    reducers: {
      setIsOpenExpandableRowItem(state, {payload}) {
          state.isOpenExpandableRowState = payload
      },
      returnResendingInitialState: () => initialState
    }
})

const {reducer, actions} = resending
export const {setIsOpenExpandableRowItem, returnResendingInitialState} = actions
export default reducer