
export function DrawRectangle(ctx, x, y, w, h, lineWidth, color, text="", fill=false, broken_line=false) {
    ctx.beginPath();
    ctx.rect(x, y, w, h);
    ctx.fillStyle = color;
    if (fill) {
      ctx.fill();
    }
    if (broken_line) {
      ctx.setLineDash([20, 10])
    }
    else {
      ctx.setLineDash([0, 0])
    }
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.font = "30px serif";

    ctx.stroke();
    ctx.fillText(text, x+2, y+h-2);
    ctx.closePath();
}

export function DrawText(ctx, x, y, color, text ="") {
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.fillStyle = color;
  ctx.font = "30px serif";
  ctx.fillText(text, x+2, y-2);
  ctx.closePath();
}

export function  DrawCircle(ctx, centerX, centerY, radius, lineWidth, color, fill= false) {
    if (!ctx) return
    ctx.beginPath();
    ctx.arc(Math.round(centerX), Math.round(centerY), radius, 0, 2 * Math.PI, false);
    if (fill) {
      ctx.fillStyle = color;
      ctx.fill();
    }
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
}

export function CleanCanvas(ctx) {
    ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
}