import StreamIcon from "../../../components/LiveView/StreamIcon";
import EmptyField from "../../../components/Customs/EmptyField";
import TagItem from "../../../components/ANTD/TagItem";

export const cameraColumns = (updateLastUpdateDate, turnaround_id, _id) => ([
    {
        key: 'cctv_id',
        title: 'cctv_id',
        dataIndex: 'cctv_id',
        render: (cctv_id) => <EmptyField isEmpty={!cctv_id} message={'NC'}>{cctv_id}</EmptyField>
    },
    {
        key: 'last_frame_date',
        title: 'last_frame_date',
        dataIndex: 'last_frame_date',
        render: (time) => <EmptyField isEmpty={!time} message={'ND'}>{updateLastUpdateDate(time)}</EmptyField>
    },
    {
        key: 'video',
        title: 'stream',
        dataIndex: 'video',
        render: (_, {cctv_id, mode}) => {
            return (
                <StreamIcon
                    cctv_id={cctv_id}
                    isActiveMode={mode === 'active'}
                    url_path={`/live/${_id}/${cctv_id}/${turnaround_id}`}
                    _id={_id}
                />
            )
        }
    },
    {
        key: 'mode',
        title: 'mode',
        dataIndex: 'mode',
        render: (mode) => {
            const isActiveMode = mode === 'active'
            return (
                <EmptyField isEmpty={!mode} message={'NM'}>
                    <TagItem color={isActiveMode ? 'green' : 'red'} mark value={mode}/>
                </EmptyField>
            )
        }
    }
])