export const styles = {
    filterDpd: {
        padding: 8
    },
    input: {
        marginBottom: 8,
        display: 'block'
    },
    button: {
        width: 90
    },
    HighLighter: {
        backgroundColor: '#ffc069',
        padding: 0
    }
}