import {createSlice} from "@reduxjs/toolkit";
import {
    addValidation,
    finishValidation, getDetections,
    getIsValidFields,
} from "../../asyncThunkFunctions/Api/ValidationRequests";

const initialState = {
    pairedEvent: {
        process: null,
        event: null,
        isFromPotentialEventTable: false,
    },
    isValidFields: {
        datetime: null,
        status: null
    },

    states: [],
    detections: [],

    isDetections: false,
    isValidationFinish: false,
    isValidatedEvent: false,
    isAddValidation: false,
    validationError: null
}

const Validation = createSlice({
    name: 'validation',
    initialState,
    reducers: {
        getPairedEvent(state, {payload}) {
            state['pairedEvent'] = payload
        },
        resetSomeValidationState(state, {payload}) {
            state[payload] = initialState[payload]
        },
        returnInitValidationState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(addValidation.pending, state => {
            state['isAddValidation'] = true
            state['validationError'] = null
        })
            .addCase(addValidation.fulfilled, (state, {payload}) => {
                state['isAddValidation'] = false
            })
            .addCase(addValidation.rejected, (state, {error}) => {
                state['validationError'] = error['message']
                state['isAddValidation'] = false
            })

        builder.addCase(getIsValidFields.pending, state => {
            state['isValidatedEvent'] = true
            state['validationError'] = null
        })
            .addCase(getIsValidFields.fulfilled, (state, {payload}) => {
                state['isValidFields'] = payload
                state['isValidatedEvent'] = false
            })
            .addCase(getIsValidFields.rejected, (state, {error}) => {
                state['validationError'] = error['message']
                state['isValidatedEvent'] = false
            })

        builder.addCase(finishValidation.pending, state => {
            state['isValidationFinish'] = true
            state['validationError'] = null
        })
            .addCase(finishValidation.fulfilled, (state, {payload}) => {
                state['isValidationFinish'] = false
            })
            .addCase(finishValidation.rejected, (state, {error}) => {
                state['validationError'] = error['message']
                state['isValidationFinish'] = false
            })

        builder.addCase(getDetections.pending, state => {
            state['isDetections'] = true
            state['validationError'] = null
        })
            .addCase(getDetections.fulfilled, (state, {payload}) => {
                state['detections'] = payload
                state['isDetections'] = false
            })
            .addCase(getDetections.rejected, (state, {error}) => {
                state['validationError'] = error['message']
                state['isDetections'] = false
            })
    }
})

export default Validation.reducer

export const {getPairedEvent, returnInitValidationState, resetSomeValidationState} = Validation.actions