import React from 'react';
import {Button, Descriptions, Empty} from "antd";
import {resortObjectFieldsFunction} from "../../utils/helperFunctions";
import {correctEventPositionsFields} from "../../utils/helperArrays";

const EventInformation = ({event, onClick, transformTime}) => {
    return (
        <Descriptions bordered size={'small'} layout="vertical">
            {!Object.keys(event).length && <Empty/>}
            {event && Object.keys(event).length &&
                resortObjectFieldsFunction(event, correctEventPositionsFields)?.map(([key, value]) => {
                if (!['validation', 'Confidence', 'Manual_Event', 'uuid'].includes(key)) {
                    return <Descriptions.Item label={key === 'State' ? 'Event' : key} key={key}>
                        {key === 'Timestamp' ? transformTime(value): value}
                    </Descriptions.Item>
                }})}
            <Descriptions.Item>
                <Button onClick={() => onClick(event)}>Back to Frame</Button>
            </Descriptions.Item>
        </Descriptions>
    )
};

export default EventInformation;