import {useCallback, useEffect, useMemo} from 'react';
import useThrottledResizeObserver from "../../../hooks/useThrottledResizeObserver";
import {useDispatch, useSelector} from "react-redux";
import {selectCctvFrames, selectUploadedFrame} from "../../../reduxToolkit/selectors/Api/frames";
import {selectIdCamera} from "../../../reduxToolkit/selectors/Api/formAddGate";
import useImg from "../../../hooks/useImg";
import DrawButtons from "../../FormAddGate/StepThreeNew/DrawButtons";
import {clearCanvasList, circleElem} from "../../../utils/helpers/functions/drawCanvasItems";
import {returnXY} from "../../../utils/helpers/functions/calcPositions";
import {getStorageValue} from "../../../utils/CacheHelper";
import {toggleStorage, updateFieldToGateCameraForm} from "../../../reduxToolkit/slices/Api/newGate";
import {isEqual} from "../../../utils/helpers/functions/analiza";
import {showModalInfoContent} from "../../../utils/helpers/functions/modalContent";
import useReactZoomPanPinch from "../../../hooks/useReactZoomPanPinch";
import {CIRCLE_CLOSE, INFO, UPLOAD} from "../../ANTD/Icons";
import {extrinsicDrawButtonsTools} from "../../../utils/helpers/objects/drawButtonsTools";
import useUploadFrameHandler from "../../../hooks/useUploadFrameHandler";
import {imageFileAccepts} from "../../../utils/helpers/variables/accepts";
import ZoomableCanvasFrameComponent from "../../Customs/ZoomableCanvasFrameComponent";
import {Carousel} from "antd";
import useReadyState from "../../../hooks/useReadyState";

const MarkDoor = ({hide, showHideImg}) => {
    const dispatch = useDispatch()
    const frames = useSelector(selectCctvFrames);
    const cctv_id = useSelector(selectIdCamera);
    const uploadedFrame = useSelector(selectUploadedFrame);


    const {img_width, img_height} = useImg(frames[cctv_id]?.imgLink);
    const {ref: ref_canvas_frame_block, width, height, dpi_width, dpi_height} = useThrottledResizeObserver(100);
    const {state: markDoor, onChangeReadyState: setMarkDoor} = useReadyState([]);
    const {state: carouselSlideNumber, onChangeReadyState: setCarouselSlideNumber} = useReadyState(null);
    const [scale, setScale] = useReactZoomPanPinch(1);
    const onUploadImgFile = useUploadFrameHandler(cctv_id);

    const cameraStorage = getStorageValue('camera', {}, 'session');
    const frameList = [frames[cctv_id].imgLink, uploadedFrame?.uploadedFrame];

    const handleGetMousePosition = ({clientX, clientY}, setCorrectHandlePosition) => {
        const {x, y} = setCorrectHandlePosition(clientX, clientY);

        const passengerPoint = [x / (width * scale), y / (height * scale)];
        setMarkDoor(passengerPoint)
    }

    const clearList = () => setMarkDoor([]);

    const handleCloseModal = () => {
        hide();
        clearList();
    }
    const handleSaveMarkDoor = () => {
        dispatch(updateFieldToGateCameraForm({key: "doors_point", value: markDoor}))
        dispatch(toggleStorage(true));
        handleCloseModal();
    }

    const isEditedMarkDoor = useMemo(() => {
        return isEqual(markDoor, cameraStorage.doors_point);
    }, [markDoor]);

    const drawPassengerPoint = useCallback((context) => {
        const {x: circle_x, y: circle_y} = returnXY(markDoor[0], markDoor[1], dpi_width, dpi_height);
        clearCanvasList(context)
        circleElem(context, circle_x, circle_y, 7, 6, 'red');
    }, [markDoor, dpi_width, dpi_height])

    const zoomableImgWrapperProps = {
        handleGetMousePosition,
        trackingZoomScale: setScale,
        dpi_width,
        dpi_height,
        drawCanvasFunction: drawPassengerPoint
    }

    const drawBtnProps = {
        isChanged: !isEditedMarkDoor,
        reset: {...extrinsicDrawButtonsTools.reset, onConfirm: clearList, disabled: !markDoor || !markDoor.length},
        close: {...extrinsicDrawButtonsTools.close, onClick: handleCloseModal},
        save: {...extrinsicDrawButtonsTools.save, onConfirm: handleSaveMarkDoor, onCancel: handleCloseModal, icon: CIRCLE_CLOSE},
        info: {
            icon: INFO, onClick: () => showModalInfoContent({
                width: '550px', title: 'Drawing instructions', infoContent: {}
            })
        },
        upload: {onChange: onUploadImgFile, icon: UPLOAD, accept: imageFileAccepts}
    }
    const imgSectionStyles = {maxWidth: `${img_width}px`, maxHeight: `${img_height}px`}

    useEffect(() => {
        if (showHideImg && cameraStorage.doors_point) {
            setMarkDoor(cameraStorage.doors_point)
        }
    }, [showHideImg]);

    return (
        <div className={'big_img_wrapper'}>
            <div className={'big_img_wrapper__img_section'} style={imgSectionStyles}>
                <div ref={ref_canvas_frame_block} className={'zoomable_img_wrapper'}>
                    <Carousel adaptiveHeight dots={frameList.every(frame => frame)} afterChange={setCarouselSlideNumber}>
                        {frameList.map((frame, index) => {
                            if (frame) {
                                return (
                                    <ZoomableCanvasFrameComponent
                                        {...zoomableImgWrapperProps}
                                        url={frame}
                                        key={frame}
                                        isReadyZoomToDef={carouselSlideNumber !== null && carouselSlideNumber !== index}
                                    />
                                )
                            }
                        })}
                    </Carousel>
                </div>
            </div>
            <footer className={"big_img_wrapper__draw_btn_section"}>
                <DrawButtons {...drawBtnProps} />
            </footer>
        </div>
    );
};

export default MarkDoor;