import {createSlice} from "@reduxjs/toolkit";
import {getS3Files, getYmlFiles} from "../../asyncThunkFunctions/Api/FlightsFilesRequests";
import {addTurnPost} from "../../asyncThunkFunctions/Api/MockRequests";


const initialState = {
    yml_file_obj: {
        ymlFiles: [],
        isYmlFilesLoading: false,
        flightsYmlFilesError: null,
    },
    s3_files_obj: {
        s3Files: {},
        isS3FilesLoading: false,
        flightsS3FilesError: null
    },
    isAddTurnPost: false,
}

export const FlightsFilesSlice = createSlice({
    name: 'flightsFiles',
    initialState,
    reducers: {
        getInitialState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getS3Files.pending, (state) => {
            state.s3_files_obj.isS3FilesLoading = true
            state.s3_files_obj.flightsS3FilesError = null
        })
            .addCase(getS3Files.fulfilled, (state, {payload}) => {
                state.s3_files_obj.s3Files = payload
                state.s3_files_obj.isS3FilesLoading = false
            })
            .addCase(getS3Files.rejected, (state, {error}) => {
                state.s3_files_obj.isS3FilesLoading = false
                state.s3_files_obj.flightsS3FilesError = error.message
            })

        builder.addCase(getYmlFiles.pending, (state) =>  {
            state.yml_file_obj.isYmlFilesLoading = true
            state.yml_file_obj.flightsYmlFilesError = null
        })
            .addCase(getYmlFiles.fulfilled, (state, {payload}) => {
                state.yml_file_obj.ymlFiles = payload
                state.yml_file_obj.isYmlFilesLoading = false
            })
            .addCase(getYmlFiles.rejected, (state, {error}) => {
                state.yml_file_obj.isYmlFilesLoading = false
                state.yml_file_obj.flightsYmlFilesError = error.message
            })

        builder.addCase(addTurnPost.pending, (state) => {
            state.isAddTurnPost = true
            state.s3_files_obj.flightsS3FilesError = null
        })
            .addCase(addTurnPost.fulfilled, (state, {payload}) => {
                state.s3_files_obj.s3Files = payload
                state.isAddTurnPost = false
            })
            .addCase(addTurnPost.rejected, (state, {error}) => {
                state.isAddTurnPost = false
                state.s3_files_obj.flightsS3FilesError = error.message
            })
    }
})


export const {getInitialState} = FlightsFilesSlice.actions

export default FlightsFilesSlice.reducer