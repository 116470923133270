import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    file: null,
    filesState: {},
}


const filesSlice = createSlice({
    name: "files",
    initialState,
    reducers: {
        setFile(state, {payload}) {
            state.file = payload
        },
        setInitialFileState: () => initialState
    }
})



export const {actions, reducer} = filesSlice

export const {setFile, setInitialFileState } = actions
export default reducer