import {
    ACCOUNT, ADMINISTRATION,
    ALERTS,
    CONFIGURATION,
    FIDS, FORM_GATE,
    LIVE_STREAM,
    LIVE_VIEW,
    MOCK,
    PERFORMANCE,
    SYSTEM,
    TURNAROUND
} from "./Paths";
import {Admin, All, WithoutViewerValidator} from "./Roles";
import Live from "../../pages/Live";
import Turnaround from "../../pages/Turnaround";
import Fids from "../../pages/Fids";
import Configuration from "../../pages/Configuration";
import Mock from "../../pages/Mock";
import Alerts from "../../pages/Alerts";
import WrapperFormGate from "../../components/FormAddGate/WrapperFormGate";
import Profile from "../../pages/Profile";
import Administration from "../../pages/Administration";
import LiveView from "../../pages/LiveView";
import System from "../../pages/System";
import Performance from "../../pages/Performance";

export const ROUTES = [
    {
        path: '/',
        component: <LiveView/>,
        roles: All,
    },
    {
        path: LIVE_VIEW,
        component: <LiveView/>,
        roles: All,
    },
    {
        path: FIDS,
        component: <Fids/>,
        roles: All
    },
    {
        path: LIVE_STREAM,
        component: <Live/>,
        roles: All
    },
    {
        path: TURNAROUND,
        component: <Turnaround/>,
        roles: All
    },
    {
        path: CONFIGURATION,
        component: <Configuration/>,
        roles: WithoutViewerValidator
    },
    {
        path: SYSTEM,
        component: <System/>,
        roles: WithoutViewerValidator
    },
    {
        path: MOCK,
        component: <Mock/>,
        roles: WithoutViewerValidator
    },
    {
        path: ALERTS,
        component: <Alerts/>,
        roles: WithoutViewerValidator
    },
    {
        path: PERFORMANCE,
        component: <Performance/>,
        roles: WithoutViewerValidator
    },
    {
        path: FORM_GATE,
        component: <WrapperFormGate/>,
        roles: WithoutViewerValidator
    },
    {
        path: ACCOUNT,
        component: <Profile/>,
        roles: All
    },
    {
        path: ADMINISTRATION,
        component: <Administration/>,
        roles: Admin
    }
]