import React from 'react';
import {useSelector} from "react-redux";
import {selectCctvFrames, selectUploadedFrame} from "../../../reduxToolkit/selectors/Api/frames";
import {selectIdCamera} from "../../../reduxToolkit/selectors/Api/formAddGate";
import ZoomableCanvasFrameComponent from "../../Customs/ZoomableCanvasFrameComponent";
import useReadyState from "../../../hooks/useReadyState";
import usePrintCrossesWithIndexText from "../../../hooks/usePrintCrossesWithIndexText";
import {Carousel} from "antd";

const LandmarkCrosses = ({landmarks, prevElem, handleAddAndUpdateCross, setScale, dpi_height, dpi_width}) => {
    const frames = useSelector(selectCctvFrames);
    const cctv_id = useSelector(selectIdCamera);
    const uploadedFrameObj = useSelector(selectUploadedFrame);

    const {state: carouselSlideNumber, onChangeReadyState: setCarouselSlideNumber} = useReadyState(null);

    const framesList = [frames[cctv_id]?.imgLink, uploadedFrameObj?.uploadedFrame];

    const onPrintTextMarkCrosses = usePrintCrossesWithIndexText({
        landmarks,
        dpi_width, dpi_height,
        isMarked: (index) => prevElem.index === index && prevElem.prev?.x && prevElem.prev?.y}
    )

    return (
        <>
            <Carousel adaptiveHeight dots={framesList.every(frame => frame)} afterChange={setCarouselSlideNumber}>
                {framesList.map((frame, index) => {
                    if (frame) {
                        return (
                            <ZoomableCanvasFrameComponent
                                key={frame}
                                handleGetMousePosition={handleAddAndUpdateCross}
                                dpi_width={dpi_width}
                                dpi_height={dpi_height}
                                url={frame}
                                trackingZoomScale={setScale}
                                drawCanvasFunction={onPrintTextMarkCrosses}
                                isReadyZoomToDef={carouselSlideNumber !== null && carouselSlideNumber !== index}
                            />
                        )
                    }
                })}
            </Carousel>

        </>

    );
};

export default LandmarkCrosses;