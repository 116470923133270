import {Admin, All, WithoutViewerValidator} from "./Roles";
import {
    ADMINISTRATION_LINKS,
    CONFIGURATION_LINKS,
    FIDS_LINKS,
    LIVE_VIEW_LINKS, MOCK_LINKS,
    PERFORMANCE_LINKS, SYSTEM_LINKS
} from "./Links";
import {Link} from "react-router-dom";
import {CaretDownOutlined} from "@ant-design/icons";

const {live_view} = LIVE_VIEW_LINKS
const {day, min_max, date_compare} = PERFORMANCE_LINKS
const {fids} = FIDS_LINKS
const {configuration} = CONFIGURATION_LINKS
const {download, event_table_tools, validation} = SYSTEM_LINKS
const {mock} = MOCK_LINKS
const {users, tools, audit} = ADMINISTRATION_LINKS
export const Navigations = [
    {
        label: <Link to={live_view}>Live View</Link>,
        key: live_view,
        roles: All,
    },
    {
        label: <Link to={fids}>Turnarounds</Link>,
        key: fids,
        roles: All,
    },
    {
        label: <Link to={configuration}>Configuration</Link>,
        key: configuration,
        roles: WithoutViewerValidator,
    },
    {
        label: 'System',
        key: 'system',
        roles: WithoutViewerValidator,
        icon: <CaretDownOutlined />,
        children: [
            {
                label: <Link to={download}>Download</Link>,
                key: download,
                roles: WithoutViewerValidator,
            },
            {
                label: <Link to={event_table_tools}>Event list tools</Link>,
                key: event_table_tools,
                roles: WithoutViewerValidator,
            },
            {
                label: <Link to={validation}>Validation</Link>,
                key: validation,
                roles: WithoutViewerValidator,
            },
        ]
    },
    {
        label: <Link to={mock}>Mock</Link>,
        key: mock,
        roles: WithoutViewerValidator,
    },
    {
        label: 'Performance',
        roles: WithoutViewerValidator,
        key: 'performance',
        icon: <CaretDownOutlined />,
        children: [
            {
                label: <Link to={day}>Day</Link>,
                key: day,
                className: 'day'
            },
            {
                label: <Link to={min_max}>MinMax</Link>,
                key: min_max,
                className: 'minMax'
            },
            {
                label: <Link to={date_compare}>DateCompare</Link>,
                key: date_compare,
                className: 'dateCompare'
            }
        ]
    },
    {
        label: 'Administration',
        roles: Admin,
        key: 'administration',
        icon: <CaretDownOutlined />,
        children: [
            {
                label: <Link to={users}>Users</Link>,
                key: users,
            },
            {
                label: <Link to={tools}>Tools</Link>,
                key: tools,
            },
            {
                label: <Link to={audit}>Audit</Link>,
                key: audit,
            }
        ]
    }
]