import React from 'react';

const ModalWrapper = ({modalClasses, closeHandler, children}) => {
    return (
        <section className={modalClasses} onClick={closeHandler}>
            {children}
        </section>
    )
}

export default ModalWrapper;