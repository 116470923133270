import React from 'react';

const SlideMark = ({time, frameNumber}) => {
    return (
        <div className={'slideMark'}>
            <span>{time}</span>
            <span>( {frameNumber} )</span>
        </div>
    );
};

export default SlideMark;