export const VIEWER = 'viewer';
export const VALIDATOR = 'validator';
export const DEVELOPER = 'developer';
export const ADMIN = 'admin';

export const Admin = [ADMIN];
export const All = [DEVELOPER, ADMIN, VIEWER, VALIDATOR];
export const WithoutValidator = [VIEWER, DEVELOPER, ADMIN];
export const WithoutViewerValidator = [DEVELOPER, ADMIN];
export const WithoutViewer = [ VALIDATOR, DEVELOPER, ADMIN];
