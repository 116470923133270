import {useCallback, useMemo, useState, useRef, useEffect} from 'react';
import {Collapse, theme, Table} from 'antd'
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {All, VIEWER, WithoutViewer} from "../../utils/RouteSettings/Roles";
import {eventColumns} from "../../utils/Table/Columns/event";
import FooterMainEventTable from "./FooterMainEventTable";
import SummaryTable from "../ANTD/SummaryTable";
import SummaryEventButtons from "./SummaryEventButtons";
import {isValidateString} from "../../utils/TurnaroundUtils";
import air_axios_settings from "../../utils/axiosBody";
import {useParams} from "react-router-dom";
import {finishValidation, getIsValidFields} from "../../reduxToolkit/asyncThunkFunctions/Api/ValidationRequests";
import {getEvents} from "../../reduxToolkit/asyncThunkFunctions/Api/TurnaroundRequests";
import {
    getEventsName,
    getTurnPotentialEvents,
    getProcessesName
} from "../../reduxToolkit/asyncThunkFunctions/Api/EventsRequest";
import {addUuidFiled, getUniqueValues, setFilterOptionSettings, sortByDate} from "../../utils/helperFunctions";
import ColorEventTableInstruction from "./ColorEventTableInstruction";
import {colorHelperPotentialEventTable} from "../../utils/helperArrays";
import {potentialEventTurnColumns} from "../../utils/Table/Columns/potentialEvent";
import useTable from "../../hooks/useTable";
import {returnCorrectTimeZoneTime} from "../../utils/TimeHelper";
import {getPairedEvent} from "../../reduxToolkit/slices/Api/Validation";
import {selectProfile} from "../../reduxToolkit/selectors/Api/auth";
import {selectTimeZone} from "../../reduxToolkit/selectors/Ui/TimeZone";
import {selectTurnaroundSlice} from "../../reduxToolkit/selectors/Api/turnaround";
import {selectEventScrollTable, selectPotentialTurnEvents} from "../../reduxToolkit/selectors/Api/events";
import {selectIsValidEvents} from "../../reduxToolkit/selectors/Api/validation";

import {saveAs} from 'file-saver';

const CollapseTurnTables = ({handleOpenAddNewEvent, handleOpenEditEventForm, jumpToEvent}) => {
    const {cctv_id} = useParams()
    const {token} = theme.useToken();
    const {turnaround_id} = useParams();
    const dispatch = useDispatch()
    const {role} = useSelector(selectProfile);
    const {events, isEvents, turnaround} = useSelector(selectTurnaroundSlice);
    const isValidFields = useSelector(selectIsValidEvents);
    const {turnPotentialEvents, isTurnPotentialEvents} = useSelector(selectPotentialTurnEvents);
    const timeZone = useSelector(selectTimeZone);
    const eventScrollTableSettings = useSelector(selectEventScrollTable);

    const {getColumnSearchProps} = useTable();

    const [collapse, setCollapse] = useState(['1']);

    const [tableFunctionality, settableFunctionality] = useState({});
    const table_row_ref = useRef(null);

    // test solution for the get event index (for the scrolling)
    const getEventIndex = useMemo(() => {
        const copyArrayEvents = [...events]
        const {Frame_Id, Process, State, Position} = eventScrollTableSettings
        const {camera, sorter} = tableFunctionality

        const filterEvents = camera?.length > 0 ? copyArrayEvents?.filter(({Camera}) => camera?.includes(Camera)) : copyArrayEvents
        const sortFilterEvents = filterEvents.sort((a, b) => {
            return sorter !== 'ascend' ? sortByDate(a['Timestamp'], b['Timestamp']) : -1
        })

        return sortFilterEvents.findIndex(event => {
            return Frame_Id === event['Frame_Id'] &&
                Process === event['Process'] &&
                State === event['State'] &&
                Position === event['Position']
        })
    }, [eventScrollTableSettings, events, tableFunctionality]);
    // test solution for the get event index (for the scrolling)

    const tableClasses = classNames('turnaroundTableCollapse', {
        'panel': collapse.length <= 1, 'panels': collapse.length >= 2
    })

    const mainEventsRowClass = useCallback((Manual_Event, index) => classNames(null, {
        'wrapper_row_manually_class': Manual_Event,
        'wrapper_row_not_manually_class': !Manual_Event,
        'shadedColumn': index === getEventIndex
    }), [events, cctv_id, getEventIndex])

    const potentialEventRowClass = useCallback((level) => {
        if (level === 'HIGH') return 'potential_red_row_event'
        if (level === 'MEDIUM') return 'potential_yellow_row_event'
    }, [turnPotentialEvents]);

    const handleGetPairedEvent = (paired_event) => {
        handleOpenAddNewEvent()
        dispatch(getPairedEvent({...paired_event, isFromPotentialEventTable: true}))
        dispatch(getProcessesName())
        dispatch(getEventsName(paired_event['process']))
    }
    const transformTime = (time) => returnCorrectTimeZoneTime(time, timeZone)
    const handleOnChangeCollapse = (collapses) => setCollapse(collapses);

    const camerasOption = useMemo(() => {
        const uniqueCameras = getUniqueValues(events, "Camera")
        return setFilterOptionSettings(uniqueCameras)
    }, [events])

    const filteredEventColumns = useMemo(() => {
        return eventColumns(
            handleOpenEditEventForm, getColumnSearchProps,
            camerasOption, jumpToEvent, transformTime
        ).filter(({roles}) => roles.includes(role))
    }, [role, events, timeZone])

    const isFinishValidation = useMemo(() => events.some(ev => !ev['validation']['finished']), [events]);

    const downloadEventFile = async () => {
        // if there is some reason to save in redux
        // dispatch(getEventFiles(turnaround_id))
        const config = {params: {turnaround_id, as_file: true},responseType: 'blob'}
        const {data} = await air_axios_settings.get('turnarounds/events', config);
        saveAs(data, `${turnaround_id}_events.csv`);
    }

    const handleFinishValidation = async () => {
        await dispatch(finishValidation(turnaround_id))
        await dispatch(getIsValidFields(turnaround_id))
        await dispatch(getEvents({turnaround_id, cctv_id}))
    }

    const handleShowPotentialEvents = () => {
        if (!turnPotentialEvents.length) {
            return dispatch(getTurnPotentialEvents(turnaround_id))
        }
    }

    const getNotFoundEvents = useMemo(() => {
        const arrayWithUuidField = addUuidFiled(turnPotentialEvents)
        return arrayWithUuidField.filter(({level}) => level !== 'IGNORE' && level !== 'LOW')
    }, [turnPotentialEvents]);

    useEffect(() => {
        const ant_table_body = document.getElementsByClassName('ant-table-container')[0];
        if (ant_table_body) {
            ant_table_body.scrollTo({
                top: getEventIndex * table_row_ref.current?.clientHeight,
                behavior: 'smooth'
            })
        }
    }, [cctv_id, table_row_ref]);

    const summaryData = (position, colSpan, index) => {
        return (
            <Table.Summary fixed={position}>
                <SummaryTable colSpan={colSpan} index={index}>
                    <SummaryEventButtons
                        validateString={isValidateString(isValidFields, timeZone)}
                        downloadEventFile={downloadEventFile} isFinishValidation={isFinishValidation}
                        isValidFields={isValidFields} handleFinishValidation={handleFinishValidation}
                        handleOpenAddNewEvent={handleOpenAddNewEvent}
                        isEventsThere={events.length > 0}
                        isSimpleUser={role !== VIEWER}
                        isStillTurnInRedis={turnaround['source'] === "redis"}
                    />
                </SummaryTable>
            </Table.Summary>
        )
    }


    const collapseItemsList = [
        {
            key: "1",
            label: "Events",
            roles: All,
            children: (
                <Table
                    dataSource={events} columns={filteredEventColumns}
                    loading={isEvents} sticky pagination={false} rowKey={({uuid}) => uuid}
                    rowClassName={({Manual_Event}, index) => mainEventsRowClass(Manual_Event, index)}
                    summary={() => summaryData('top', 9, 2)}
                    onRow={(_, index) => {if (index === 0) return {ref: table_row_ref}}}
                    footer={() => (
                        <FooterMainEventTable
                            isFinish={!isFinishValidation && isValidFields['status']}
                            isShowColorSettings={events.length}
                            finishValidInformation={isValidateString(isValidFields, timeZone)}
                        />
                    )}
                    onChange={(pagination, {camera}, {order}) => {
                        settableFunctionality({camera, sorter: order})
                    }}

                />
            )
        },
        {
            key: "2",
            label: "Potential Events",
            roles: WithoutViewer,
            onClick: handleShowPotentialEvents,
            children: (
                <Table
                    dataSource={getNotFoundEvents} sticky pagination={false}
                    columns={potentialEventTurnColumns({
                        handleGetPairedEvent,
                        getColumnSearchProps,
                        isShowFilter: false,
                        isStillTurnInRedis: turnaround['source'] === 'redis'

                    })}
                    rowKey={({uuid}) => uuid} loading={isTurnPotentialEvents}
                    rowClassName={({level}) => potentialEventRowClass(level)}
                    footer={() => (
                        <div className={'footerPotentialEventTable'}>
                            <ColorEventTableInstruction
                                colors={colorHelperPotentialEventTable}
                                isShowColorSettings={turnPotentialEvents.length}
                            />
                        </div>
                    )}
                />
            )
        }
    ]

    return (
        <Collapse
            activeKey={collapse}
            className={tableClasses}
            defaultActiveKey={['1']}
            items={collapseItemsList.filter(({roles}) => roles.includes(role))}
            onChange={handleOnChangeCollapse}
            bordered={false}
            style={{background: token.colorBgContainer}}
        />
    );
};

export default CollapseTurnTables;