import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import useThrottledResizeObserver from "../../../hooks/useThrottledResizeObserver";
import useImg from "../../../hooks/useImg";
import useReadyState from "../../../hooks/useReadyState";
import {getStorageValue} from "../../../utils/CacheHelper";
import {calibrationTooltipInst} from "../../../utils/helpers/objects/DrawDescription";
import DrawButtons from "../../FormAddGate/StepThreeNew/DrawButtons";
import {toggleStorage, updateFieldToGateCameraForm} from "../../../reduxToolkit/slices/Api/newGate";
import {selectCctvFrames, selectUploadedFrame} from "../../../reduxToolkit/selectors/Api/frames";
import {selectIdCamera} from "../../../reduxToolkit/selectors/Api/formAddGate";
import {toast} from "react-toastify";
import {showModalInfoContent} from "../../../utils/helpers/functions/modalContent";
import {filterElements} from "../../../utils/helperFunctions";
import {isEqual} from "../../../utils/helpers/functions/analiza";
import useReactZoomPanPinch from "../../../hooks/useReactZoomPanPinch";
import {CIRCLE_CLOSE, INFO, UNDO, UPLOAD} from "../../ANTD/Icons";
import {extrinsicDrawButtonsTools} from "../../../utils/helpers/objects/drawButtonsTools";
import useUploadFrameHandler from "../../../hooks/useUploadFrameHandler";
import {imageFileAccepts} from "../../../utils/helpers/variables/accepts";
import ZoomableCanvasFrameComponent from "../../Customs/ZoomableCanvasFrameComponent";
import {Carousel} from "antd";
import ImageFallbackWrapper from "../../Customs/ImageFallbackWrapper";
import useDrawIntrinsicCrossLines from "../../../hooks/useDrawIntrinsicCrossLines";

const Calibration = ({hide, showHideImg}) => {
    const dispatch = useDispatch();

    const frames = useSelector(selectCctvFrames);
    const cctv_id = useSelector(selectIdCamera);
    const uploadedFrame = useSelector(selectUploadedFrame);

    const {img_width, img_height} = useImg(frames[cctv_id]?.imgLink);
    const {ref: ref_canvas_frame_block, width, height, dpi_width, dpi_height} = useThrottledResizeObserver(100);
    const {state: calibrations, onChangeReadyState: setCalibrations} = useReadyState([]);

    const {state: isReadyToDraw, onChangeReadyState: setIsReadyToDraw} = useReadyState(false);
    const {state: isDisableFirstTime, onChangeReadyState: setIsDisableFirstTime} = useReadyState(true);
    const {state: carouselSlideNumber, onChangeReadyState: setCarouselSlideNumber} = useReadyState(null);

    const [scale, setScale] = useReactZoomPanPinch(1);
    const onUploadImgFile = useUploadFrameHandler(cctv_id);


    const cameraStorage = getStorageValue('camera', {}, 'session');

    const frameList = [frames[cctv_id].imgLink, uploadedFrame?.uploadedFrame];

    console.log('Calibration');



    const filteredCalibrations = useMemo(() => {
        return filterElements(calibrations, (pointList) => pointList.length > 0)
    }, [calibrations]);

    const isEditedCalibrationData = useMemo(() => {
        return isEqual(filteredCalibrations, cameraStorage.calibration?.lines)
    }, [filteredCalibrations])

    const handleUndo = () => {
        let copy_calibration = [...calibrations];

        const emptyArrayIndex = copy_calibration.findIndex(points => points.length === 0);
        const arrayOnePointIndex = copy_calibration.findIndex(points => points.length === 1);

        const lastCalibIndexItemNumb = calibrations.length - 1

        if (!isReadyToDraw) setIsReadyToDraw(true);

        if (arrayOnePointIndex === 0) copy_calibration = []

        if (emptyArrayIndex >= 1) copy_calibration.splice(emptyArrayIndex, emptyArrayIndex);

        if (arrayOnePointIndex > -1) {
            copy_calibration.splice(arrayOnePointIndex, arrayOnePointIndex);
            if (isReadyToDraw) setIsReadyToDraw(false);
        }


        if (copy_calibration[lastCalibIndexItemNumb] && copy_calibration[lastCalibIndexItemNumb].length >= 2) {
            copy_calibration[lastCalibIndexItemNumb].pop()
        }

        setCalibrations(copy_calibration)
    }

    const handleSave = () => {
        const isOnePoint = filteredCalibrations.some(line => line.length < 2);
        const isEnoughListItems = filteredCalibrations.length >= 4;
        const isEmptyList = filteredCalibrations.length === 0

        if (isOnePoint && !isEnoughListItems) toast.warning('Too few points, draw a minimum of 2 points');

        if (!isEmptyList && !isEnoughListItems && !isOnePoint) toast.warning('Too few lines, draw a minimum of 4 lines');

        if (isEmptyList || isEnoughListItems) {
            dispatch(updateFieldToGateCameraForm({
                key: "calibration",
                value: {...cameraStorage.calibration, lines: filteredCalibrations, status: "changed"}
            }));
            dispatch(toggleStorage(true))
            setIsDisableFirstTime(true);
            setIsReadyToDraw(false);
            hide()
        }
    }

    const handleGetMousePoss = useCallback(({clientX, clientY}, setCorrectHandlePosition) => {
        if (isReadyToDraw) {
            const {x, y} = setCorrectHandlePosition(clientX, clientY);
            if (isDisableFirstTime) setIsDisableFirstTime(false);
            const calibrationCopyList = [...calibrations];

            const lastCalibIndexItemNumb = calibrationCopyList.length - 1
            const lastPointList = calibrationCopyList[lastCalibIndexItemNumb];


            lastPointList.push({x: x / (width * scale), y: y / (height * scale)});
            calibrationCopyList[lastCalibIndexItemNumb] = lastPointList;
            setCalibrations(calibrationCopyList);
        }
    }, [width, height, scale, isDisableFirstTime, isReadyToDraw])

    const handleClearDrawList = () => {
        setCalibrations([]);
        if (isReadyToDraw) setIsReadyToDraw(false);
    }

    const handleCloseModal = () => {
        handleClearDrawList()
        setIsDisableFirstTime(true)
        hide()
    }

    const handleAddNewLine = () => {
        if (isDisableFirstTime) setIsDisableFirstTime(false);

        if (!isReadyToDraw) setCalibrations([...calibrations, []]);

        setIsReadyToDraw(prev => !prev);
    }

    const isSomeIncomplete = useMemo(() => {
        return calibrations.every(pointList => pointList.length >= 2)
    }, [calibrations]);

    const zoomableImgWrapperProps = {
        dpi_width,
        dpi_height,
        handleGetMousePosition: handleGetMousePoss,
        trackingZoomScale: setScale,
        drawCanvasFunction: useDrawIntrinsicCrossLines({
            intrinsicList: calibrations, dpi_width, dpi_height, isReadyToDraw, isEditedCalibrationData
        })
    }

    const drawBtnProps = {
        isChanged: !isEditedCalibrationData,
        add: {
            onClick: handleAddNewLine,
            name: !isReadyToDraw ? "Draw New Line" : "Finish Drawing",
            disabled: isReadyToDraw && !isSomeIncomplete,
            title: isReadyToDraw && !isSomeIncomplete && 'Should be minimum 2 points',
        },
        remove: {
            name: "Undo", onClick: handleUndo, icon: UNDO,
            disabled: isDisableFirstTime || isEditedCalibrationData || !filteredCalibrations.length
        },
        reset: {
            ...extrinsicDrawButtonsTools.reset,
            onConfirm: handleClearDrawList,
            disabled: !filteredCalibrations.length
        },
        close: {...extrinsicDrawButtonsTools.close, onClick: handleCloseModal},
        save: {...extrinsicDrawButtonsTools.save, icon: CIRCLE_CLOSE, onConfirm: handleSave, onCancel: handleCloseModal},
        info: {
            icon: INFO, onClick: () => showModalInfoContent({
                width: '600px', title: 'Drawing instructions', infoContent: calibrationTooltipInst
            })
        },
        upload: {icon: UPLOAD, onChange: onUploadImgFile, accept: imageFileAccepts, disabled: isReadyToDraw}
    }

    const imgSectionStyles = {
        maxWidth: `${img_width}px`,
        maxHeight: `${img_height}px`,
        cursor: !isReadyToDraw ? 'not-allowed' : 'pointer'
    }

    useEffect(() => {
        if (showHideImg && cameraStorage && cameraStorage.calibration?.lines.length) {
            setCalibrations(cameraStorage.calibration?.lines);
        }
    }, [showHideImg]);

    return (
        <div className={'big_img_wrapper'}>
            <div className={'big_img_wrapper__img_section'} style={imgSectionStyles}>
            {/*<ImageFallbackWrapper className={"big_img_wrapper__img_section"} img={frames[cctv_id]?.imgLink || frames[cctv_id]?.uploadedImg}>*/}
                <div ref={ref_canvas_frame_block} className={'zoomable_img_wrapper'}>
                    <Carousel adaptiveHeight dots={frameList.every(frame => frame)} afterChange={setCarouselSlideNumber}>
                        {frameList.map((frame, index) => {
                            if (frame) {
                                return (
                                    <ZoomableCanvasFrameComponent
                                        {...zoomableImgWrapperProps}
                                        url={frame}
                                        key={frame}
                                        isReadyZoomToDef={carouselSlideNumber !== null && carouselSlideNumber !== index}
                                    />
                                )
                            }
                        })}
                    </Carousel>
                </div>
            {/*</ImageFallbackWrapper>*/}
            </div>
            <footer className={"big_img_wrapper__draw_btn_section"}>
                <DrawButtons {...drawBtnProps}/>
            </footer>
        </div>
    );
};
export default Calibration;