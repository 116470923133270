import useCanvas from "../../hooks/useCanvas";
import {debounce} from "lodash";
import {useEffect} from "react";

const CanvasList = ({ drawCanvasFunction, dpi_width, dpi_height, handleGetMousePosition, ...canvasProps}) => {
    const {canvas_ref, current,  ctx} = useCanvas();

    const setCorrectHandlePosition = (x, y) => {
        const {left, top} = current?.getBoundingClientRect();
        return {x: x - left, y: y - top};
    }

    const debounceDrawFunction = debounce(() => {
        // console.log('DEBOUNCED')
        drawCanvasFunction(ctx)
    },100);

    useEffect(() => {
        if (ctx) debounceDrawFunction()
        return () => {
            debounceDrawFunction.cancel()
        }
    }, [ctx, dpi_width, dpi_height, drawCanvasFunction]);

    return (
        <canvas
            ref={canvas_ref}
            width={dpi_width}
            height={dpi_height}
            onDoubleClick={(event) => handleGetMousePosition(event, setCorrectHandlePosition)}
            {...canvasProps}
        />
    );
};

export default CanvasList