import {useState} from 'react';
import {Checkbox} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {editPotentialEvent, getPotentialEvents} from "../../../reduxToolkit/asyncThunkFunctions/Api/EventsRequest";
import {selectPotentialEvents} from "../../../reduxToolkit/selectors/Api/events";

const SystemEventActions = ({isActive, event, gate, camera}) => {
    const dispatch = useDispatch();
    const {potentialPage: page, potentialLimit: limit, potentialFilterObj} = useSelector(selectPotentialEvents);

    const [active, setActive] = useState(isActive);

    const asyncHandler = async (value, dispatch) => {
        await dispatch(editPotentialEvent({required: value, gate, ...event}))
        await dispatch(getPotentialEvents({page, limit, gate, camera, ...potentialFilterObj}))
    }

    const handleOnChangeIsRequired = (event) => {
        const value = event.target.checked
        setActive(value);
        asyncHandler(value, dispatch);
    }

    return <Checkbox checked={active} onChange={handleOnChangeIsRequired} className={"required_event_checkbox"}/>
};

export default SystemEventActions;