import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Button, Popconfirm, Tooltip} from "antd";
import {getEvents} from "../../reduxToolkit/asyncThunkFunctions/Api/TurnaroundRequests";
import {deleteEvent} from "../../reduxToolkit/asyncThunkFunctions/Api/EventsRequest";
import {CLOSE, MARK, PENCIL, TRASH} from "../ANTD/Icons";
import {useParams} from "react-router-dom";
import {addValidation} from "../../reduxToolkit/asyncThunkFunctions/Api/ValidationRequests";
import {selectProfile} from "../../reduxToolkit/selectors/Api/auth";
import {selectTurnInfo} from "../../reduxToolkit/selectors/Api/turnaround";

const ActionsButton = ({event, handleOpenEditForm}) => {
    const dispatch = useDispatch()
    const {role} = useSelector(selectProfile);
    const turnaround = useSelector(selectTurnInfo);
    const {turnaround_id, cctv_id} = useParams();

    const {Manual_Event, validation} = event
    const is_finished = Object.keys(validation).length > 0 && validation['finished']
    const isViewer = role === 'viewer'
    const is_approved = !('reject_reason' in validation);

    const greenActionValidButtonColor = is_finished && is_approved && !isViewer ? '#228B22' : ''
    const reedActionValidButtonColor = is_finished && !is_approved && !isViewer ? '#f5222d' : ''
    const isStillTurnInRedis = turnaround['source'] === "redis"

    const tooltipMessage = (isStillTurnInRedis && !isViewer) && "disabled while turn is active"
    const handleDeleteEvent = async () => {
        await dispatch(deleteEvent({turnaround_id, event}))
        await dispatch(getEvents({turnaround_id, cctv_id}))
    };

    const handleApproveValidation = () => {
        const {uuid, ...rest} = event
        return dispatch(addValidation({turnaround_id, cctv_id, event: {...rest, validation: {finished: true}}}))
    }

    return (
        <div className={'turnaroundTableCollapse__actionsEventButtonsBlock'}>
            {!Manual_Event &&
                (
                    <>

                        <Tooltip placement={'bottom'} title={tooltipMessage} >
                            <Button
                                disabled={isStillTurnInRedis || Manual_Event || isViewer || is_finished}
                                className={'actionEventButton'} onClick={handleApproveValidation}
                                style={{color: `${greenActionValidButtonColor}`}}>{MARK}</Button>
                        </Tooltip>
                        <Tooltip placement={'bottom'} title={tooltipMessage} >
                            <Button
                                disabled={isStillTurnInRedis || Manual_Event || isViewer || is_finished}
                                className={'actionEventButton'} onClick={handleOpenEditForm}
                                style={{color: `${reedActionValidButtonColor}`}}>{CLOSE}</Button>
                        </Tooltip>
                        <Tooltip placement={'bottom'} title={tooltipMessage} >
                            <Button
                                disabled={isStillTurnInRedis || isViewer || !is_finished}
                                className={'actionEventButton'} onClick={handleOpenEditForm}>{PENCIL}</Button>
                        </Tooltip>
                    </>
                )
            }
            {Manual_Event
                &&
                <Tooltip placement={'bottom'} title={tooltipMessage}>
                    <Popconfirm title={"Are you sure ?"} onConfirm={handleDeleteEvent}>
                        <Button disabled={isViewer} className={'actionEventButton'}>{TRASH}</Button>
                    </Popconfirm>
                </Tooltip>
            }
        </div>
    );
};

export default ActionsButton;