import {useState, useCallback} from "react";

export default function useToggle(initialState = false) {
    const [state, setState] = useState(initialState);

    const toggle = useCallback(() => setState(!state),[])

    const close = useCallback(() => setState(false), []);

    const open = useCallback(() => setState(true), []);

    const previewCheckClasses = {'active' : state, 'notActive': !state};

    return {state, open, close, toggle, previewCheckClasses}
}