import {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Card, DatePicker, Form, Input, Select} from "antd";
import {getDataFile, setOptionsSettings} from "../../utils/helperFunctions";
import {getMillisecondsUnixTimestamp} from "../../utils/TimeHelper";
import {useDispatch, useSelector} from "react-redux";
import {handleGetDownloadFileName, handleOnChangeIsDisabled} from "../../reduxToolkit/slices/Websocket/DownloadWsFile";
import {defDownloadFileState} from "../../utils/DefaultStates/States";
import {
    connectionToDownloadFileSession,
    getMessage, sendToDownloadFileMessageSession
} from "../../reduxToolkit/asyncThunkFunctions/Weboscket/DownloadFileMessages";
import {getPrepareToast} from "../../reduxToolkit/slices/Ui/Notification";
import {toast} from "react-toastify";
import {getListOfCctvID, getListOfGateNames} from "../../reduxToolkit/asyncThunkFunctions/Api/GateRequest";
import {resetGateState} from "../../reduxToolkit/slices/Api/sliceGateRequest";
import {onChangeIsOpenSession} from "../../reduxToolkit/slices/Websocket/General";
import {selectProfile} from "../../reduxToolkit/selectors/Api/auth";
import {selectSettingsUi} from "../../reduxToolkit/selectors/Api/system";
import {saveAs} from 'file-saver';
import {
    selectDownloadFileName,
    selectDownloadIsDisable,
    selectDownloadWsConnection
} from "../../reduxToolkit/selectors/Websocket/download";
import {selectCctvIds, selectGateNames} from "../../reduxToolkit/selectors/Api/gates";
import {selectIsOpenWsSession} from "../../reduxToolkit/selectors/Websocket/general";

const Download = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const {RangePicker} = DatePicker;
    const [turnValueId, setTurnValueId] = useState('');
    const [rangePickerTime, setRangePickerTime] = useState(null);

    const profile = useSelector(selectProfile);
    const {AIRPORT_IATA} = useSelector(selectSettingsUi)
    const {isConnected} = useSelector(selectDownloadWsConnection);
    const download_file_name = useSelector(selectDownloadFileName);
    const isDisableDownloadButton = useSelector(selectDownloadIsDisable);
    const {gatesName} = useSelector(selectGateNames);
    const {cctvIds} = useSelector(selectCctvIds)

    const isOpenSession = useSelector(selectIsOpenWsSession);

    const download_frame_params = useRef(null);

    const empty_rule = {required: true, message: "Mustn't be empty"}
    const handleOnChangeTurnId = (value) => setTurnValueId(value);
    const handleOnChangeRangePicker = (value) => setRangePickerTime(value);

    const getGateNameOptions = useMemo(() => setOptionsSettings(gatesName), [gatesName]);
    const getCctvIdOptions = useMemo(() => setOptionsSettings(cctvIds), [cctvIds]);
    const onChangeSomeEventFormFiled = (fields) => form.setFieldsValue(fields);

    const downloadFile = (file, file_name) => saveAs(file, file_name);
    const downloadFileWithSplitName = (file_path) => {
        if (file_path) {
            const splits = file_path.split('/');
            return downloadFile(file_path, splits[splits.length-1]);
        }
    }

    const transRangePickerDates = () => {
        const [start, end] = rangePickerTime
        return {start: getMillisecondsUnixTimestamp(start), end: getMillisecondsUnixTimestamp(end)}
    }

    const handleDownloadTurnPayload = async () => {
        const params = {turnaround_id: turnValueId}
        const payload = await getDataFile({path: 'turnarounds/payload', config: {params}, method: 'GET'})
        if (payload) {
            return downloadFile(payload, `payload_${params['turnaround_id']}`);
        }
    }

    const handleDownloadTurnEvents = async () => {
        const params = {turnaround_id: turnValueId, as_file: true}
        const events = await getDataFile({path: 'turnarounds/events', config: {params, responseType: 'blob'}, method: 'GET'})
        if (events) {
            return downloadFile(events, `${params['turnaround_id']}_events.csv`);
        }
    }

    const handleDownloadTurnValidInfo = async () => {
        const params = {turnaround_id: turnValueId}
        const validInfo = await getDataFile({path: 'turnarounds/validation', config: {params}, method: 'GET'})
        downloadFileWithSplitName(validInfo)
    }

    const handleDownloadImages = async (params) => {
        const {gate_id, cctv_id, start, end} = params
        const file_name = `${AIRPORT_IATA}--${gate_id}--${cctv_id}--${start}--${end}`

        download_frame_params.current = {cctv_id, start: +start, end: +end}

        dispatch(onChangeIsOpenSession(true));
        dispatch(handleOnChangeIsDisabled(true));
        dispatch(handleGetDownloadFileName(file_name));
        console.log('onopen');

    }

    const handleDownloadAllValidInfo = async (body = {start: '', end: ''}) => {
        const valid_info = await getDataFile({path: 'events/validation_info', method: 'POST', body})
        downloadFileWithSplitName(valid_info);
    }

    const handleDownloadAllEventsInfo = async () => {
        const events_info = await getDataFile({path: 'events/events_info', method: 'POST', body: transRangePickerDates()})
        downloadFileWithSplitName(events_info)
    }

    const handleDownloadValidLast24Hour = async () => {
        const last_24_hour = await getDataFile({path: 'events/validation_data', method: 'GET'})
        downloadFileWithSplitName(last_24_hour)
    }

    const onDropVisibleChangeGateName = (isOpen) => {
        if (isOpen) {
            dispatch(getListOfGateNames());
        }
    }

    const onChangeGateName = (_id) => {
        dispatch(getListOfCctvID({_id}))
        onChangeSomeEventFormFiled({cctv_id: ''})
    }

    useEffect(() => {
        if (isOpenSession && !isConnected) {
            dispatch(connectionToDownloadFileSession());
            dispatch(getMessage())
            dispatch(onChangeIsOpenSession(false))
        }

    }, [isOpenSession, isConnected]);

    useEffect(() => {
        if (download_file_name && isConnected) {
            dispatch(sendToDownloadFileMessageSession({
                params: {...download_frame_params.current, token: profile?.user_token}, type: 'download_video_blob'
            }))
            dispatch(getPrepareToast({
                toast_name: 'download_video',
                toast_id: toast.loading('Prepare for download video', {closeButton: true})
            }))
        }
    }, [download_file_name, isConnected]);


    useEffect(() => {
        return () => {
            dispatch(resetGateState())
        }
    }, []);

    return (
        <div className={'systemWrapper__downloadWrapper'}>
            <header><h2>DOWNLOAD</h2></header>
            <section className={'downloadSection'}>
                <Card title={'Turnaround data'} className={'downloadSection__systemCardBlock turnCardDataBlock'}>
                    <section style={{width: '250px', margin: '0 auto 10px auto'}}>
                        <Input placeholder={'turn id'} value={turnValueId}
                               onChange={(e) => handleOnChangeTurnId(e.target.value)}/>
                    </section>
                    <footer>
                        <Button disabled={!turnValueId} onClick={handleDownloadTurnPayload}>Payload</Button>
                        <Button disabled={!turnValueId} onClick={handleDownloadTurnEvents}>Events</Button>
                        <Button disabled={!turnValueId} onClick={handleDownloadTurnValidInfo}>Validation info</Button>
                    </footer>
                </Card>
                <Card title={'Video'} className={'downloadSection__systemCardBlock imagesCardDataBlock'}>
                    <Form style={{width: '250px', margin: '0 auto 10px auto'}} onFinish={handleDownloadImages}
                          form={form} initialValues={defDownloadFileState}>
                        <section>
                            <Form.Item name={'gate_id'} rules={[empty_rule]}>
                                <Select
                                    placeholder={'select gate name'}
                                    options={getGateNameOptions}
                                    onChange={onChangeGateName}
                                    onDropdownVisibleChange={onDropVisibleChangeGateName}
                                />
                            </Form.Item>
                            <Form.Item name={'cctv_id'} rules={[empty_rule]}>
                                <Select placeholder={'select cctv_id'} options={getCctvIdOptions}/>
                            </Form.Item>
                            <Form.Item name={'start'} rules={[empty_rule, {pattern: /^\d{13}$/, message: 'Incorrect format'}]}>
                                <Input placeholder={'from -> timestamp'}/>
                            </Form.Item>
                            <Form.Item name={'end'} rules={[empty_rule, {pattern: /^\d{13}$/, message: 'Incorrect format'}]}>
                                <Input placeholder={'to -> timestamp'}/>
                            </Form.Item>
                        </section>
                        <footer>
                            <Form.Item shouldUpdate>
                                {({getFieldsError}) => {
                                    const isValidated = getFieldsError()?.some(({errors}) => errors.length > 0);
                                    return (
                                        <Button
                                            htmlType={'submit'}
                                            disabled={isValidated || !!isDisableDownloadButton}
                                        >Download</Button>
                                    )
                                }}
                            </Form.Item>
                        </footer>
                    </Form>
                </Card>
                <Card title={'Validations'} className={'downloadSection__systemCardBlock validCardDataBlock'}>
                    <section className={'validCardDataBlock__rangeDateSection'}>
                        <RangePicker value={rangePickerTime} onChange={handleOnChangeRangePicker} showTime
                                     size={'middle'}/>
                    </section>
                    <footer>
                        <Button onClick={() => handleDownloadAllValidInfo(transRangePickerDates())}
                                disabled={!rangePickerTime}>Validations by range</Button>
                        <Button onClick={handleDownloadAllEventsInfo}
                                disabled={!rangePickerTime}>Events by range</Button>
                        <Button onClick={handleDownloadValidLast24Hour}>Last 24 hour data</Button>
                        <Button onClick={() => handleDownloadAllValidInfo()}>All validations
                            metadata</Button>
                    </footer>
                </Card>
            </section>
        </div>
    );
};

export default Download;