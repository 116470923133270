import {combineReducers} from "redux";
import Auth from "../slices/Api/Auth";
import User from "../slices/Api/User";
import Logs from "../slices/Api/Logs";
import systemSlice from "../slices/Api/System";
import sliceGateRequest from "../slices/Api/sliceGateRequest";
import turnaroundInfo from "../slices/Api/turnaroundInfo";
import Events from "../slices/Api/Events";
import Validation from "../slices/Api/Validation";
import FlightsFiles from "../slices/Api/FlightsFiles";
import performanceSlice from "../slices/Api/Performance";
import newGate from "../slices/Api/newGate";
import Frames from '../slices/Api/Frames'
import AbortController from "../slices/Api/AbortController";

const Api = combineReducers({
    Auth,
    User,
    Logs,
    systemSlice,
    Gates:  sliceGateRequest,
    turnaroundSlice: turnaroundInfo,
    Events,
    Validation,
    FlightsFiles,
    performanceSlice,
    formGate: newGate,
    Frames,
    AbortController
})

export default Api