import { v4 as uuid4 } from 'uuid'
import {getMillisecondsUnixTimestamp} from "./TimeHelper";
import air_axios_settings from "./axiosBody";
import {toast} from "react-toastify";


export const showCorrectToast = ({type, message}) => {
    switch (type) {
        case 'done' || 'success':
            return toast.success(message)
        case 'error':
            return toast.error(message)
        case 'warning':
            return toast.warning(message)
        default:
            return toast.warning('Incorrect type')
    }
}


export const resortObjectFieldsFunction = (object, arrayCorrectPositionFields) => {
    return Object.entries(object).sort(([keyA], [keyB]) => {
        return arrayCorrectPositionFields.indexOf(keyA) - arrayCorrectPositionFields.indexOf(keyB)
    })
}

export const filterElements = (array, condition) => array?.filter(item => condition(item));

export const setOptionsSettings = (array) => array?.map(item => ({label: item, value: item}));

export const setFilterOptionSettings = (array) => array?.map(item => ({text: item, value: item}));

export const setOptionsSettingsTest = (array, key = null) => array?.map(item => {
    if (key) return {label: item[key], value: item[key]}
    return {label: item, value: item}
})

export const setSomeFiled = (arrayObj, key) => arrayObj?.map(obj => obj[key])
export const getUniqueValuesTest = (arrayKeys) => [...new Set(arrayKeys)]

export const getUniqueValues = (arrayObj, key) => {
    const arrayKeys = arrayObj?.map(obj => obj[key]);
    return [...new Set(arrayKeys)]
}

export const addUuidFiled = (arrayObj) => arrayObj?.map(item => ({...item, uuid: uuid4()}));

export const sortByDate = (a, b) => {
    if (!a || !b) return -1
    return getMillisecondsUnixTimestamp(a) - getMillisecondsUnixTimestamp(b)
}

export const sortByKeysLength = (a, b) => {
    if (!a || !b) return -1
    return Object.keys(b).length - Object.keys(a).length
}
const setMethods = async ({method, path, body, config}) => {
    if (method === 'POST') return await air_axios_settings.post(path, body, config)
    if (method === 'GET') return await air_axios_settings.get(path, config)

}

export const getDataFile = async (settings) => {
    try {
        const {data} = await setMethods(settings)
        if (!data) toast.warning('There is no data');
        return data
    } catch (error) {
        toast.error(`Something happened, plz try again`)
        throw new Error(error)
    }
}