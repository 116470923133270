import {useCallback, useState} from "react";

export default function (pageNumber, limitTotal, toolsObj) {
    const [page, setPage] = useState(pageNumber || 1);
    const [limit, setLimit] = useState(limitTotal || 5);
    const [tools, setTools] = useState(toolsObj || {});

    const onChangePage = useCallback((pageNumber) => setPage(pageNumber), []);
    const onChangeLimit = useCallback((pageLimit) => setLimit(pageLimit), []);
    const onChangeTools = useCallback((pageTools) => setTools(pageTools), []);

    const setSettings = (page, limit, settings) => {
        setPage(page);
        setLimit(limit);
        setTools({...tools, ...settings});
    }

    const getInitialStates = () => {
        setPage(1)
        setLimit(5)
        setTools({})
    }

    return {page, limit, tools, setSettings, getInitialStates, onChangePage, onChangeLimit, onChangeTools}
}