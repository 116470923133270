import {useEffect} from 'react';
import {Route, Routes, Navigate} from "react-router-dom";
import Login from "./components/SignIn/Login";
import {handleUpdateUser} from "./reduxToolkit/slices/Api/Auth";
import {useDispatch, useSelector} from "react-redux";
import BigLoading from "./components/Loadings/BigLoading";
import PrimaryRoutes from "./routes/PrimaryRoutes";
import {LOGIN, STAR} from "./utils/RouteSettings/Paths";
import {selectAuthUser} from "./reduxToolkit/selectors/Api/auth";

const Intell_Act = () => {
    const dispatch = useDispatch();
    const {isAuthLoading, isAuth} = useSelector(selectAuthUser);

    useEffect(() => {
        const userCash = JSON.parse(localStorage.getItem('profile'))
        const userTokenCash = localStorage.getItem('token')
        if (userCash) dispatch(handleUpdateUser({...userCash, user_token: `Bearer ${userTokenCash}`}))

    }, [])

    if (isAuthLoading) return <BigLoading/>

    if (!isAuth) {
        return (
            <Routes>
                <Route path={LOGIN} element={<Login/>}/>
                <Route path={STAR} element={<Navigate to={LOGIN} replace/>}/>
            </Routes>
        )
    }

    return <PrimaryRoutes/>
}
export default Intell_Act;