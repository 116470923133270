import {combineReducers} from "redux";
import Resending from "../slices/Ui/Resending";
import Geotime from "../slices/Ui/Geotime";
import Notification from "../slices/Ui/Notification";
import Files from "../slices/Ui/Files";

const Ui = combineReducers({
    geotime: Geotime,
    resending: Resending,
    notif: Notification,
    files: Files,
})

export default Ui;