import {useCallback} from "react";
import {returnXY} from "../utils/helpers/functions/calcPositions";
import {clearCanvasList, drawCross, drawLines} from "../utils/helpers/functions/drawCanvasItems";
import {randomColorList} from "../utils/helpers/arrays/colors";

export default function ({intrinsicList, dpi_width, dpi_height, isEditedCalibrationData, isReadyToDraw}) {
    return useCallback((context) => {
        console.log('render useDrawIntrinsicCrossLines');
        clearCanvasList(context)
        return intrinsicList.forEach((pointList, pointListIndex) => {
            const isHideOldLines = isReadyToDraw && (intrinsicList.length - 1 !== pointListIndex) ? 0.5 : 1
            pointList.forEach(({x, y}, index) => {
                const {x: circle_x, y: circle_y} = returnXY(x, y, dpi_width, dpi_height);
                drawCross({
                    context, crossSize: 10,
                    x: circle_x, y: circle_y,
                    strokeStyle: "#FF0000",
                    lineWidth: 2, opacity: isHideOldLines
                });



                if (index > 0) {
                    const {x, y} = returnXY(pointList[index - 1].x, pointList[index - 1].y, dpi_width, dpi_height);
                    const isCurrentLine = !isEditedCalibrationData && isReadyToDraw && (intrinsicList.length - 1 === pointListIndex);
                    const strokeStyle = isCurrentLine ? "#00FFFF" : randomColorList[pointListIndex];
                    const lineWidth = isCurrentLine ? 2 : 3

                    drawLines({
                        context, lineWidth, startX: x, startY: y,
                        endY: circle_y, endX: circle_x, moveTo: true,
                        strokeStyle, opacity: isHideOldLines
                    });
                }
            })
        });
    }, [intrinsicList, dpi_width, dpi_height, isEditedCalibrationData, isReadyToDraw])
}