import React, {useEffect} from 'react';

const LinkImg = ({totalFrames, cctv_id, imgLink, handleGoTo, isOpenModalGoTo, getImgVideoFrame}) => {

    useEffect(() => {
        if (isOpenModalGoTo) {
            getImgVideoFrame(cctv_id)
        }
    }, [isOpenModalGoTo])


    return (
        <div className={'wrapperLinkImg'}>
            <div className={cctv_id}>
                <button onClick={() => handleGoTo(cctv_id)} className={'linkSecondTurnImg'}>
                    <img src={imgLink} alt={cctv_id} title={`Camera ${cctv_id}`}/>
                </button>
            </div>
            {totalFrames && <h5>Camera: {cctv_id}</h5>}
        </div>

    );
};
export default LinkImg;