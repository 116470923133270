import React from 'react';
import {Button, Tooltip} from "antd";

const SummaryEventButtons =
    ({
         downloadEventFile, isFinishValidation, isValidFields,
         handleFinishValidation, validateString, handleOpenAddNewEvent, isEventsThere, isSimpleUser, isStillTurnInRedis
    }) => {
    return (
        <div className={'summaryEventTableButtonsBlock'}>
            <Button onClick={downloadEventFile}>Export Events</Button>
            {isSimpleUser && (
                <>
                    {!isFinishValidation && !isValidFields['status'] && isEventsThere &&
                        <Button onClick={handleFinishValidation} className={'summaryEventTableButtonsBlock__finishValidationButton'}>
                            {validateString}
                        </Button>
                    }
                    <Tooltip placement={'bottom'} title={isStillTurnInRedis && "disabled while turn is active"}>
                        <Button onClick={handleOpenAddNewEvent} disabled={isStillTurnInRedis}>Add New Event</Button>
                    </Tooltip>

                </>
            )}
        </div>
    );
};

export default SummaryEventButtons;