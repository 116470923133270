import {useEffect, useState} from 'react';
import {Button, Card, InputNumber} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {editUiSettings} from "../../reduxToolkit/asyncThunkFunctions/Api/SystemRequests";
import {selectSettings} from "../../reduxToolkit/selectors/Api/system";

const Validation = () => {
    const dispatch = useDispatch();
    const {settingsUI: {TIMELINE_STEPS}, isEditSettingsLoading} = useSelector(selectSettings);
    const [second, setSecond] = useState(0);

    useEffect(() => setSecond(TIMELINE_STEPS), [TIMELINE_STEPS])
    const handleSendSecond = () => dispatch(editUiSettings({TIMELINE_STEPS: Number(second)}))
    const handleOnChangeSecond = (value) => setSecond(value)

    return (
        <div className={'systemWrapper__otherWrapper'}>
            <header><h2>Validation</h2></header>
            <section className={'otherSection'}>
                <Card title={'Camera settings'} className={'otherSection__otherCardBlock'}>
                    <section className={'cameraSettingsSection'}>
                        <span>Skip seconds</span>
                        <InputNumber min={1} max={240} value={second} onChange={handleOnChangeSecond}/>
                    </section>
                    <footer className={'cameraSettingsFooter'}>
                        <Button onClick={handleSendSecond} loading={isEditSettingsLoading}>Save</Button>
                    </footer>
                </Card>
            </section>
        </div>
    );
};

export default Validation;