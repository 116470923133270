import FidsModalButton from "../../../components/Fids/FidsModalButton";
import Cameras from "../../../components/Fids/Cameras";
import CameraEvents from "../../../components/Events/CameraEvents";
import EmptyField from "../../../components/Customs/EmptyField";
import TagItem from "../../../components/ANTD/TagItem";

export const fidsColumns = (
    {
        getColumnSearchProps,
        transformFidsDateTwo,
        gate_name_options,
        status_options,
        handleOpenModal,
    }) => ([
    {
        key: 'turnaround_id',
        title: 'Id',
        dataIndex: 'id',
        ...getColumnSearchProps('id'),
        filterSearch: true,
        render: (turn_id, _) => {
            return (
                <EmptyField isEmpty={!turn_id} message={'N/A'}>
                    <FidsModalButton id={turn_id} handleOpenModal={handleOpenModal}/>
                </EmptyField>
            )
        }
    },
    {
        key: 'gate',
        title: 'Gate',
        dataIndex: 'parking_gate',
        filters: gate_name_options,
        filterSearch: true,
        onFilter: (value, {parking_gate}) => parking_gate.indexOf(value) === 0,
        render: (parking_gate) => {
            return (
                <EmptyField isEmpty={!parking_gate} message={'NG'}>
                    <span>{parking_gate}</span>
                </EmptyField>
            )
        }

    },
    {
        key: 'sta',
        title: 'sta',
        dataIndex: 'sta_utc',
        sorter: true,
        render: (sta) => {
            return <EmptyField isEmpty={!sta} message={'N/A'}>
                <TagItem value={transformFidsDateTwo(sta)}/>
            </EmptyField>
        }

    },
    {
        key: 'eta',
        title: 'eta',
        dataIndex: 'eta_utc',
        sorter: true,
        render: (eta) => {
            return (
                <EmptyField isEmpty={!eta} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(eta)} color={'yellow'}/>
                </EmptyField>
            )
        },
    },
    {
        key: 'ata',
        title: 'ata',
        dataIndex: 'ata_utc',
        sorter: true,
        render: (ata) => {
            return (
                <EmptyField isEmpty={!ata} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(ata)} color={'#FFE100'} tag_class={'markTag'} mark/>
                </EmptyField>
            )
        },
    },
    {
        key: 'std',
        title: 'std',
        dataIndex: 'std_utc',
        sorter: true,
        render: (std) => {
            return (
                <EmptyField isEmpty={!std} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(std)} empty_value={'ND'} />
                </EmptyField>
            )
        },
    },
    {
        key: 'etd',
        title: 'etd',
        dataIndex: 'etd_utc',
        sorter: true,
        render: (etd) => {
            return (
                <EmptyField isEmpty={!etd} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(etd)} color={'#FFE100'} tag_class={'markTag'} mark/>
                </EmptyField>
            )
        },
    },
    {
        key: 'atd',
        title: 'atd',
        dataIndex: 'atd_utc',
        sorter: true,
        render: (atd) => {
            return (
                <EmptyField isEmpty={!atd} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(atd)} color={'#FFE100'} tag_class={'markTag'} mark/>
                </EmptyField>
            )
        },
    },
    {
        key: 'cameras',
        title: 'Cameras',
        dataIndex: 'frames',
        render: (frames, rec) => {
            return (
                <EmptyField isEmpty={!frames.length} message={"N/A"}>
                    <Cameras frames={frames} parking_gate={rec['parking_gate']} id={rec['id']}/>
                </EmptyField>
            )
        }
    },
    {
        key: 'frames',
        title: 'Events',
        dataIndex: 'frames',
        render: (frames, rec) => {
            return (
                <EmptyField isEmpty={!frames.length} message={'N/A'}>
                    <CameraEvents
                        frames={frames}
                        events={rec['events']}
                        is_validated={rec['is_validated']}
                        id={rec['id']}
                    />
                </EmptyField>
            )
        }
    },
    {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        filters: status_options,
        filterSearch: true,
        onFilter: (value, record) => record?.status?.indexOf(value) === 0,
        render: (status) => {
            const status_colors = {"done": "success", "awaiting": "processing", "archived": "purple", "removed": "red"}
            return (
                <EmptyField isEmpty={!status} message={'NS'}>
                    <TagItem value={status} color={status_colors[status]} mark tag_class={'markTag status'}/>
                </EmptyField>
            )
        }
    },
])