import React from 'react';
import PreviewGate from "../PreviewGate";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {ADD_CAMERA, FINISH} from "../../ANTD/Icons";
import {Button} from "antd";
import {FORCE_FORM_GATE_STEP} from "../../../utils/RouteSettings/Links";
import {selectFormGate, selectIdCamera} from "../../../reduxToolkit/selectors/Api/formAddGate";
import {selectFile} from "../../../reduxToolkit/selectors/Ui/files";
import {sendCalibFrame} from "../../../reduxToolkit/asyncThunkFunctions/Api/CamerasRequests";
import {selectCctvFrames, selectUploadedFrame} from "../../../reduxToolkit/selectors/Api/frames";

const Finish = ({onSendRequest}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {name, id} = useParams();
    const {gate: {_id, cameras}, editCamera: {camera}} = useSelector(selectFormGate);
    const frames = useSelector(selectCctvFrames);
    const cctv_id = useSelector(selectIdCamera);
    const uploadedFrame = useSelector(selectUploadedFrame);

    const file = useSelector(selectFile);

    const styles = {justifyContent: cameras.length > 1 ? 'space-between' : 'center'}

    const handleAddNewCamera = () => {
        navigate(FORCE_FORM_GATE_STEP({_id: name, cctv_id: id, form_step: 'step_two'}))
        sessionStorage.removeItem('camera')
    }

    const handleFinish = () => {
        onSendRequest()
        if (file && uploadedFrame.uploadedFrame) {
            dispatch(sendCalibFrame(camera.cctv_id))
        }
    }

    return (
        <div className={'wrapperCameras'} style={{ width: cameras?.length > 1 ? '1280px' : '700px'}}>
            <h2>Gate Name => {_id}</h2>
            <div className={'cameras'} style={{...styles}}>
                <PreviewGate camera={camera} cameras={cameras}/>
            </div>
            <div className={'wrapperCameraButtons'}>
                {!id && <Button
                    onClick={handleAddNewCamera}
                    size={'large'}
                    type={'primary'}
                    icon={ADD_CAMERA}
                    value={'Add new camera'}>Add new camera</Button>
                }
                {(cameras.length > 0 || Object.keys(camera).length > 0)
                    && <Button
                        onClick={handleFinish}
                        size={'large'}
                        type={'primary'}
                        icon={FINISH}
                        value={'Finish'}>Finish</Button>
                }
            </div>
        </div>
    );
};

export default Finish;