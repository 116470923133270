import {createSlice} from "@reduxjs/toolkit";
import {getPerformanceInformation, getPerformances} from "../../asyncThunkFunctions/Api/PerformanceRequests";

const initialState = {
    performance_obj: {
        performances: [],
        perfsTotal: 0,
        isLoadingPerformance: false,
        errorPerformance: null,
    },
    performance_info_obj: {
        performanceInfo: [],
        perfInfoTotal: 0,
        isLoadingPerformanceInfo: false,
        errorPerformanceInfo: null
    },
    isFetching: false,
}

const performanceSlice = createSlice({
    name: 'performance',
    initialState,
    reducers: {
        toggleIsFetching(state, {payload}) {
            state['isFetching'] = payload
        },

        defPerformanceState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getPerformances.pending, (state) => {
            state.performance_obj.isLoadingPerformance = true
            state.performance_obj.errorPerformance = null
        })
            .addCase(getPerformances.fulfilled, (state, {payload: {data, total}}) => {
                state.performance_obj.performances = data
                state.performance_obj.perfsTotal = total
                state.performance_obj.isLoadingPerformance = false
            })
            .addCase(getPerformances.rejected, (state, {error}) => {
                state.performance_obj.errorPerformance = error['message']
                state.performance_obj.isLoadingPerformance = false
            })
        builder.addCase(getPerformanceInformation.pending, (state) => {
            state.performance_info_obj.isLoadingPerformanceInfo = true
            state.performance_info_obj.errorPerformanceInfo = null
        })
            .addCase(getPerformanceInformation.fulfilled, (state, {payload: {data, total}}) => {
                state.performance_info_obj.performanceInfo = data
                state.performance_info_obj.perfInfoTotal = total
                state.performance_info_obj.isLoadingPerformanceInfo = false
            })
            .addCase(getPerformanceInformation.rejected, (state, {error}) => {
                state.performance_info_obj.errorPerformanceInfo = error.message
                state.performance_info_obj.isLoadingPerformanceInfo = false
            })
    }
})


export default performanceSlice.reducer
export const {toggleIsFetching, defPerformanceState} = performanceSlice.actions