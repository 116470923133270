import {useMemo} from 'react';
import {Select, Form} from "antd";
import {setOptionsSettings} from "../../utils/helperFunctions";

const CustomSelect = ({onChange, loading, disabled, onDropdownVisibleChange, data, className, formItemElemSettings, isFormItem}) => {
    const customOptions = useMemo(() => setOptionsSettings(data), [data])

    const select = (
        <Select
            className={className}
            loading={loading}
            disabled={disabled}
            onChange={onChange}
            options={customOptions}
            onDropdownVisibleChange={onDropdownVisibleChange}
        />
    )

    if (isFormItem) {
        return (
            <Form.Item {...formItemElemSettings}>
                {select}
            </Form.Item>

        )
    }

    return select

};

export default CustomSelect;