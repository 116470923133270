import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isOpenSession: false
}


const generalSlice = createSlice({
    name: 'websocketGeneral',
    initialState,
    reducers: {
        onChangeIsOpenSession(state, {payload}) {
            state.isOpenSession = payload
        }
    }
})

const {reducer, actions} = generalSlice

export const {onChangeIsOpenSession} = actions
export default reducer