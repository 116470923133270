import {createSlice} from "@reduxjs/toolkit";
import {getCameraRequest, sendDataGate} from "../../asyncThunkFunctions/Api/CamerasRequests";

const initialState = {
    gate: {
        _id: '',
        cameras: [],
        isNewGate: false,
        error: null,
    },
    editCamera: {
        camera: {},
        isGetCamera: false,
        error: null,
    },
    disable: false,
    id_camera: '',
    switchModal: false,
    saveInStorage: false,
}

function findByIndex(state, id) {
    return [...state.gate.cameras].findIndex(({cctv_id}) => cctv_id + '' === id);
}

const newGate = createSlice(
    {
        name: 'formGate',
        initialState,
        reducers: {
            getLocalStorageGate(state, action) {
                state.gate = {...state.gate, ...action.payload}
            },

            toggleStorage(state, action) {
                state.saveInStorage = action.payload
            },

            addCameraId(state, {payload}) {
                state.id_camera = payload
            },

            switchModalShowCamera(state, action) {
                state.switchModal = action.payload
            },

            changeEditCameraData(state, action) {
                state.editCamera.camera = action.payload
            },

            initializeGateName(state, action) {
                state.gate['_id'] = action.payload
            },

            initializeCamera(state, action) {
                state.gate.cameras.push(action.payload)
            },

            editCamera(state, action) {
                const cameras = [...state.gate.cameras], index = findByIndex(state, action.payload.id);

                if (index > -1) cameras[index] = {...cameras[index], ...action.payload['editObj']}

                state.gate.cameras = cameras
            },

            removeCamara(state, action) {
                const index = findByIndex(state, action.payload + '');

                if (index > -1) state.gate.cameras.splice(index, 1)
            },

            disableButton(state, action) {
                state.disable = action.payload
            },

            updateFieldToGateCameraForm(state, {payload: {key, value}}) {
                const localStorageGate = JSON.parse(sessionStorage.getItem('gate'));
                const localStorageCamera = JSON.parse(sessionStorage.getItem('camera'));

                const camera = state.editCamera.camera
                const gate = state.gate;
                const index = findByIndex(state, state.id_camera);

                if (Object.keys(camera).length) {
                    state.editCamera.camera[key] = value
                    sessionStorage.setItem('camera', JSON.stringify({
                        ...localStorageCamera, [key]: value
                    }))
                }

                if (index > -1) {
                    gate.cameras[index][key] = value
                    sessionStorage.setItem('gate', JSON.stringify({...localStorageGate, cameras: gate?.cameras}))
                }
            },


            initialStateFormGate: () => initialState,
        },
        extraReducers: (builder) => {
            builder.addCase(sendDataGate.pending, (state) => {
                state.gate.isNewGate = true
                state.gate.error = null
            })
                .addCase(sendDataGate.fulfilled, (state, {payload}) => {
                    state.gate.isNewGate = false
                })
                .addCase(sendDataGate.rejected, (state, {error}) => {
                    state.gate.isNewGate = false
                    state.gate.error = null
                })

            builder.addCase(getCameraRequest.pending, (state) => {
                state.editCamera.isGetCamera = true
                state.error = null
            })
                .addCase(getCameraRequest.fulfilled, (state, {payload}) => {
                    state.editCamera.camera = payload
                    state.editCamera.isGetCamera = false
                })
                .addCase(getCameraRequest.rejected, (state, {error}) => {
                    state.editCamera.isGetCamera = false
                    state.error = error.message
                })
        }
    })

export default newGate.reducer
export const {
    initializeGateName,
    initializeCamera,
    removeCamara,
    editCamera,
    initialStateFormGate,
    disableButton,
    changeEditCameraData,
    switchModalShowCamera,
    addCameraId,
    getLocalStorageGate,
    toggleStorage,

    updateFieldToGateCameraForm

} = newGate.actions
