

export const MAIN_HEADER_HEIGHT = 70
export const HEADER_TABLE_HEIGHT = 38.5

// PERFORMANCE PAGE
export const PERF_MARGIN_TOP_BOT = 20 * 2
export const PERF_HEADER_HEIGHT = 32
export const PERF_HEADER_MARGIN_BOT = 15
export const PERF_PAGINATION_SIZE_BLOCK = 32
export const PERF_PAGINATION_MARGIN_TOP = 10

export const PERF_BUSY_SPACE =
    MAIN_HEADER_HEIGHT +
    PERF_MARGIN_TOP_BOT +
    HEADER_TABLE_HEIGHT +
    PERF_PAGINATION_SIZE_BLOCK +
    PERF_PAGINATION_MARGIN_TOP

export const PERF_INFO_BUSY_SPACE = PERF_BUSY_SPACE + PERF_HEADER_HEIGHT + PERF_HEADER_MARGIN_BOT


// FIDS PAGE
export const FIDS_WRAPPER_MARGIN_TOP_BOT = 40
export const PAGINATION_TABLE_HEIGHT_SIZE = 32
export const PAGINATION_TABLE_MARGIN_TOP_BOT = 16 * 2

export const FIDS_BUSY_SPACE = MAIN_HEADER_HEIGHT + HEADER_TABLE_HEIGHT + FIDS_WRAPPER_MARGIN_TOP_BOT + PAGINATION_TABLE_HEIGHT_SIZE + PAGINATION_TABLE_MARGIN_TOP_BOT


const BUSY_HEADER_PLACE_IN_EVENT_VISIBILITY = 42
export const BUSY_SPACE_IN_PAGE_JUST_WITH_TABLE = MAIN_HEADER_HEIGHT + HEADER_TABLE_HEIGHT + FIDS_WRAPPER_MARGIN_TOP_BOT + PERF_PAGINATION_SIZE_BLOCK + PERF_PAGINATION_MARGIN_TOP + BUSY_HEADER_PLACE_IN_EVENT_VISIBILITY



export const CONFIG_TABLE_TITLE_HEIGHT = 64
export const CONFIG_WRAPPER_MARGIN_TOP = 10
export const CONFIGURATION_BUSY_SPACE = MAIN_HEADER_HEIGHT + CONFIG_TABLE_TITLE_HEIGHT + HEADER_TABLE_HEIGHT + CONFIG_WRAPPER_MARGIN_TOP + PAGINATION_TABLE_HEIGHT_SIZE + PAGINATION_TABLE_MARGIN_TOP_BOT


export const LIVE_VIEW_WRAPPER_MARGIN_TOP = 10

export const LIVE_VIEW_BUSY_SPACE = MAIN_HEADER_HEIGHT + HEADER_TABLE_HEIGHT + LIVE_VIEW_WRAPPER_MARGIN_TOP + PAGINATION_TABLE_HEIGHT_SIZE + PAGINATION_TABLE_MARGIN_TOP_BOT


// TODO need to fix this calc for table scroll



