import React from 'react';
import {Form, Input} from "antd";

const EditableCell = (
    {
        editing, dataIndex, title,
        inputType, record, index, onChange,
        yupSync, children, ...restProps
    }) => {

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={dataIndex} style={{margin: 0}}
                           rules={[{required: true, message: `Please Input ${title}!`}, yupSync(record[dataIndex])]}>
                    <Input onChange={onChange}/>
                </Form.Item>
            ) : (children)
            }
        </td>
    )

};

export default EditableCell;