import axios from "axios";
import {baseUrl} from "./helpers/variables/api";

const air_axios_settings = axios.create({
    withCredentials: true,
    baseURL: baseUrl,
    headers: {'Content-Type': 'application/json'}
})

export default air_axios_settings
