import React from 'react'
import {Button, DatePicker} from "antd";
import locale from "antd/es/date-picker/locale/en_US";
import useReadyState from "../../hooks/useReadyState";

const FilterRangeDatePicker = ({confirm, clearFilters, isOpenDatePicker, selectedKeys, setSelectedKeys}) => {
    const {RangePicker} = DatePicker
    const {state: dates, onChangeReadyState: setDates} = useReadyState([]);

    const getResetDisabled  = selectedKeys.length === 0;
    const areDatesEmptyOrNull = dates.length === 0 || dates.includes(null)

    const handleReset = () => {
        if (clearFilters) clearFilters()
        setSelectedKeys([]);
        setDates([])
        confirm({closeDropdown: false})
    }

    const handleOnChangeData = (date) => {
        if (!date) {
            handleReset()
            return
        }

        setSelectedKeys(date);
        setDates(date)
    }

    const handleApplyFilter = () => {
        if (!areDatesEmptyOrNull) confirm()
    }


    return (
        <div style={{position: "relative"}} className={"audit_range-picker-container"}>
            <RangePicker
                locale={{...locale, lang: {...locale.lang, ok: areDatesEmptyOrNull ? "Select" : "Apply filter"}}}
                className={"date-filter-dropdown"}
                format="YYYY-MM-DD"
                popupClassName="table-date-filter-container"
                getPopupContainer={(trigger) => trigger.parentNode}
                open={isOpenDatePicker}
                value={dates}
                showTime
                onCalendarChange={handleOnChangeData}
                onOk={handleApplyFilter}
                renderExtraFooter={() => (
                    <div className={"ant-table-filter-dropdown-btns"}>
                        <Button size={"small"} onClick={handleReset} disabled={getResetDisabled}>Reset</Button>
                    </div>
                )}
            />
            <div className="table-date-filter-spacer"/>
        </div>
    );
};

export default FilterRangeDatePicker;