import { useState } from 'react';
import {Button, Modal} from 'antd';

const SimpleModal = ({primaryText, modalText}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
        <Button onClick={handleShowModal}>{primaryText}</Button>
        <Modal title={primaryText} open={isModalOpen} onOk={handleCloseModal} onCancel={handleCloseModal} footer={null}>
            <p style={{whiteSpace: 'pre-wrap', wordWrap: 'break-word'}}>{modalText}</p>
        </Modal>
    </>
  );
};

export default SimpleModal;