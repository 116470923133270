import React from 'react';
import {Marker} from "@react-google-maps/api";

const Markers = ({markers, icon, onMarkedIconMarker}) => {
    return markers?.map((marker, index) => {
        if (marker.lat && marker.lng) {
            return (
                <Marker
                    key={`${marker.lat}-${marker.lng}`}
                    position={marker}
                    icon={icon || onMarkedIconMarker(index, marker.color)}
                    label={{
                        text: `${index + 1}`,
                        color: "#ffffff",
                        fontWeight: "400",
                        fontSize:" 0.8rem"
                    }}
                >
                </Marker>
            )
        }

    })
}

export default Markers;