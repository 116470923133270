import {createSlice} from "@reduxjs/toolkit";
import {login} from "../../asyncThunkFunctions/Api/AuthReqeusts";
import {clearLocalStorage} from "../../../utils/CacheHelper";

const initialState = {
    profile: {},
    isAuth: Boolean(localStorage.getItem('token')),
    isAuthLoading: false,
    error: null,

}

const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        handleLogout(state) {
            clearLocalStorage()
            state.isAuth = false
            state.user = {}
        },
        handleUpdateUser(state, {payload}) {
           state.profile = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isAuthLoading = true
            state.error = null
        })
            .addCase(login.fulfilled, (state, {payload}) => {
                const {_id, is_active, role, token} = payload
                state.profile = {_id, is_active, role, user_token: token}
                state.isAuth = true
                state.isAuthLoading = false
            })
            .addCase(login.rejected, (state, {error}) => {
                state.isAuthLoading = false
                state.error = error.message
            })
    }
})


export default AuthSlice.reducer
export const {handleLogout, handleUpdateUser} = AuthSlice.actions