import * as Yup from "yup";
import {regCctv_id, regCrop} from "./reg";

const isRequiredString = Yup.string().required('required').trim();

export const yupCameraCheck = (cameraIds) => Yup.object().shape({
    top_left: Yup.string().trim().nullable().matches(regCrop, 'Incorrect format')
        .test('valid_bottom_right', "you can't just add bottom/right field", function (top_left) {
            const {bottom_right} = this.parent
            return !(!top_left && bottom_right);
        }),
    bottom_right: Yup.string().trim().nullable().matches(regCrop, 'Incorrect format')
        .test('valid_bottom_right', "you can't just add top/left field", function (bottom_right) {
            const {top_left} = this.parent
            return !(!bottom_right && top_left);
        }),
    cctv_id: isRequiredString.matches(regCctv_id, 'without any signs').notOneOf(cameraIds, "We have this cctv_id"),
    url: isRequiredString,
    cctv_params: Yup.string().test('valid-json', 'Invalid format', (cctv_params) => {
        try {
            JSON.parse(cctv_params);
            return true;
        } catch (error) {
            return false;
        }
    })
})

export const yupCheckName = (_id) => Yup.object({_id: isRequiredString.notOneOf(_id, "This gate already exists")})