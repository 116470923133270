import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isFinishToPrepare: false,
    isDownloadFile: false,
    objOfPrepareToasts: {},
    closeNamePrepareToast: ''
}


const NotificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        onChangeIsDownloadFile(state, {payload}) {
          state.isDownloadFile = payload
        },
        onChangeFinishPrepareState(state, {payload}) {
            state.isFinishToPrepare = payload
        },

        getPrepareToast(state, {payload: {toast_name, toast_id}}) {
            state.objOfPrepareToasts[toast_name] = toast_id
        },
        getFeedbackNameSession(state, {payload}) {
            state.closeNamePrepareToast = payload
        },

        removePrepareToastId(state) {
            delete state.objOfPrepareToasts[state.closeNamePrepareToast]
        }
    }
})

const {reducer, actions} = NotificationSlice

export const {
    onChangeFinishPrepareState,
    getPrepareToast,
    getFeedbackNameSession,
    removePrepareToastId,
    onChangeIsDownloadFile

} = actions
export default reducer