import {useEffect} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import EditCamera from "./EditCamera";
import {useDispatch, useSelector} from "react-redux";
import {getLocalStorageGate, initialStateFormGate, toggleStorage} from "../../reduxToolkit/slices/Api/newGate";
import FormGate from "./FormGate";
import {sendCameras, sendDataGate} from "../../reduxToolkit/asyncThunkFunctions/Api/CamerasRequests";
import {FORM_GATE_ADD_CAMERAS, FORM_GATE_EDIT_CAMERA} from "../../utils/RouteSettings/Paths";
import {CONFIGURATION_LINKS} from "../../utils/RouteSettings/Links";
import {clearImgStates} from "../../reduxToolkit/slices/Api/Frames";
import {selectFormGate} from "../../reduxToolkit/selectors/Api/formAddGate";
import {clearSessionStorage} from "../../utils/CacheHelper";
import {setInitialFileState} from "../../reduxToolkit/slices/Ui/Files";

const  WrapperFormGate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {gate: {_id, cameras}, saveInStorage} = useSelector(selectFormGate);

    const handleGoToConfiguration = () => {
        const {configuration} = CONFIGURATION_LINKS
        return navigate(configuration)
    }

    const handleAddNewGate = async () => {
        await dispatch(sendDataGate({_id, cameras}))
        await handleGoToConfiguration()
    }

    const handleAddNewCameras = async () => {
        await dispatch(sendCameras({cameras, _id}))
        await handleGoToConfiguration()
    }

    useEffect(() => {
        if (saveInStorage) {
            sessionStorage.setItem('gate', JSON.stringify({_id, cameras}))
        }
        return () => {
            dispatch(toggleStorage(false))
        }
    }, [saveInStorage])

    useEffect(() => {
        const gateStorage = JSON.parse(sessionStorage.getItem('gate'));

        if (gateStorage) dispatch(getLocalStorageGate(gateStorage))

        return () => {
            dispatch(initialStateFormGate())
            dispatch(clearImgStates())
            dispatch(setInitialFileState())
            clearSessionStorage()
        }
    }, [])

    return (
        <Routes>
            <Route path={'*'} element={<FormGate onSendRequest={handleAddNewGate}/>}/>
            <Route path={FORM_GATE_ADD_CAMERAS} element={<FormGate onSendRequest={handleAddNewCameras}/>}/>
            <Route path={FORM_GATE_EDIT_CAMERA} element={<EditCamera/>}/>
        </Routes>
    );
};

export default WrapperFormGate;