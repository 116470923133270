import {PERFORMANCE} from "./Paths";


export const PERFORMANCE_LINKS = {
    day: '/performance/24hours',
    min_max: '/performance/minmax',
    date_compare: '/performance/dateCompare'
}

const LOGIN_LINKS = {
    sign_in: '/login'
}

export const LIVE_VIEW_LINKS = {
    live_view: '/liveView'
}

export const FIDS_LINKS = {
    fids: '/fids'
}

export const CONFIGURATION_LINKS = {
    configuration: '/configuration'
}

export const SYSTEM_LINKS = {
    download: '/system/download',
    event_table_tools: '/system/event_tools',
    validation: '/system/other',
}

export const MOCK_LINKS = {
    mock: '/mock'
}

export const ALERTS_LINKS = {
    alerts: '/alerts'
}

export const FORM_GATE_ADD_CAMERAS_LINK = (_id, form_step) => `/formGate/gate/${_id}/add_cameras/${form_step}`
export const FORM_GATE_EDIT_CAMERA_LINK = (_id, cctv_id, form_step) => `/formGate/gate/${_id}/edit_camera/${cctv_id}/${form_step}`

export const FORCE_FORM_GATE_STEP = ({_id, cctv_id, form_step}) => {
    if (cctv_id) return FORM_GATE_EDIT_CAMERA_LINK(_id, cctv_id, form_step)
    if (!cctv_id && _id) return FORM_GATE_ADD_CAMERAS_LINK(_id, form_step)
    return `/formGate/${form_step}`
}
export const FORM_GATE_LINKS = {
    step_one: '/formGate/step_one',
    step_two: '/formGate/step_two',
    step_three: '/formGate/step_three',
    preview: '/formGate/preview',
}

export const TURNAROUND_LINKS = {
    cctv_id: '/turnaround/:turnaround_id/:gate_id/:cctv_id'
}

export const ADMINISTRATION_LINKS = {
    users: '/administration/users',
    tools: '/administration/tools',
    audit: '/administration/audit'
}