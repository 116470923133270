import React from 'react';

const ModalWindow = ({modalClasses, handleClose, children}) => {
    return (
        <>
            <section className={modalClasses} onClick={handleClose}/>
            {children}
        </>
    )
};

export default ModalWindow;