import {Button, Card, DatePicker, Form, Input, Select} from "antd";
import {CLEAR, CLOSE, FINISH} from "../../ANTD/Icons";
import EventInformation from "../EventInformation";
import {rules} from "../../../utils/FormItemRules";
import {defaultEventEditFormState, typesOcclusionsOption} from "../../../utils/helperArrays";

const EditEvent = (
    {
        form, classesList, event, close, updateFields,
        initialState, jumpToEvent, onFinish,

        resetEditForm, jumpToTime, onChangeRejectReason,
        editOption, transformTime, multifunctionalOptions, isDetections,
        isPositions
    }) => {

    return (
        <div className={classesList}>
            <Card className={'editForm__cardBlock'}>
                <Button onClick={close} className={'editForm__closeFormButton'} title={'close form'}>{CLOSE}</Button>
                <EventInformation event={event} onClick={jumpToEvent} transformTime={transformTime}/>
                <Form name={'Leave a comment'} onFinish={({timestamp, ...states}) => onFinish(states)}
                      initialValues={initialState} form={form} className={'cardBlockForm'}>
                    <Form.Item name={'reject_reason'} label={'Reason'} className={'cardBlockForm__inputBlock'}>
                        <Select
                            options={defaultEventEditFormState}
                            onChange={onChangeRejectReason}
                            className={'editForm__inputFiled'}
                            rules={rules['reject_reason']}
                        />
                    </Form.Item>

                    {editOption['isShowSelect'] &&
                        <Form.Item name={editOption['value'] === 'location' ? 'correct_location' : 'correct_detection'}
                                   label={editOption['hideLabelSelect']}
                                   rules={[{
                                       required: multifunctionalOptions.length > 0 || editOption['value'] !== 'location',
                                       message: `Please select ${editOption['value']}!`
                                   }]}
                                   className={'cardBlockForm__inputBlock'}
                        >
                            <Select loading={isDetections || isPositions}
                                    options={multifunctionalOptions}
                                    className={'editForm__inputFiled'}
                            />
                        </Form.Item>
                    }

                    {editOption['isShowDatePicker'] && (
                        <>
                            <div style={{position: 'relative'}} className={'cardBlockForm__inputBlock'}>
                                <Form.Item name={'timestamp'} label={'Date'}>
                                    <DatePicker showTime format={transformTime} disabled
                                                className={'editForm__inputFiled'}
                                    />
                                </Form.Item>
                                <Form.Item style={{position: 'absolute', top: '0', right: '-75px'}}>
                                    <Button onClick={updateFields} title={'Update -> Date/Timestamp'}>
                                        Update
                                    </Button>
                                </Form.Item>
                            </div>
                            <div style={{position: 'relative'}} className={'cardBlockForm__inputBlock'}>
                                <Form.Item name={'correct_time'} label={'Timestamp'} rules={rules['correct_time']}>
                                    <Input disabled className={'editForm__inputFiled'}/>
                                </Form.Item>
                                <Form.Item style={{position: 'absolute', top: '0', right: '-90px'}}>
                                    <Button style={{padding: '4px'}} onClick={jumpToTime}>Jump to Time</Button>
                                </Form.Item>
                            </div>

                        </>
                    )}
                    {editOption['isShowOcclusion'] &&
                        <Form.Item name={'occlusion'} label={'Occlusion'} className={'cardBlockForm__inputBlock'}
                                   rules={[{required: true, message: 'Please select occlusion'}]}>
                            <Select options={typesOcclusionsOption} className={'editForm__inputFiled'}/>
                        </Form.Item>
                    }
                    <Form.Item name={'comment'} rules={[{
                        required: editOption['value'] === 'other',
                        message: 'Please write the commit'
                    }]}>
                        <Input.TextArea autoSize={{minRows: 3, maxRows: 5}} placeholder={'Some comment'}/>
                    </Form.Item>
                    <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type={'primary'}
                                    onClick={resetEditForm}
                                    disabled={!Object.keys(editOption).length}>
                                    Clear {CLEAR}
                                </Button>
                            )}
                        </Form.Item>

                        <Form.Item shouldUpdate>
                            {() => (
                                <Button
                                    type={'primary'}
                                    htmlType={'submit'}
                                    disabled={!Object.keys(editOption).length}>
                                    Submit {FINISH}
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default EditEvent;