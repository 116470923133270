import {combineReducers} from "redux";
import Websocket from "../Websocket";
import Api from "../Api";
import Ui from '../Ui'

const Collector_Combines = combineReducers({
    api: Api,
    ws: Websocket,
    ui: Ui
})

export default Collector_Combines