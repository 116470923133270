export const drawSheetBtnsSettings = {
    'ramp': [
        {id: 1, name: 'Clearance Zone', value: 'clearance zone'},
        {
            id: 2,
            name: 'Calibration',
            value: 'calibration',
            openValues: ["intrinsic", "extrinsic"],
            isPopConfirm: true,
            confirmProps: {
                title: '"Intrinsic" calibration action "Extrinsic"',
                cancelText: "Intrinsic",
                okText: "Extrinsic",
            }
        },
    ],
    'bridge': [
        {id: 1, name: 'Mark Door', value: 'mark door'}
    ]
}